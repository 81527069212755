.social-links-wrapper {
    text-align: center;
    margin-bottom: 50px;
    ul {
        list-style-type: none;
        li {
            display: inline-block;
            margin-right: 15px;
            a {
                img {
                    max-height: 25px;
                    border-radius: 5px;
                }
            }
        }
    }
}