@import "src/scss/brands";

@include brandify {
  .wizardPageTitle {
    font-style: italic;
    padding-left: 2rem;
    font-size: 1.5625rem;
    color: white;
    font-weight: 500;

    svg {
      margin-right: 8px;
    }
  }
  .wizardPageSubTitle {
    color: $color-disabled;
  }

  .binaryChoice .grid {
    width: 100%;
    .btn {
      width: 100%;
    }
  }
}
