.thank-you-container {
    margin-top: 100px;
    text-align: center;
    padding-left: 15px;
    padding-right: 15px;

    @media screen and (max-width: 567px) {
        padding-left: 30px;
        padding-right: 30px;
    }

    .green-tick {
        max-width: 50px;
        margin-bottom: 30px;
    }
    .limos-black-logo {
        max-width: 150px;
        margin-bottom: 30px;
        font-family: "Rubik-Medium";
    }

    .call-btn button{
         border: 1px solid;
         background-color: #fff;
         font-size: 14px;
         font-weight: bold;
         color: #585858;
         padding: 5px 10px;
         border-radius: 5px;
         letter-spacing: 0.5px;
         font-family: "Rubik-Medium";
     }
     p {
        font-family: "Rubik-Regular";
     }
}