@import "src/scss/brands";

@include brandify {
  .countdownContainer {
    min-height: 76px;
    position: fixed;
    z-index: 9999;

    padding-top: 16px;
    padding-left: 24px;
    padding-right: 24px;
    background: $color-red;

    left: 50%;
    transform: translate(-50%, 0);

    max-width: 300px;

    @media (max-width: 768px) {
      bottom: 0;
    }

    @media (min-width: 768px) {
      top: 0;
    }

    .wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      p {
        margin-bottom: 8px;
        color: white;
        text-align: center;
        line-height: 16px;
        margin-top: 4px;
      }

      @media (min-width: 768px) {
        .countdown {
          margin-top: -4px;
        }

        p {
          margin-top: 0;
          margin-bottom: 16px;
        }
      }

      .countdown {
        display: flex;
        align-items: center;
        justify-content: center;

        .time-section {
          padding-left: 1px;
          padding-right: 1px;

          .time {
            color: white;
            font-size: 32px;
            line-height: 28px;
            font-weight: 700;
            font-style: italic;
            letter-spacing: 1px;
          }
        }
      }
    }
  }
}
