@import "src/scss/brands";

.DayPicker {
  background: none;
  display: inline-block;
  width: 100%;
}

.DayPicker-Month {
  border-spacing: 2px;
  border-collapse: inherit;
  margin: 0;
  margin-top: 0;
  display: block;
  width: 100%;
}

.DayPicker-Months {
  display: flex;
}
.DayPicker-wrapper {
  padding-bottom: 0rem;
}

.DayPicker-Weekdays {
  display: flex;
}

.DayPicker-WeekdaysRow {
  display: flex;
  width: 100%;
  flex: 1 1;
}

.DayPicker-Weekday {
  display: inline-block;
  width: 100%;
}

.DayPicker-Body {
  display: block;
  width: 100%;
}

.DayPicker-Week {
  display: flex;
  flex: 1 1;
  width: 100%;
}

.DayPicker-Day {
  border-radius: 0;
  margin: 0.2rem;
  display: flex;
  width: 100%;
  justify-content: center;
}

.DayPicker-NavButton--next {
  background-image: none;
}

.DayPicker-NavButton--prev {
  background-image: none;
}

.DayPicker-NavBar {
  display: flex;
  .flexLeft {
    flex: 1 1;
    align-self: flex-start;
  }
  .flexRight {
    align-self: flex-end;
  }
  .monthSelectButton {
    font-size: 4.5rem;
    margin-left: 0.9rem;
    margin-right: 0.9rem;
    line-height: 0;
  }
  .wizardPageTitle {
    line-height: 4.5rem;
  }
}

@include brandify {
  .DayPicker-Day--today {
    color: white;
  }

  .DayPicker:not(.DayPicker--interactionDisabled)
    .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    background-color: $color-off-white;
    color: black;
  }
  .DayPicker-Day {
    color: white;
    border: 0.04rem solid $color-off-white;
    font-size: 1.5rem;
    font-weight: 800;
    padding: 0.6rem;
    border-radius: 0.1875rem;
  }

  .DayPicker-Day--disabled {
    color: rgba(255, 255, 255, 0.5);
    pointer-events: none;
  }

  .DayPicker-Month {
    border-spacing: 0.36rem;
  }
  .DayPicker-Caption {
    font-style: italic;
    font-size: 1.33rem;
    color: white;
    font-weight: 500;
  }
  .DayPicker-WeekdaysRow {
    font-size: 1.7rem;
  }
  .DayPicker-Weekday {
    color: white;
  }
  .monthSelectButton {
    color: white;
  }
}
