@import "src/scss/globals";
@import "src/scss/brands";

@include brandify {
  .searchResultDate {
    font-size: 1.4rem;
    font-weight: 700;
    padding-left: 1.4rem;
    color: white;
    letter-spacing: -0.025rem;
  }

  .miniAddress {
    font-style: italic;
    font-weight: 300;
    font-size: 1.3rem;
    color: black !important;
  }
  .miniPickupAddress {
    padding-left: 1rem;
  }
  .miniDropOffAddress {
    text-align: right;
  }
  .miniTripInfo {
    margin-top: 0rem;
    width: 100%;
    font-family: "Rubik-Regular";
    margin: auto auto;
    margin-bottom: 1rem;
    .mini-trip-to-from {
      margin: 0 auto 0 auto;
      background: #fff;
      padding: 8px 60px;
      text-align: center;
      line-height: 1.8;
      color: #212121;
      display: flex;
      flex-direction: column;
      font-family: "Rubik-Regular";
      font-size: 14px;
      align-items: center;
      justify-content: center;
      justify-items: center;

      @media screen and (max-width: 667px) {
        padding: 20px;
      }

      .pickup_display {
        display: flex;
        justify-content: space-evenly;
        width: 85%;
        align-items: center;
        @media screen and (max-width: 667px) {
          flex-direction: column;
          width: 100%;
          font-size: 13px;
          padding: 8px 0px;
          text-align: left;
        }
        span {
          @media screen and (max-width: 667px) {
            text-align: left;
          }
        }
      }
    }
  }
  .mapMarker {
    max-height: 2rem;
    max-width: 4rem;
    padding: 0.1rem;
    object-fit: scale-down;
  }
  .pickupMapMarker {
    margin-right: 0.3rem;
  }

  .dropoffMapMarker {
    margin-left: 0.3rem;
  }
}
