@import "src/scss/globals";
@import "src/scss/brands";

@include brandify {
  .mapContainer {
    text-align: center;
    margin-top: 0.15rem;
    margin-bottom: 0rem;
    object-fit: contain;
  }

  .mapContainer img {
    width: 100%;
    border-radius: $border-radius-standard;
    border: 0rem solid rgba(0, 0, 0, 0);
  }
}
