@import "src/scss/brands";
@mixin gridItem {
  border-radius: 0;
  border: 0.04rem solid $color-off-white;
  border-radius: 0.1875rem;
}

@include brandify {
  .grid .btn-group-toggle :not(.active).btn {
    color: black;
    font-weight: 800;
    display: flex;
    justify-content: center;
  }

  .grid .btn-group-toggle :not(.active).btn-disabled {
    color: grey;
  }

  .ampm {
    font-size: 0.9rem;
    padding-left: 0.2rem;
    padding-top: 0.23rem;
    text-transform: uppercase;
  }

  .grid .btn-group-toggle .btn {
    margin: 0.2rem;
    flex: 1;
    font-size: 1.3rem;
  }

  .grid .btn-group-toggle .btn:hover {
    background-color: $color-hover;
    border-color: $color-interactive;
  }
  .grid {
    .btn .hour {
    }

    .btn {
      width: 5rem;
      height: 3rem;
    }

    .btn-group > .btn-group:not(:last-child) > .btn,
    .btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
      @include gridItem;
    }

    .btn-group > .btn-group:not(:first-child) > .btn,
    .btn-group > .btn:not(:first-child) {
      @include gridItem;
    }
  }
  .blankMinutes {
    opacity: 0.33;
  }
}

.hourCell {
  padding-top: 0.6rem;
  position: relative;
}

.halfDayLabel {
  position: absolute;
  width: 100%;
  bottom: 0;
  font-size: 0.7rem;
}
