.contactPage {
  margin-top: 1rem;
}

.pageTitle {
  text-align: left;
  font-size: 1.8rem;
  font-weight: 800;
}

.aboutText {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 1.43rem;
  font-weight: 300;
}
