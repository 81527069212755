@import "src/scss/brands";
@import "src/scss/globals";

.talkToSpecialist {
  margin-bottom: 2rem;
  font-size: 21px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  text-shadow: 0 2px 3px rgba(0, 0, 0, 0.6);
  font-family: RubikRoman;
}

.txt-color {
  color: #5178bf;
}

.linkButton {
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    margin-right: 0.2rem;
    margin-bottom: 0.15rem;
  }
}

.callAndChatContainer .btn.linkButton {
  font-size: 1rem;
  height: 2.5rem;
}

.hero-form .callAndChatContainer .btn.linkButton {
  font-size: 1.2rem;
  height: 2.5rem;
}

@include brandify {
  .callAndChatContainer .btn.linkButton {
    border-radius: 0;
    background-color: #fff;
    border-color: $color-interactive;
    // border: 0.2rem solid $color-interactive;
    color: #000 !important;
    border-radius: 5.2px;
    text-transform: inherit;
    min-height: auto;
    font-family: "SFCompactDisplay";
  }

  .hero-form .callAndChatContainer .btn.linkButton {
    background-color: inherit;
    border-color: $color-border;
    border: 0.2rem solid $color-border;
  }
}
