@import "src/scss/globals";
@import "src/scss/brands";

$carousel-indicator-size: 0.55rem;
.carousel-inner img {
  max-width: 100%;
}

.carousel-inner .carousel-item {
  height: 13rem;
}
.carousel-indicators {
  margin-bottom: 0rem;
  li {
    border-radius: 50%;
    width: $carousel-indicator-size;
    height: $carousel-indicator-size;
    background-color: $gallery;
  }
}

.heroPrice {
  font-size: 2.8rem;
  font-weight: 800;
  height: 2.7rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .dollarSign {
    font-size: 2rem;
    align-self: flex-end;
  }
}

.hidden {
  display: none;
}

.operatorId {
  color: $cadet-blue;
  padding-top: 0.15rem;
}

.amenities {
  height: 13rem;
  list-style-type: none;
  padding: 1.2rem;
  font-weight: 600;
  font-size: 1.3rem;
}
.checkMarkIcon {
  margin-right: 0.7rem;
}

.reviews {
  height: 13rem;
  width: 100%;
  padding-left: 3rem;
  padding-right: 3rem;
}
.carousel-outer {
  margin-bottom: 0.2rem;
}
.carousel-arrow {
  color: rgba(50, 50, 50, 1);
}
.carouselImageContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.cardInnerContainer {
  padding: 0.6rem;
}

.emptyStar {
  color: $gallery;
}
.vehicleTitle {
  font-size: 1.6875rem;
  text-align: center;
  font-weight: 600;
  font-style: italic;
  color: black;
  margin: 24px auto 0 auto;
}

.amenitiesIcon {
  font-size: 1.7rem;
}

.cardContainer {
  margin-bottom: 0.625rem;
  margin-top: 0.625rem;
  border-radius: 0;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.12), 0 5px 5px 0 rgba(0, 0, 0, 0.24);

  color: black !important;
  overflow: hidden;

  .btn {
    border: 0;
    height: 3.4rem;

    @include button-text;
  }

  .addToCartButton {
    display: flex;
  }
  .addToCartButton .btn {
    border-radius: 0;
    height: 4rem;
    font-size: 1.5em;
    font-weight: 800;
    flex: 1;
  }

  .addToCartButton .btn.small-font {
    font-size: 1em;
    font-weight: 800;
  }

  .capacityBox {
    padding-right: 0.5rem;
  }

  .capacityBoxInner {
    border-radius: 0;
    font-size: 1.7rem;
    width: 100%;
    height: 2.9rem;
    display: flex;
    justify-content: center;
    align-items: center;

    .capacityValue {
      padding-left: 0.2rem;
      font-weight: 600;
    }
  }

  .amenities {
    overflow: auto;
    width: 100%;
  }

  .capacityValueRow {
    height: 2.5rem;
    margin-bottom: 0.7rem;
  }
  .amenitiesButtonText {
    font-size: 1.3rem;
  }
}

@include brandify {
  .cardInnerContainer {
    color: $color-body-text;

    .stars {
      svg {
        color: gold;
      }
    }

    .gratuity {
      margin-bottom: 24px;

      svg,
      em {
        color: black;
      }
    }
  }
  .cardContainer {
    .capacityBoxInner {
      background-color: white;
      color: black;
      border: 0.14rem solid black;
    }
  }
  .cardContainer .btn {
    background: $color-interactive;
  }

  .cardContainer .addToCartButton a {
    border-left: 0.14rem solid white;
    text-decoration: none;
  }

  .cardContainer a {
    text-decoration: underline;
  }

  .cardContainer .no-underline {
    text-decoration: none;
  }

  .cardContainer .amenitiesButton {
    height: 2.9rem;
    border-radius: 0;
    background: white;
    color: black;
    border: 0.14rem solid black;
  }
  .carousel-indicators {
    .active {
      background-color: $color-interactive;
    }
  }

  .carousel-arrow {
    color: $color-interactive;
  }

  a.carousel-control-next,
  a.carousel-control-prev {
    opacity: 1;
  }

  .heroPrice {
    color: $color-interactive;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .priceSubtitle {
    max-height: 1.3rem;
    font-size: 1.2rem;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }

  .searchResultReferenceNumber {
    font-size: 1.7rem;
    font-weight: 800;
    padding-left: 1.4rem;
    color: black;
  }
}