@import "../../components/variables.scss";
@import "../../components/mixins.scss";

.container-contest {
  width: 100%;
  min-height: 900px;
  background-color: $white;
  @media only screen and (max-width: 576px) {
    height: auto;
    width: 100%;
  }
  .contest-add-cities {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    @media only screen and (max-width: 576px) {
      margin-top: 0px;
    }

    button {
      width: auto;
      border-radius: 6px;
      background-color: #000;
      color: #fff;
      padding: 0.5rem 0.7rem;
      margin-bottom: 2rem;
      font-size: 12px;
      font-family: "SFCompactDisplay-Semibold";
      font-weight: 500;
      border: none;
      &:hover {
        border: none;
      }
      &:active {
        border: none;
      }
    }
  }
  .contest-custom-request {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    max-width: 30%;
    margin: auto auto;
    @media only screen and (max-width: 576px) {
      max-width: 100%;
    }
    a {
      width: 243px;
      border-radius: 6px;
      background-color: #000;
      color: #fff;
      padding: 0.5rem 0.7rem;
      margin-bottom: 2rem;
      font-size: 12px;
      font-family: "Rubik-Medium";
      color: $white;
      text-align: center;
      @media only screen and (max-width: 576px) {
        margin-bottom: 1rem;
      }
    }
  }
  .contest-package-imgs {
    margin: auto auto;
    width: 45%;
    height: auto;
    @include display-row;
    margin-top: 3rem;

    justify-content: space-between;
    @media only screen and (max-width: 576px) {
      height: auto;
      width: 100%;
      @include display-column;
      margin-top: 1rem;
    }
    .pack-image-conainer {
      display: flex;
      flex-direction: column;
      width: 48%;
      height: auto;
      border-top-right-radius: 6px;
      border-top-left-radius: 6px;
      @media only screen and (max-width: 576px) {
        margin-bottom: 0.8rem;
        width: 90%;
        margin: auto auto;
        margin-bottom: 1rem;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
      }
      .contest-package-desc {
        color: #fff;
        background-color: black;
        background: transparent;
        opacity: 0.85;
        background-color: #000;
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 6px;
        height: auto;
        padding: 0.5rem 0px;
        display: flex;
        flex-direction: column;
        font-family: "Rubik-Regular";

        p {
          font-size: 12px;
          width: 45%;
          margin: 0px;
          height: 100%;
          display: flex;
          justify-items: center;
          text-align: left;
          align-items: center;
          margin-left: 0.4rem;
          box-sizing: border-box;
          font-weight: 500;
          line-height: 1.33;
          font-family: "Rubik-Medium";
        }
        .sub-text {
          font-weight: light;
          font-family: "Rubik-Regular";
        }
      }
    }
  }
  .contest-packages {
    margin: 3rem 0px;
    text-align: center;
    @media only screen and (max-width: 576px) {
      margin: 0rem 0px;
    }

    span {
      @include font-normal(21px, "Rubik-Regular", #142b47, center, normal);
    }
  }

  .contest-hero-banner {
    .contest-video {
      width: 20%;
      height: 42%;
      position: absolute;
      right: 12%;
      bottom: 6%;
      border-radius: 6px;
      /*    @media only screen and (min-width: 1600px) {
        right: 40%;
      bottom: 13%;
      width: 5%;
      height: 42%;
      }
      @media only screen and (min-width: 1500px) {
        right: 33%;
      bottom: 13%;
      width: 8%;
      height: 42%;
      }
      @media only screen and (min-width: 2000px) {
        right: 40%;
        bottom: 13%;
        width: 5%;
        height: 42%;
      } */
    }
    .img-container {
      @media only screen and (max-width: 576px) {
      }

      .banner-container {
        height: 700px;
        overflow: hidden;

        img {
          object-fit: cover;
          width: 100%;
        }

        /*   @media only screen and (min-width: 1476px) {
          height: 00px;
        }
        @media only screen and (min-width: 2000px) {
          height:1000px;
        }  */
        @media only screen and (max-width: 576px) {
          height: 100%;
          object-fit: contain;

          img {
            object-fit: contain;
            width: 100%;
            height: 70%;
          }
        }
      }
    }

    .contest-desc {
      height: 550px;
      @include margin-auto(40%, 40%, 1px solid none);
      position: absolute;
      top: 15%;
      left: 30%;
      @media only screen and (min-width: 1476px) {
        top: 13%;
      }
      @media only screen and (min-width: 2000px) {
        top: 6%;
      }
      @media only screen and (max-width: 576px) {
        top: 26%;
        left: 0%;
        width: 100%;
      }
      .limos-logo-txt {
        text-align: center;
        font-size: 2.5rem;
        width: 100%;
        font-family: $rubikRegularFont;
        font-weight: 500;
        letter-spacing: 4px;
        color: $white;
        @media only screen and (max-width: 576px) {
          display: none;
        }
      }
      .logo-desc {
        @include font-normal(18px, "Rubik-Medium", $white, center, 500);
        width: 60%;
        max-width: 289px;
        margin: auto auto;
        @media only screen and (max-width: 576px) {
          width: 100%;
        }
      }
      .contest-golden-ticket {
        width: 159px;
        height: 79px;
        margin: auto auto;
        margin-top: 3.1rem;
        // background-image: linear-gradient(140deg, #fadb00 18%, #f4b400 79%);
        position: relative;
        @media only screen and (max-width: 576px) {
          margin-top: 0rem;
        }
        @media only screen and (min-width: 1676px) {
          margin-top: 2rem;
        }
        @media only screen and (min-width: 2000px) {
          margin-top: 3rem;
        }
        .carnival-ticket-desc {
          width: 100%;
          position: absolute;
          text-align: center;
          @include display-column;
          span {
            @include font-normal(11.5px, "Rubik-Medium", #594700, center, 500);
          }
        }
      }
      .steps-video-container {
        display: flex;
        flex-direction: row;
        width: 650px;
        margin: auto auto;
        @media only screen and (max-width: 576px) {
          flex-direction: column;
          width: 100%;
        }
        .contest-videos {
          width: 25%;
          height: 300px;
          display: flex;
          align-items: center;
          margin-top: 1rem;
          border-radius: 6px;
          background-color: #000;
          justify-items: flex-start;
          position: relative;
          @media only screen and (max-width: 576px) {
            display: none;
          }
          video {
            padding: 0px;
            position: absolute;
            height: auto;
            width: 89%;
            top: 49%;
            left: 50.1%;
            transform: translate(-50%, -49%);

            @media only screen and (max-width: 576px) {
              display: none;
            }
          }
        }

        .contest-steps {
          width: 78%;
          height: auto;
          padding: 1rem;
          background-color: $white;
          margin: auto auto;
          margin-top: 1rem;
          border-radius: 6px;
          max-width: 400px;
          max-height: 350px;
          @media only screen and (max-width: 576px) {
            margin-top: 1rem;
            width: 98%;
            box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.4);
          }

          hr {
            opacity: 0.09;
            border: solid 0.7px #000;
          }
          .steps-row {
            @include display-row;
            justify-content: space-between;
            align-items: center;

            .steps-container {
              width: 20%;
              @include display-row;
              justify-content: space-around;
              .step-number {
                @include font-normal(
                  16px,
                  "SFCompactDisplay-Medium",
                  #2a2a2a,
                  center,
                  500
                );
                font-family: "SFCompactDisplay-Medium";
              }
              .step-circle {
                border-radius: 50%;
                width: 8px;
                height: 8px;
                background: #fff;
                border: 1.6px solid #2a2a2a;
                color: #2a2a2a;
                text-align: center;
                box-sizing: content-box;
                font-size: 16px;
                padding: 0.4rem;
                display: flex;
                align-items: center;
                justify-items: center;
                font-family: "SFCompactDisplay-Medium";
              }
            }
            .step-text {
              @include font-normal(
                10px,
                "SFCompactDisplay-Medium",
                #2a2a2a,
                center,
                500
              );
              line-height: 2.28;
              width: 90%;
              text-align: left;
              padding: 0 1.3rem;
              line-height: 1.4;
              font-weight: 500;
              font-family: "SFCompactDisplay-Medium";

              @media only screen and (max-width: 576px) {
                padding: 0 1rem;
              }
            }
            .show-lg {
              @media only screen and (max-width: 576px) {
                display: none;
              }
              @media only screen and (min-width: 1400px) {
                display: none;
              }
              @media only screen and (min-width: 2000px) {
                display: none;
              }
            }
            .show-md {
              display: none;
              @media only screen and (max-width: 576px) {
                display: block;
              }
              @media only screen and (min-width: 1400px) {
                display: block;
              }
              @media only screen and (min-width: 2000px) {
                display: block;
              }
            }
          }
        }
      }
    }
  }
  .contest-videos-sm {
    width: 98%;
    margin: auto auto;
    margin-top: 19rem;
    height: 300px;
    display: flex;
    align-items: center;
    border-radius: 6px;
    background-color: #000;
    justify-items: flex-start;
    position: relative;
    display: none;

    @media only screen and (max-width: 576px) {
      display: block;
    }
    video {
      padding: 0px;
      position: absolute;
      height: 290px;
      width: 100%;
      top: 49%;
      left: 50.1%;
      transform: translate(-50%, -49%);

      @media only screen and (max-width: 576px) {
        display: block;
      }
    }
  }
  .contest-images-container {
    width: 80%;
    @include display-row;
    margin: auto auto;
    margin-top: 4rem;
    align-items: center;
    @media only screen and (max-width: 576px) {
      @include display-column;
      width: 90%;
      margin-top: -12rem;
    }
    @media only screen and (max-width: 1676px) {
    }
    @media only screen and (max-width: 2000px) {
    }
    .contest-images {
      width: 60%;
      margin-left: 7rem;
      @media only screen and (max-width: 576px) {
        width: 100%;
        margin-left: 0rem;
      }
      @media only screen and (min-width: 1676px) {
        width: 50%;
      }
      @media only screen and (min-width: 2000px) {
        width: 50%;
      }

      .contest-img-list {
        width: 90%;
        margin: auto auto;
        margin-top: 1rem;
        img {
          width: 100%;
          margin-right: 1rem;
          margin-bottom: 1rem;
          border-radius: 6px;
        }
      }
    }
    .contest-img-desc {
      width: 30%;
      text-align: left;
      @media only screen and (max-width: 576px) {
        width: 100%;
        margin-top: 13rem;
      }
      .contest-desc-container {
        width: 90%;
        margin: auto auto;
        margin-top: 5rem;
        @media only screen and (max-width: 576px) {
          margin-top: 1rem;
        }
        p {
          margin: auto auto;
          color: #000;
          width: 90%;
          font-size: 21px;
          line-height: 1.43;
          letter-spacing: normal;
          color: #424242;
          font-family: "Rubik-Regular";
        }
        .contest-desc-lg {
          @media only screen and (max-width: 576px) {
            display: none;
          }
        }
        .contest-desc-mb {
          display: none;
          @media only screen and (max-width: 576px) {
            display: block;
            width: 100%;
          }
        }
        .limos-about {
          margin: auto auto;
          width: 90%;
          opacity: 0.7;
          font-family: "Rubik-Regular";
          font-size: 13px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.54;
          letter-spacing: normal;
          color: #424242;
          margin-top: 1rem;
          @media only screen and (max-width: 576px) {
            width: 100%;
          }
        }
        .show-lg {
          @media only screen and (max-width: 576px) {
            display: none;
          }
        }
        .show-md {
          display: none;
          @media only screen and (max-width: 576px) {
            display: block;
          }
        }
        input {
          width: 100%;
          margin-top: 1rem;
          padding: 0.7rem 1rem;
          opacity: 1;
          border-radius: 6.9px;
          border: solid 1.3px rgba(0, 0, 0, 0.35);
          font-family: "SFCompactDisplay-Medium";
          font-size: 13px;
          outline: none;
          box-shadow: none;
          color: #424242;
          &::placeholder {
            color: #424242;
            opacity: 0.9;
          }
          @media only screen and (min-width: 1676px) {
            min-height: 50px;
          }
          @media only screen and (min-width: 2000px) {
            height: 50px;
          }

          @media only screen and (max-width: 576px) {
            width: 100%;
          }
        }
        button {
          width: 100%;

          padding: 4px 10px;
          border-radius: 6px;
          background-color: #212122;
          color: #fff;
          text-align: center;
          margin-top: 1rem;
          font-family: "SFCompactDisplay-Semibold";
          font-size: 16px;
          border: none;
          &:hover {
            border: none;
          }
          &:active {
            border: none;
          }
          @media only screen and (min-width: 1676px) {
            padding: 15px 10px;

            // min-height: 45px;
          }
          @media only screen and (min-width: 2000px) {
            padding: 15px 10px;

            // min-height: 45px;
          }
        }
      }
    }
  }
  .contest-cities-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 80%;
    height: auto;
    margin-top: 1rem;
    margin-bottom: 3rem;
    @media only screen and (max-width: 576px) {
      height: auto;
      width: 100%;
      flex-direction: column;
      margin-bottom: 1rem;
    }
    .pack-image-conainer {
      display: flex;
      flex-direction: column;
      width: 30%;
      height: auto;
      border-top-right-radius: 6px;
      border-top-left-radius: 6px;
      @media only screen and (max-width: 576px) {
        width: 90%;
        margin: auto auto;
        margin-bottom: 1rem;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
      }
      .contest-package-desc {
        color: #fff;
        background-color: black;
        background: transparent;
        opacity: 0.85;
        background-color: #000;
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 6px;
        height: auto;
        padding: 0.5rem 0px;
        display: flex;
        flex-direction: column;
        font-family: "Rubik-Regular";

        p {
          font-size: 12px;
          width: 45%;
          margin: 0px;
          height: 100%;
          display: flex;
          justify-items: center;
          text-align: left;
          align-items: center;
          margin-left: 0.4rem;
          box-sizing: border-box;
          font-weight: 500;
          line-height: 1.33;
          font-family: "Rubik-Medium";
        }
        .sub-text {
          font-weight: light;
          font-family: "Rubik-Regular";
        }
      }
    }
  }
  .popular-grad-cities {
    @include display-column;
    display: flex;

    align-items: center;
    margin: auto auto;
    margin-bottom: 0rem;

    h4 {
      width: auto;
      text-align: center;
      color: #142b47;
      font-size: 21px;
      font-family: "Rubik-Regular";
      margin-bottom: 1rem;
      @media only screen and (max-width: 576px) {
        margin-bottom: 0;
      }
    }
  }
}

/* .carnival-banner {
  max-width: 100%;
  height: auto;

  @media only screen and (max-width: 576px) {
  }
}
.container-contest {
  width: 100%;
  min-height: 900px;
  background-color: white;
  margin-top: 3rem;
  .contest-images-container {

    width:70%;
    display: flex;
    flex-direction: row;
    margin: auto auto;
    margin-bottom: 4rem;
    .contest-images {
      width: 60%;
      margin-left: 7rem;
      .contest-img-list{
        width: 90%;
        margin: auto auto;
        margin-top: 1rem;
      img {
        width: 40%;
        margin-right: 1rem;
        margin-bottom: 1rem;
        border-radius: 6px;
      }}
    }
    .contest-img-desc{
      width:40%;
    p {
      color:#000;
      width: 70%;
      font-size: 21px;
      line-height: 1.43;
      letter-spacing: normal;
      color: #424242;
      font-family: "Rubik-Regular";

    }
    .limos-about {
      width: 70%;
       opacity: 0.7;
       font-family: Rubik;
       font-size: 13px;
       font-weight: normal;
       font-stretch: normal;
       font-style: normal;
       line-height: 1.54;
       letter-spacing: normal;
       color: #424242;
    }
    input {
      margin-top: 0.5rem;
      width: 80%;
      border-radius: 7px;
      background-color: transparent;

      border:0.4px solid #fff;
      color: #333;
      -webkit-box-shadow: 0 2px 10px 1px rgba(0,0,0,0.5);
      box-shadow: 0 2px 10px 1px rgba(0,0,0,0.5);
      border-radius: 6px;
    }
    button {
      width: 80%;
      background-color: #000;
      color: #fff;
      margin-top: 0.5rem;
      border-radius: 7px;
      height: 34px;
      border-radius: 7px;
      background-color: #000;
      margin: auto auto;
      margin-top: 0.5rem;
      border: none;

    }
  }
  }

  .contest-hero-banner {
    width: 100%;
    height: 500px;
    background-color: white;
    margin-bottom: 4rem;

    .img-container {
      position: relative;
      .banner-container {
        height: 541px;
        object-fit: contain;
        @media only screen and (max-width: 576px) {
          height: 620px;
        }
        img {
          width: 100%;
          height: 100%;
        }

      }
      .contest-desc {
        
        width: 40%;
        height: 40%;
        position: absolute;
        top:10%;
        left:30%;
        .contest-banner {
          width: 139px;
          height: 74px;
          position: relative;
          p {
            top:50%;
            left:70%;
            text-align: center;
            span {
              text-align: center;
            }   
          }
         
        }
        .limos-logo-txt {
          text-align: center;
          font-size: 3rem;
          width:100%;
        }
        .contest-desc-level-1 {
          width: 45%;
          height: auto;
          margin: auto auto;
          font-family: "Rubik";
          font-size: 16px;
        }
        .contest-desc-level-2 {
          margin-top: 2rem;
          display: flex;
          flex-direction: column;
          width: 100%;
          span {
            text-align: center;
          }
          h5 {
            text-align: center;

          }
         
        }
        .contest-enroll {
          width: 50%;
          text-align: center;
          margin: auto auto;
          margin-top: 0.4rem;
          input{
            width: 100%;
            height: 41px;
              background: transparent;
              opacity: 0.2;
              color: #000;
              border: none;
              background-color: #000;

              border:0.4px solid #fff;
              color: #333;
              -webkit-box-shadow: 0 2px 10px 1px rgba(0,0,0,0.5);
              box-shadow: 0 2px 10px 1px rgba(0,0,0,0.5);
              border-radius: 6px;
          }
          & ::placeholder {
            color:#000;
            
          }
          .enroll-btn {
            width: 100%;
            height: 34px;
            border-radius: 7px;
            background-color: #fff;
            margin: auto auto;
            margin-top: 0.5rem;
            border: none;
          }
        }

      }

      .sprites-bg {
        width: 40%;
        height: 40%;
        position: absolute;
        bottom: 2rem;
        @media only screen and (max-width: 576px) {
          bottom: 4rem;
          width: 100%;
          height: 20%;
        }
        .sprite-group {
          position: relative;
          .cruise-logo {
            height: 35px;
            align-items: center;
            display: flex;
            text-align: center;
            justify-content: center;
            justify-items: center;
            margin-bottom: 1rem;
            @media only screen and (max-width: 576px) {
              margin-bottom: 0.4rem;
            }

            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
          .img-desc {
            width: 100%;
            margin: auto auto;
            height: auto;
            text-align: center;

            span {
              font-size: 16px;
              line-height: 12px;
              color: #142b47;
              font-family: "Rubik-Regular";
              letter-spacing: normal;
              @media only screen and (max-width: 576px) {
                font-size: 16px;
                line-height: 0;
              }
            }
          }
          .banner-sprites {
            list-style-type: none;
            display: flex;
            padding: 0;
            justify-content: center;
            align-items: center;
            justify-content: center;
            .li-flex {
              display: flex;
              flex-direction: column;
              width: 100px;
              align-items: center;
              .img-div {
                width: 38px;
                height: 38px;
                object-fit: contain;
                img {
                  object-fit: contain;
                  width: 100%;
                  height: 100%;
                }
              }
            }
            .li-desc {
              color: #142b47;
              font-size: 11px;
              font-weight: 500;
              font-family: "Rubik-Medium";
              text-align: center;
              font-weight: bold;
            }
          }
        }
      }
      .search-form-carnival {
        position: absolute;
        top: 2rem;
        right: 7rem;
        width: auto;
        height: 200px;
        box-sizing: border-box;
        padding: 9px 14px 17px;
        @media only screen and (max-width: 576px) {
          top: 0rem;
          right: 1.5rem;
        }
        form {
          //margin-top: 4rem;
        }

        #index-page {
          width: 300px;
          height: 356px;
          font-family: "SFCompactDisplay";
          box-sizing: border-box;
          position: relative;
          .form-title {
            font-size: 17px !important;
            font-weight: 500;
          }
          .new-form-label {
            label {
              font-size: 11px;
            }
          }

          .form-error-message {
            .error-label {
              font-size: 11px;
            }
          }
          .form-field {
            height: 45px;
            padding: 0;
            .form-checkboxes {
              .form-label {
                font-size: 15px;
              }
            }
            .trip-date-picker {
              line-height: 1;
            }
            .new-form-label {
              font-size: 11px;
              font-weight: 500;
              padding-top: 0.3rem;
            }
            select {
              font-size: 12px;
              font-weight: 500;
              top: 0;
              margin-bottom: 0px;
              padding-top: 1rem;
            }
            input {
              font-size: 12px;
              font-weight: 500;
              padding-top: 0;
            }
            .rbt {
              line-height: 1;
              height: 1rem;
              div {
                padding: 0px;
                height: 100%;
              }
              #pickupPlace,
              #dropoffPlace {
                line-height: 1;
                height: auto;
              }
            }

            .hour-time-container {
              top: -5px;
              select {
                padding-top: 0rem;
              }
            }
          }
        }
        #search-button {
          width: 100%;
          min-height: 34px;
          box-sizing: border-box;
          padding: 0;
          border-radius: 6.9px;
          background-color: #fff;
          font-size: 15px;
          font-weight: 800;
          font-family: "SFCompactDisplay-Medium";
          font-weight: bold;
        }
      }
    }
  }
 
  .cruise-ships-container {
    width: 100%;
    background-color: white;
    margin-top: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media only screen and (max-width: 576px) {
      margin-top: 8rem;
    }
    .cruise-ship-desc {
      color: #142b47;
      text-align: center;
      margin-bottom: 2rem;
      font-size: 21px;
      font-family: "Rubik-Regular";
      @media only screen and (max-width: 576px) {
        font-size: 16px;
        margin-bottom: 0.2rem;
      }
    }
    .cruise-ship-names {
      width: 332px;
      display: flex;
      flex-direction: column;
      color: #4d74a3;
      align-items: center;
      margin: auto auto;
      font-size: 14px;
      line-height: 2.21;
      margin-bottom: 3.5rem;
      @media only screen and (max-width: 576px) {
        margin-bottom: 0.2rem;
      }
    }
  }
  .popular-grad-cities {
    display: flex;
    flex-direction: column;

    align-items: center;
    margin: auto auto;
    margin-bottom: 4rem;

    h4 {
      width: auto;
      text-align: center;
      color: #142b47;
      font-size: 21px;
      font-family: "Rubik";
      margin-bottom: 1rem;
    }
    .cruise-ports-container {
      display: flex;
      flex-direction: row;
      width: 780px;
      height: 150px;
      @media only screen and (max-width: 576px) {
        height: auto;
        width: 100%;
        flex-direction: column;
      }
      .cruise-ports {
        margin: auto auto;
        display: flex;
        flex-direction: column;
        width: 243px;
        height: 104;
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
        @media only screen and (max-width: 576px) {
          margin-bottom: 0.8rem;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
        .cruise-port-desc {
          color: #fff;

          background-color: black;
          background: transparent;
          opacity: 0.85;
          background-color: #000;
          border-bottom-right-radius: 6px;
          border-bottom-left-radius: 6px;
          height: 44px;

          p {
            font-size: 12px;
            width: 40%;
            margin: 0px;
            height: 100%;
            display: flex;
            justify-items: center;
            text-align: left;
            align-items: center;
            font-family: "Rubik";
            margin-left: 0.4rem;
            box-sizing: border-box;
          }
        }
      }
    }
  }
  .cruise-footer-section {
    margin-top: 2rem;
    width: 100%;
    @media only screen and (max-width: 576px) {
      margin-top: 0.2rem;
    }
    .book-about-section {
      width: 52%;
      margin: auto auto;
      display: flex;
      flex-direction: column;
      @media only screen and (max-width: 576px) {
        flex-direction: row;
        height: 350px;
        width: 60%;
      }
      .book-now {
        color: #424242;
        @media only screen and (max-width: 576px) {
          margin-right: 4rem;
        }

        p {
          color: #000;
          font-size: 14px;
          font-weight: 500;
          padding: 0;
          margin: 0;
        }
        span {
          font-size: 14px;
          font-weight: normal;
        }
        .footer-links {
          margin-top: 0.5rem;
          display: flex;
          flex-direction: column;
          a {
            font-size: 14px;
            padding-bottom: 0.4rem;
            color: #424242;
            @media only screen and (max-width: 576px) {
              width: 150px;
              padding: 0px;
            }
          }
        }
      }
    }
  }
  .margin-space {
    margin-bottom: 1rem;
  }
}  */
