@import "src/scss/globals";
@import "src/scss/brands";

@include brandify {
  .tripTypeCell.btn-group > .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    padding-top: 0.6rem;
  }
  .tripTypeCell {
    display: block;
    margin-bottom: 6rem;

    .tripTypeButton.btn.btn-primary.btn-block {
      color: $color-interactive;
      border: 0.04rem solid $color-off-white;
      text-align: left;
    }
    .tripTypeButton.btn.btn-primary.btn-block:hover {
      background-color: $color-off-white;
    }
  }
}
