@import "src/scss/brands";
@include brandify {
  .footerLinks {
    margin-bottom: 0.5rem;

    a {
      color: gold !important;
      &:hover {
        color: $color-interactive;
      }
    }
  }

  footer {
    margin-top: 3rem;
  }

  footer ul {
    list-style-type: none;
    padding-left: 0;

    a {
      color: gold !important;
      &:hover {
        color: $color-interactive;
      }
    }
  }

  .footerPipe {
    color: $color-off-white;
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }
}
.footer-gta-fixel {
  display: none;
}
