@import "src/scss/brands";

@include brandify {
  .promo-flex-box {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  .promo-code-container {
    position: relative;
    margin-top: 0.5rem;

    .form-control.promo-code-input {
      border: 2px solid $color-disabled;
      text-indent: 1rem;
      border-radius: 7px;
    }

    .promo-code-icon {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      display: flex;
      align-items: center;
      margin-left: 1rem;
      color: $color-disabled;
    }
  }

  .promo-code-button.btn {
    border-radius: 7px;
    margin-top: 0.7rem;
    margin-bottom: 0.3rem;
  }

  .promo-code-result {
    margin-bottom: 1rem;
    text-align: right;
    color: #E95265  ;
  }
  .promo-code-result.hidden {
    visibility: hidden;
  }

  .promo-code-button.btn:disabled {
    background-color: $color-disabled;
  }
}
}
