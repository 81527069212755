@import "src/scss/brands";

.extras {
  list-style: none;
  padding-left: 0;
}

.terms-and-conditions {
  margin-top: 15px;
}

.termsAndCheckoutButton .branded-checkbox {
  margin-top: 0.7rem;
}

.StripeElement {
  margin-top: 0.3rem;
}

.agreement-container {
  color: black;
  padding: 32px 16px;
  background-image: linear-gradient(
    to bottom left,
    rgba(255, 255, 255, 0.69),
    rgba(255, 255, 255, 0.96)
  );
  box-shadow: 0 1px 1.5px 0 rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.24);

  .agreement-checkbox {
    margin-right: 1rem;
  }
}

.stripe {
  font-size: 1.5em;
  vertical-align: -0.3em;
}

@include brandify {
  .termsAndCheckoutButton {
    color: $color-body-text;

    .assistance-text-container {
      border-radius: 5px;
      border: solid 0.8px rgba(255, 255, 255, 0.3);
      background-color: rgba(255, 255, 255, 0.16);
      font-family: "Rubik-Regular";
      width: fit-content;
      margin: auto;
      display: flex;
      align-items: center;

      &__link {
        display: inherit;
      }

      @media screen and (max-width: 667px) {
        width: 95%;
      }

      .small-image {
        height: 40px;
        margin: 4px 9px 0px 9px;
      }

      .text-container {
        margin: 0 10px;
        .line1,
        .line2 {
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 16px;
          color: #fff;
        }

        .line2 {
          font-size: 13px !important;
          display: inline-block;
          margin-right: 10px;
        }
      }
    }

    .checkout-wrapper {
      font-family: "SFCompactDisplay-Medium";
      &__title_countdown_container {
        display: flex;

        .checkout-wrapper-title-container {
          flex: 9;
        }
        .checkout-wrapper-countdown-container {
          flex: 1;

          .countdownContainer {
            position: static;
            transform: none;
            text-align: center;
            min-height: auto;
            padding-top: 10px;

            .wrapper {
              flex-direction: row;
            }
          }
        }
      }
    }

    hr {
      border-top: 1px solid $color-body-text;
    }

    h4 {
      color: $color-dynamic-content;
      font-weight: 700;
    }

    ul:last {
      margin-bottom: 1.5rem;
    }
  }

  .extras-heading {
    font-weight: bold;
  }

  .extras-heading,
  .extras-title,
  .extras-value,
  .extras-subtitle {
    color: white;
  }

  .fallback-without-trip-assurance {
    color: white;

    h5 {
      font-size: 0.95rem;
      margin-top: 0;
      margin-bottom: 0;
    }

    p {
      font-size: 0.9rem;
      line-height: 1.1rem;
    }
  }

  .fallback-payment-container {
    color: #fff;
    padding-top: 10px;

    .credit-card-heading-container {
      text-align: center;
    }

    .checkout-form {
      font-family: "SFCompactDisplay-Medium";
      .flex-container {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;

        @media screen and (max-width: 667px) {
          flex-direction: column;
        }

        .form-group {
          position: relative;
          flex: 1 1 auto;
          margin-right: 7px;
          margin-bottom: 0 !important;

          &.card-number {
            width: 49%;

            @media screen and (max-width: 667px) {
              width: 98%;
            }
          }

          &.cvv {
            width: 45%;
          }
        }

        .form-control {
          padding: 20px 0px 0px 14px;
          height: 50px;
          font-size: 16px;
          font-family: "SFCompactDisplay-Medium";
        }

        label {
          padding: 5px 0px 0px 15px;
          position: absolute;
          left: 0px;
          top: 0px;
          font-size: 15px;
          font-family: "SFCompactDisplay-Medium";
        }

        .text-danger {
          font-size: 15px;
          font-family: "SFCompactDisplay-Medium";
          color: #e95265 !important;
          line-height: 1.2;
        }
      }

      .payment-expiry-cvv {
        width: 70%;
        display: flex;
        justify-content: space-evenly;

        @media screen and (max-width: 667px) {
          display: flex;
          flex-direction: row;
          width: 100%;
        }

        &.expiry-date {
          width: 40%;

          @media screen and (max-width: 667px) {
            width: 60%;
          }
        }

        &.cvv {
          width: auto;

          @media screen and (max-width: 667px) {
            width: 40%;
          }
        }
      }

      .checkout-btn-container {
        margin-right: 10px;

        .checkout-button {
          background-color: #f2e84d;
          color: #2a2a2a;
          border-radius: 5.2px;
          font-size: 17px;
          font-family: "SFCompactDisplay-Medium";
          font-weight: 700;
          line-height: normal;
          padding: 5px 0;
          border: 1px solid #f2e84d;

          span {
            display: block;
          }

          .total-price-label {
            font-weight: 500;
            font-size: 12px;
            font-family: "SFCompactDisplay-Medium";
          }
        }
      }

      .promo-code-container {
        width: 50%;
        display: inline-block;
        font-size: 13px;

        @media screen and (max-width: 667px) {
          margin-right: 15px;
        }
        input {
          font-size: 19px;
        }

        .form-control {
          height: 50px;
          text-indent: 0;
          padding-left: 15px;
        }
      }

      .promo-code-button {
        width: 48%;
        display: inline-block;
        margin: 0;
        height: 50px;
        font-size: 19px;

        @media screen and (max-width: 667px) {
          font-size: 20px;
        }
      }

      .promo-code-result {
        margin-right: 20px;
      }
    }
  }

  .checkoutPageTitle {
    color: white;
    font-weight: 400;
    font-style: italic;
    text-align: center;
    width: 100%;
    margin: 32px auto;
    .form-jumbo {
      font-family: "SFCompactDisplay-Medium";
      padding: 16px;
      width: 420px;
      background-image: linear-gradient(
        to bottom left,
        rgba(0, 0, 0, 0.5),
        rgba(0, 0, 0, 0.2)
      );
      box-shadow: 0 1px 1.5px 0 rgba(0, 0, 0, 0.12),
        0 1px 1px 0 rgba(0, 0, 0, 0.24);
      .top-text {
        font-style: normal;
        font-family: "SFCompactDisplay-Medium";
      }
      .carrier-input {
        position: absolute;
        top: -15px;
        background-color: #000;
        padding: 0;
        height: 18px;
      }
      p {
        margin-top: 16;
        max-width: 400;
        margin: 16px auto;
        font-style: normal;
        font-family: "SFCompactDisplay-Medium";
      }

      .dropdown-menu {
        border-radius: 10px;
        margin-top: 20px;
      }
      &.dropdown-menu:hover {
        background-color: #3498db;
      }

      .form-button {
        text-transform: inherit;
        border-radius: 7px !important;
        font-weight: 400;
        min-height: auto;
        font-family: "SFCompactDisplay-Medium";
        letter-spacing: normal;
        background-color: #e0b542;
      }
      .service-type-dropdown-container {
        position: relative;
        // height: 65px;
      }

      .service-type-dropdown-container select {
        -webkit-appearance: none;
        -moz-appearance: none;
        text-indent: 1px;
        font-family: "SFCompactDisplay-Medium";
        background-color: transparent;
        color: #fff;
        font-size: 15px;
        padding-top: 0;
        padding-bottom: 0;
        position: absolute;
        height: 100%;
        top: 3px;
        left: 0;
        padding-top: 18px;
        padding-left: 15px;
        background-color: #000;
        option,
        optgroup {
          color: #000;
        }
        option[value=""] {
          color: rgba(255, 255, 255, 0.6);
        }
      }

      .service-type-dropdown-container .down-arrow-container {
        display: inline-block;
        color: #fff;
        width: 48%;
        text-align: end;
        position: relative;
        top: 16px;
        @media screen and (max-width: 567px) {
          top: 10px;
        }
        .svg-down-icon {
          display: inline-block;
          fill: currentColor;
          line-height: 1;
          stroke: currentColor;
          stroke-width: 0;
        }
      }

      .service-type-dropdown-container select::-ms-expand {
        display: none;
      }

      .add-form-border {
        border: 2.2px solid hsla(0, 0%, 100%, 0.4) !important;
        border-radius: 7px;
        background-color: transparent;
        margin-top: 0;
        padding-top: 5px;
        padding-bottom: 5px;
        margin-bottom: 7px;
        height: 57px;
        #carrier-filter {
          .down-arrow:after {
            cursor: pointer;
            content: "▼";
            position: absolute;
            top: -15px;
            left: calc(100% - 40px);
            height: 100%;
            font-size: 80%;
            padding: 10px 7px;
            color: white;
            pointer-events: none;
          }
        }
        .passengers-count-field.fullWidth {
          width: 100%;
          float: right;
          .service_type:after {
            border: 10px solid red;
            content: "▼";
            position: absolute;
            top: 6;
            left: calc(100% - 30px);
            height: 100%;
            font-size: 80%;
            padding: 12px 7px;
            background-color: DodgerBlue;
            color: white;
            pointer-events: none;
          }
          .new-form-label {
            text-align: left;
            color: #fff;
            padding-left: 15px;
            font-size: 15px;
            label {
              margin-bottom: 0;
              text-transform: uppercase;
              font-family: "SFCompactDisplay-Medium";
              font-style: normal;
            }
          }
          input {
            height: 20px;
            padding-top: 0;
            padding-bottom: 0;
            background-color: transparent;
            color: #fff;
            font-size: 15px;
            font-family: "SFCompactDisplay-Medium";
            padding-left: 15px;
            &::-webkit-input-placeholder {
              color: rgba(255, 255, 255, 0.65);
              font-family: "SFCompactDisplay-Medium";
            }

            &:-moz-placeholder {
              color: rgba(255, 255, 255, 0.65);
              opacity: 1;
              font-family: "SFCompactDisplay-Medium";
            }

            &::-moz-placeholder {
              color: rgba(255, 255, 255, 0.65);
              opacity: 1;
              font-family: "SFCompactDisplay-Medium";
            }

            &:-ms-input-placeholder {
              color: rgba(255, 255, 255, 0.65);
              font-family: "SFCompactDisplay-Medium";
            }

            &::-ms-input-placeholder {
              color: rgba(255, 255, 255, 0.65);
              font-family: "SFCompactDisplay-Medium";
            }

            &::placeholder {
              color: rgba(255, 255, 255, 0.65);
              font-family: "SFCompactDisplay-Medium";
            }

            &:focus {
              border: 0;
              outline: 0;
              box-shadow: none;
            }
          }
        }
      }
    }

    svg {
      margin-right: 8px;
    }
  }

  .btn {
    min-height: 3.25rem;
    border: none;
    font-weight: 800;
    font-size: 1.4rem;
    font-weight: bold;
    border-radius: 0;
    /*margin: 5px;*/
  }
}

.purchaseButton {
  margin-top: 1rem;
  margin-bottom: 5rem;
}

.bookingContainerRoot {
  .checkout-form {
    .btn:hover:not(:disabled) {
      border: none;
    }

    .card-number-fields {
      .card-number {
        width: 50%;
        float: left;

        @media screen and (max-width: 667px) {
          width: 100%;
          float: left;
        }
      }

      .expiry-date {
        width: 30%;
        float: left;

        @media screen and (max-width: 667px) {
          width: 50%;
          float: left;
        }
      }

      .cvv {
        width: 20%;
        float: left;

        @media screen and (max-width: 667px) {
          width: 45%;
          float: right;
        }
      }
    }

    input {
      & :focus {
        outline: 0;
      }

      border: 2.2px solid hsla(0, 0%, 100%, 0.4) !important;
      border-radius: 7px;
      background-color: transparent;
      padding-top: 5px;
      padding-bottom: 5px;
      margin-bottom: 10px;
      color: #fff;
    }

    .form-control:focus {
      outline: 0;
      box-sizing: inherit;
      color: #fff;
    }

    .pay-securely-container {
      text-align: center;
      padding-top: 5px;

      .securely-text {
        display: inline;
        color: #fff;
        font-size: 14px;
        opacity: 0.45;
        line-height: 2.22;
        margin-left: 5px;
        font-family: "SFCompactDisplay-Medium";
      }
    }

    .payment-images-container {
      .payment-images {
        justify-content: center;
        margin-top: 30px;
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        padding: 0;

        .payment-icon {
          margin: 0 15px;
          padding: 0;
        }
      }
    }
  }

  .form-submit-button {
    min-width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: 0;
    border: 0 !important;

    .form-loader {
      border: 2px solid #f3f3f3;
      border-radius: 50%;
      border-top: 2px solid #3498db;
      width: 15px;
      height: 15px;
      -webkit-animation: spin 1s linear infinite;
      /* Safari */
      animation: spin 1s linear infinite;
    }
  }

  .checkout-page-title-container {
    width: 100%;
  }

  .checkout-page-container {
    width: 100%;
    display: flex;

    @media screen and (max-width: 667px) {
      display: block;
    }

    .flight-carrier-right-container {
      width: 50%;
      background-color: #000;
      margin-top: 20px;

      @media screen and (max-width: 667px) {
        width: 100%;
      }

      .flight-carrier-details {
        width: 63%;
        margin: auto;
      }

      .booking-conditions-container {
        width: 75%;
        margin: auto;

        @media screen and (max-width: 667px) {
          width: 100%;
        }

        .booking-conditions-title {
          font-size: 23px;
          color: #fff;
          font-family: "Rubik-Regular";
          line-height: 1.11;
          text-align: left;
        }

        .booking-conditions-hr-line {
          height: 2px;
          border: solid 1px #fff;
          width: 100%;
          opacity: 0.2;
          margin: 15px 0;
        }
      }
    }

    .checkout-page-left-container {
      width: 50%;
      background-color: #fff;
      margin-top: 20px;

      @media screen and (max-width: 667px) {
        width: 100%;
      }

      .search-results-checkout-container {
        width: 94%;
        margin: auto;

        @media screen and (max-width: 667px) {
          width: 100%;
        }
      }
    }
  }
}

.network-error-message {
  p {
    color: #dc3545 !important;
    font-size: 30px;
  }

  ul {
    li {
      color: #dc3545 !important;
    }
  }
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.reservations-terms-container {
  .reservations-terms-heading {
    font-weight: "bold";
    text-align: center;
    margin: auto;
  }

  .reservations-terms-paragarph {
    text-align: left;
    margin: 0;
  }
}

.form-group.form-check {
  .form-check-input {
    margin-top: 8px;
  }

  .form-check-label {
    font-family: "SFCompactDisplay-Medium";
    font-size: 15px;

    &.trip-assurance {
      text-decoration: underline;
      cursor: pointer;
    }

    .terms-condition {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.price-breakdown-container {
  display: flex;
  flex-direction: column;
  font-size: 15px;
  font-family: "SFCompactDisplay-Medium";
  margin-bottom: 10px;

  .promo-savings-cta {
    border-radius: 2.1px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.35);
    border: solid 2px #f2e84d;
    color: #fff;
    font-size: 13px;
    font-weight: 500;
    padding: 0 8px;
    height: 20px;
    display: flex;
    align-items: center;
    width: auto;
    justify-content: center;
    min-width: 270px;

    @media screen and (max-width: 667px) {
      width: auto;
      margin: auto;
    }
  }

  .price-bd-cta {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;

    @media screen and (max-width: 667px) {
      display: block;
    }

    .price-breakdown-heading {
      width: 40%;

      @media screen and (max-width: 667px) {
        text-align: center;
        width: 100%;
        margin-bottom: 5px;
      }

      .promo-savings-cta {
        border-radius: 2.1px;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.35);
        background-color: #f2e84d;
        color: #000;
        font-size: 11px;
        font-family: "Rubik-Medium";
        font-weight: 500;
        padding: 0 3px;
        height: 18px;
        display: flex;
        align-items: center;

        @media screen and (max-width: 667px) {
          width: 100%;
          text-align: center;
          justify-content: center;
        }
      }
    }
  }

  .price-breakdown-row {
    display: flex;
    flex-direction: row;
    position: relative;

    .cell-item {
      width: 50%;
      display: flex;

      @media screen and (max-width: 667px) {
        width: 60%;
      }

      .extra-hours-text {
        margin-left: 4px;
      }
    }

    .actual-price {
      width: 15%;

      .dollar-sign {
        text-decoration: line-through;
        text-decoration-color: red;
        text-decoration-thickness: 2px;
      }
    }

    .cell-value {
      width: 10%;
    }

    .cell-hrs {
      width: 10%;
    }
  }

  .total-hr-line {
    height: 1px;
    border: solid 1px #fff;
    width: 100%;
    opacity: 0.2;
    margin-top: 5px;
    margin-bottom: 10px;
  }

  .tooltiptext {
    display: none;
    width: 120px;
    background-color: white;
    text-align: center;
    border-radius: 6px;
    padding: 5px 3px;
    position: absolute;
    z-index: 1;
    left: 100px;
    color: #000;
    top: 0px;
    cursor: pointer;
    font-size: 10px;
    border: 1px solid #000;
  }

  .hover-icon {
    margin-top: 5px;
    margin-left: 5px;
  }

  .hover-icon:hover .tooltiptext {
    display: block;
  }

  .dot {
    height: 12px;
    width: 12px;
    background-color: #000;
    border-radius: 50%;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #fff;
    font-size: 8px;
    font-weight: 500;
  }

  .round-trip-wrapper {
    .round-trip-heading-container {
      display: flex;
      margin: 10px 0;

      .first_leg_container,
      .second_leg_container {
        width: 50%;
        text-align: center;
      }
    }

    .round-trip-price-breakdown-container {
      display: flex;

      &__first_leg,
      &__second_leg {
        width: 50%;

        .cell-item {
          width: 50%;
          display: flex;
        }

        .cell-value {
          width: 18%;
        }

        .cell-hrs {
          width: 20%;
        }
      }
    }
    .total-hr-line {
      width: 90%;
    }
  }
}