.image-container {
    img {
        height: 147px;
        background-color: #0e0e0e;
    }
}

.middle-text-container {
    background-color: #fff;
    height: 100%;

    & .call-for-pricing-copy {
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: right;
        color: #5178bf;
        height: 48px;
    }
}

.call-for-pricing-container {
    background-color: #f6f6f6;
    height: 100%;
    text-align: center;

    & .call-for-pricing-copy {
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: right;
        color: #5178bf;
        height: 48px;
    }
}