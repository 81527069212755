@import "src/scss/globals";
@import "src/scss/brands";

.container {
  font-family: "Rubik-Regular";
  margin-top: 10px;
  ul {
    padding: 0px;
    margin-bottom: 0;
    li {
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      color: #212121;
    }
  }
  .show-more {
    border: none;
    background: none;
    color: inherit;
    padding: 0;
    font-size: 13px;
    cursor: pointer;
    outline: inherit;
  }
}

.amenities-container {
  max-height: 136px;
  overflow-y: auto;
  width: 100%;
}

@include brandify {
  .amenities-inner-container {
    padding-top: 0.5rem;
    font-weight: 600;
    font-size: 1.3rem;
    width: 100%;

    .amenity {
      margin-left: 0.7rem;
      font-weight: 300;
    }

    .checkMarkIcon {
      margin-right: 0.7rem;
    }
  }

  .btn.show-more-amenities {
    font-size: 0.8rem;
    background-color: inherit;
    color: black;
    padding: 0.2rem;
    height: 2rem;
    border: 0;
    text-decoration: underline;

    &:hover,
    &:hover:not(:disabled) {
      border: none;
    }
  }
}
