@import "src/scss/globals";
@import "src/scss/brands";

$carousel-indicator-size: 0.55rem;

.amenities-container {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  width: 100%;
}

@include brandify {
  .search-result-wide {
    background-color: white;
    margin: 1rem auto;
    border-radius: 0;
    color: black !important;
    overflow: hidden;
    .searchwide-discount {
      background-color: #f2e84d;
      right: 0;
      position: absolute;
      top: 5px;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.35);
      border-radius: 3px;
      padding: 3px 6px 3px 7px;
      z-index: 1;
      font-family: "Rubik-Medium";
      font-size: 11px;
      font-weight: 500;
      z-index: 10;
    }

    @media (min-width: 1024px) {
    }

    .carousel-inner,
    .carousel-inner .carousel-item,
    .carousel.slide {
      height: auto;
    }

    .image-carousel-container {
      background-color: #0e0e0e;
      @media (min-width: 1024px) {
        width: 40%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .features-container {
      @media (min-width: 1024px) {
        display: flex;
      }

      .image-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        // align-items: center;
        gap: 10px;

        .custom-image {
          width: 300px;
          max-height: 250px;
          min-height: 210px;
        }
      }

      .carousel-arrow {
        color: $color-interactive;
      }

      .carousel-indicators {
        margin-bottom: 0rem;

        .active {
          background-color: $color-interactive;
        }

        li {
          border-radius: 50%;
          width: $carousel-indicator-size;
          height: $carousel-indicator-size;
          background-color: $gallery;
        }
      }

      a.carousel-control-next,
      a.carousel-control-prev {
        opacity: 1;
      }

      .carousel-image-container,
      .carousel-review-container {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;

        img {
          max-height: 250px;
          min-height: 210px;
        }
      }

      .content {
        width: 100%;

        @media (min-width: 1024px) {
          display: flex;
        }

        .info-container,
        .about-and-ratings {
          padding: 1rem;

          @media (min-width: 1024px) {
            padding: 0;
          }
        }

        .sub-info {
          margin-left: 1%;
          @media (min-width: 1024px) {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
          }
        }

        .info-container {
          width: 67%;
          padding: 20px 0 0 20px;

          @media screen and (max-width: 567px) {
            width: 100%;
          }

          .display-text {
            text-align: left;
            .rating-star {
              height: 100%;
              display: flex;
              align-items: center;
              span {
                top: -9px;
              }
              &.hidden-rating {
                display: none;
              }
            }
            .rating-info {
              display: flex;
              flex-direction: row;
              width: 29%;
              justify-content: space-between;
              align-items: flex-start;
              height: 35px;
              @media screen and (max-width: 667px) {
                width: 42%;
              }
              .ops-id {
                opacity: 0.5;
                font-family: "Rubik-Regular";
                font-size: 13px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 2;
                letter-spacing: normal;
                color: #212121;
                display: flex;
              }
              /*  @media (min-width: 520px) {
                display: flex;
                align-items: flex-start;
                flex-direction: column;
              } */

              .emptyStar {
                color: $gallery;
              }

              svg {
                color: #e8b317;
                width: 9.5px;
                height: 9px;
                margin: 0px;
              }

              .linkButton {
                background: none;
                color: black !important;
                padding: 0;
                height: auto;
                font-size: 16px;
                font-weight: 300;
                text-transform: none;
                border: none;

                &:hover,
                &:hover:not(:disabled) {
                  border: none;
                }
              }
            }

            h3 {
              margin-bottom: 0;
              font-size: 20px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
            }

            h4 {
              margin-top: 0;
              font-size: 14px;
              color: rgba(0, 0, 0, 0.5);
              &.round-trip-label {
                font-size: 24px;
              }
            }
          }
        }

        .new-price-container {
          width: 33%;
          background-color: #eee;
          padding-top: 20px;

          @media screen and (max-width: 567px) {
            width: 100%;
          }

          .price-container {
            text-align: right;
            height: 100px;

            .price {
              font-size: 32px;
              line-height: 24px;
              font-weight: 700;
              margin-bottom: 0;
              color: $color-interactive;
              margin-right: 40px;
              position: relative;
              .flat-rate-parent {
                display: flex;
                width: 100%;
                justify-content: end;
                justify-items: center;
                align-items: center;
                font-family: "Rubik-Medium";
                .flat-rate {
                  font-size: 30px;
                  font-weight: 500;
                }
                .rate-text {
                  font-size: 12px;
                  line-height: normal;
                  margin-left: 8px;
                }
                .rate-text-hr {
                  font-size: 26px;
                  font-weight: 500;
                }
                .flat-rate-hr {
                  font-size: 27px;
                  font-weight: 500;
                }
              }
              .rate-dis-hourly-extra-hrs,
              .rate-dis-hourly-not-extrs-hrs,
              .flat-rate-wrapper {
                font-size: 17px;
              }
              .price-red-slash {
                color: red;
                background-color: red;
                height: 1px;
                width: 27%;
                position: absolute;
                right: -3px;
                top: -6px;
                transform: rotate(-17deg);

                &.wide {
                  width: 45%;
                  transform: none;

                  @media screen and (max-width: 567px) {
                    width: 30%;
                    top: -5px;
                    right: 0px;
                    transform: none;
                  }
                }

                &.moz {
                  width: 15%;
                  right: -8px;
                  top: -6px;
                }

                @media screen and (max-width: 567px) {
                  width: 17%;
                  top: -5px;
                  right: 0px;
                }
              }

              &.discount {
                color: black;
                font-size: 14px;
                margin-bottom: 15px;
                // text-decoration: line-through;
                margin-right: 30px;
              }

              .dollar-sign {
                margin-right: 4px;
              }
            }
            .price-label {
              margin-top: 0;
              font-size: 14px;
            }
          }
        }

        .about-and-ratings {
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          flex-direction: column;
          width: 100%;

          @media screen and (max-width: 567px) {
            padding: 0;
          }

          @media (min-width: 520px) {
            flex-direction: row;
          }

          .about-info {
            @media screen and (max-width: 567px) {
              display: block;
            }
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            min-width: 120px;

            @media (min-width: 520px) {
              display: block;
              width: auto;
            }

            .capacities {
              display: flex;
              font-size: 15px;
              font-family: "Rubik-Regular";
              color: #212121;

              & > div {
                margin-right: 1rem;
              }
            }

            .rating-info {
              @media (min-width: 520px) {
                display: flex;
                align-items: flex-start;
                flex-direction: column;
              }

              .emptyStar {
                color: $gallery;
              }

              svg {
                color: gold;
              }

              .linkButton {
                background: none;
                color: black !important;
                padding: 0;
                height: auto;
                font-size: 16px;
                font-weight: 300;
                text-transform: none;
                border: none;

                &:hover,
                &:hover:not(:disabled) {
                  border: none;
                }
              }
            }
          }
        }

        .add-to-cart-button {
          text-align: center;
          button {
            min-height: 64px;
            font-size: 15px;
            font-weight: 600;
            font-family: "Rubik-Regular";

            @media screen and (max-width: 567px) {
              min-height: auto;
            }
          }

          .trending-container {
            margin-top: 35px;
          }

          .trending-first-text,
          .trending-second-text {
            color: #e82e55;
            font-size: 13px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            font-family: "Rubik-Medium";
            img {
              margin-right: 6px;
              margin-bottom: 4px;
            }
          }

          .disclaimer-under-button-text {
            .disclainer-text {
              opacity: 0.92;
              font-family: "Rubik-Regular";
              font-size: 9px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              letter-spacing: normal;
              text-align: center;
              color: #606060;
            }
          }

          @media (min-width: 1024px) {
            margin-top: 0;

            button {
              width: auto;
              min-height: auto;
            }
          }

          button {
            background-color: #416fbd;
            border: solid 1.4px #28529a;
            color: white;
            width: 70%;
            text-transform: none;

            // &:hover {
            //   border: 4px solid gold;
            // }
          }
        }
      }
    }

    .miniTripInfo {
      margin: 32px 16px 16px 16px;
    }
  }
}
.total-price-label {
  font-size: 15px;
  text-transform: none;
  margin-bottom: 3px;
}
.price-wrapper {
  margin-bottom: 0;
}
.round-trip-label {
  font-size: 20px;
}

.discount-banner-text {
  position: absolute;
}
