$color_1: #000;
$color_2: #fff;
$color_3: #0c0c0c;
$font-family_1: "Rubik-Medium";
$font-family_3: "Rubik-Regular";
$background-color_1: #0c0c0c;
$background-color_2: #e0b542;
$background-color_3: #fff;

.container-phone-quote {
  width: 100%;
  min-height: 900px;
  background-color: white;
  margin-top: 0rem;

  .second-section {
    width: 90%;
    margin: auto;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.35);
    background-color: $background-color_1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 90%;
    height: 100%;
    img {
      max-width: 100%;
      height: 100%;
      object-fit: contain;
    }
    .content {
      // margin-top: 20px;
      // padding-top: 21px;
      // box-sizing: border-box;

      .contact-marketing {
        width: 275px;
        border-radius: 6px;
        background-color: #fff;
        text-align: center;
        margin-bottom: 10px;
        color: #0c0c0c;
        font-family: "Rubik-Medium";
        font-size: 12px;
        font-weight: 500;
        padding: 8px 11px 8px 12px;
        user-select: auto;

        a {
          color: #0c0c0c;
        }
      }
    }
    h2 {
      margin-bottom: 10px;
    }
    .limos-logo {
      width: 150.4px;
      height: 20px;
      margin-left: -15px;
    }
    .The-ultimate-destina {
      width: 327px;
      height: 44px;
      margin: 9px 0 25px 0;
      font-family: $font-family_3;
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.47;
      letter-spacing: normal;
      color: $color_2;
    }
    .limos-call-btn {
      width: 225px;
      height: 32px;
      margin: 25px 0 18px;
      padding: 8px 11px 8px 12px;
      border-radius: 6px;
      background-color: $background-color_3;
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      font-family: $font-family_1;
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
      text-align: center;
      color: $color_3;
      @media only screen and (min-width: 1300px) and (max-width: 1500px) {
        min-width: 240px;
      }
    }
    .Call-8444273445-no {
      /* 	width: 202px;
		height: 16px; */
      font-family: $font-family_1;
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
      text-align: center;
      color: $color_3;
      margin: 0;
    }
  }
  .promo-banner {
    width: 219px;
    height: 21px;
    margin: 0 0 15px;
    font-family: $font-family_1;
    padding: 4px 6px;
    border-radius: 4px;
    //box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.35);
    background-color: $background-color_2;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
  }
  .-off-on-all-vehic {
    width: 207px;
    height: 13px;
    font-family: $font-family_1;
    font-size: 10px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
    text-align: center;
    color: $color_1;
    margin: 0;
  }
  @media screen and (min-width: 768px) {
    .second-section {
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      width: 850px;
      height: 270px;
      img {
        height: 100%;
        object-fit: contain;
      }
      .content {
        // text-align: left;
        // margin-top: 0;
        // padding-left: 30px;
        margin: 20px 10px 10px 20px;
      }
      .limos-logo {
        margin-left: -17px;
      }
    }
  }

  .service-vehicle-container {
    .rio-upper-container {
      width: 60%;
      @media only screen and (max-width: 576px) {
        width: 100%;
      }
    }
    .rio-section-container {
      width: 100%;
      .rio-section-description-container {
        font-size: 15px;
      }
    }
  }
  .promo-banner-section {
    width: auto;
    max-width: 210px;
    padding: 3px 2px;
    border-radius: 4px;
    background-color: #e0b542;
    text-align: center;
    font-size: 11px;
    font-weight: 500;
    font-family: "Rubik-Medium";
    font-size: 10px;
    color: #000;
    margin: auto;
    margin-bottom: 2%;
  }

  .request-quote-hero-banner {
    width: 100%;
    background-color: white;
    overflow: hidden;
    width: 100%;
    min-height: 600px;
    margin: 0 auto;
    background-image: url("https://assets.s3.amazonaws.com/images/front-end/contestImages/limos-quote-banner-v4.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top -63px;
    @media only screen and (max-width: 576px) {
      min-height: 880px;
    }

    @media only screen and (min-width: 1300px) and (max-width: 1500px) {
      background-position: center top -74px;
      min-height: 650px;
    }
    @media only screen and (min-width: 1500px) and (max-width: 2000px) {
      background-position: center top -84px;
      min-height: 680px;
    }
    @media only screen and (min-width: 2000px) and (max-width: 3000px) {
      background-position: center top -90px;
      min-height: 700px;
    }

    .img-container {
      width: 100%;
      .inner-contents-wrapper {
        width: 100%;
        position: relative;

        .container-black-extend {
          height: 100px;
          background-color: #131313;
          width: 100%;
          margin-bottom: 3%;
          @media only screen and (max-width: 576px) {
            margin-top: -1px;
          }
        }
        .banner-container {
          .banner-lg {
            @media only screen and (max-width: 576px) {
              display: none;
            }
          }
          .banner-sm {
            display: none;
            @media only screen and (max-width: 576px) {
              display: block;
              height: 100%;
              width: 100%;
              object-fit: cover;
              margin: auto auto;
              min-height: 1330px;
            }
          }

          img {
            width: 100%;
            object-fit: cover;
            max-height: 600px;
            min-height: 600px;
          }

          @media only screen and (max-width: 576px) {
            height: 400px;
            width: 100%;
            margin: auto auto;

            img {
              object-fit: contain;
              width: 100%;
            }
          }
        }
        .container {
          min-width: 750px;
          max-width: 750px;
          padding: 20px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin-top: 23%;
          @media only screen and (max-width: 576px) {
            min-width: 400px;
            max-width: unset;
            position: absolute;
            top: 32%;
            left: 3%;
            /*   margin-top: -62%;
          margin-left: -30%;
          min-width: 660px; */
          }
          @media only screen and (min-width: 1300px) and (max-width: 1500px) {
            min-width: 800px;
            max-width: 800px;
            margin-top: 22%;
          }
          @media only screen and (min-width: 1500px) and (max-width: 2000px) {
            margin-top: 20%;
            min-width: 900px;
            max-width: 900px;
          }
          @media only screen and (min-width: 2000px) and (max-width: 2500px) {
            margin-top: 20%;
          }
          @media only screen and (min-width: 2000px) {
            margin-top: 18%;
            min-width: 1200px;
          }

          .text-20-percent-off {
            width: auto;
            height: 18px;
            margin-top: 52px;
            text-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
            font-family: "SFCompactDisplay-Semibold";
            font-size: 19px;
            font-stretch: normal;
            font-style: normal;
            line-height: 0.95;
            letter-spacing: normal;
            text-align: center;
            color: #e0b542;
          }

          .search-form-phone-quote {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            margin-bottom: 10px;

            @media only screen and (max-width: 576px) {
              display: block;
            }

            .callUsNow {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 100%;
              margin: auto auto;
              min-height: 58px;
              height: 58px;
              box-sizing: border-box;
              padding: 0;
              border-radius: 6.9px;
              background-color: #fff;
              font-size: 18px;
              font-family: "SFCompactDisplay-Semibold";
              font-size: 17px;
              margin-top: 20px;
              background-color: #e0b542;
              color: #000 !important;
              border: 2px solid #e0b542;
              background-size: 200% 100%;
              transition: all 0.99s ease-in-out;
              line-height: 1.25;
              letter-spacing: normal;
              width: 400px;
              cursor: unset;
              text-align: center;
              margin-bottom: 3rem;

              a {
                color: #000 !important;
              }

              .dial-no {
                color: #2b2b2b;
              }

              @media (max-width: 576px) {
                width: 93%;
                margin: 10px auto !important;
              }
            }
          }
          .flex {
            .text {
              min-width: 680px;
              font-size: 21px;
              font-family: "Rubik-Regular";
              font-size: 18px;
              font-weight: normal;
              line-height: 27px;
              @media (max-width: 576px) {
                max-width: unset;
                min-width: 300px;
                font-size: 16px;
              }
              @media only screen and (min-width: 1300px) {
                font-size: calc(18px + 0.2vw);
              }
            }
            &1 {
              display: flex;
              justify-content: center;
              align-items: middle;
              .logo {
                min-width: 160px;
                max-width: 200px;
                height: 35px;

                @media only screen and (max-width: 576px) {
                  display: none;
                }
                @media only screen and (min-width: 1300px) {
                  height: 35px;
                }

                img {
                  width: 100%;
                  height: 100%;
                }
              }
            }
            &2 {
              display: flex;
              flex-direction: column;
              align-items: center;
              text-align: center;
              font-size: 13px;
              font-style: normal;
              margin-top: 20px;

              .icons {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-evenly;
                margin-top: 20px;
                width: 100%;
                @media only screen and (max-width: 576px) {
                  justify-content: space-evenly;
                  flex-wrap: wrap;
                  gap: 40px;
                  align-items: center;
                  width: 100%;
                  min-width: 50vh;
                }
                .icon {
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  @media only screen and (max-width: 576px) {
                    width: 22%;
                  }
                  .text-based-icon-one {
                    width: auto;
                    height: 50px;
                    font-size: 39px;
                    color: #fff;
                    padding: 0px;
                    font-family: "Rubik-Regular";
                    @media only screen and (max-width: 576px) {
                    }
                  }
                  .text-based-icon-two {
                    width: 64px;
                    height: 50px;
                    font-size: 39px;
                    color: #fff;
                    padding: 0px;
                    font-family: "Rubik-Regular";
                    @media only screen and (max-width: 576px) {
                    }
                  }

                  img {
                    width: 100%;
                    min-width: 80px;
                    height: 100%;
                    max-width: 120px;
                    object-fit: cover;
                    object-position: center;
                    width: 70px;
                    height: 57px;
                    @media only screen and (max-width: 576px) {
                      object-fit: contain;
                    }
                  }

                  .icon-text {
                    margin-top: 5px;
                    color: #fff;
                    width: 100%;
                    font-family: "Rubik-Medium";
                    font-size: 12px;
                    font-weight: 500;
                    height: 30px;
                    min-width: 100px;
                    @media only screen and (max-width: 576px) {
                      min-width: 110px;
                      font-size: 11px;
                    }
                    @media only screen and (min-width: 1300px) {
                      font-size: calc(12px + 0.2vw);
                    }
                  }
                }
              }
            }
            &3 {
              display: flex;
              justify-content: space-evenly;
              width: 85%;
              min-width: 690px;
              margin: auto;
              font-weight: 600px;
              span {
                font-size: calc(11px + 0.2vw);
              }

              .button {
                width: 42%;
                min-width: 243px;
                max-width: 380px;
                background-color: #e0b542;
                color: #000;
                padding: 10px;
                font-family: "SFCompactDisplay-Medium";
                font-size: 14px;
                font-weight: 600;
                cursor: pointer;
                margin-top: 3rem;
                border-radius: 10px;
                text-align: center;
                line-height: normal;
                @media only screen and (min-width: 1300px) {
                  font-size: 14px;
                  font-size: calc(14px + 0.2vw);
                }
                @media only screen and (min-width: 2500px) {
                  width: 49%;
                  max-width: 480px;
                  font-size: 14px;
                  font-size: calc(14px + 0.2vw);
                }
                &.active {
                  position: relative;
                  .triangle-image {
                    display: block;
                    position: absolute;
                    top: 97%;
                    left: 40%;
                    width: 40px;
                    border-top-left-radius: 5px;
                    border-top-right-radius: 5px;
                  }
                }
                &:not(.active) {
                  .triangle-image {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .service-vehicle-container {
    width: 100%;
    background-color: white;
    margin-top: 0rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #424242;

    @media only screen and (max-width: 576px) {
      width: 100%;
      justify-content: flex-start;
      align-items: flex-start;
    }
    .cruise-ship-desc {
      color: #142b47;
      text-align: center;
      margin-bottom: 2rem;
      font-size: 21px;
      font-family: "Rubik-Regular";
      @media only screen and (max-width: 576px) {
        font-size: 18px;
        margin-bottom: 0.2rem;
        text-align: left;
        //  width: 85%;
        padding: 0 2rem;
      }
    }
    .cruise-ship-names {
      //  width: 332px;
      display: flex;
      flex-direction: column;
      color: #3c91ad;
      align-items: center;
      margin: auto auto;
      font-size: 14px;
      line-height: 2.21;
      margin-bottom: 3.5rem;
      font-family: "Rubik-Regular";
      @media only screen and (max-width: 576px) {
        width: 100%;
        margin-bottom: 0.2rem;
        align-items: flex-start;
        padding-left: 2rem;
        margin: 1rem 0;
      }
    }
  }
  .popular-services {
    display: flex;
    flex-direction: column;

    align-items: center;
    margin: auto auto;
    margin-bottom: 4rem;
    font-family: "Rubik-Regular";
    font-size: 21px;
    text-align: center;
    color: #142b47;
    font-weight: normal;
    @media only screen and (max-width: 576px) {
      align-items: flex-start;
      padding-left: 2rem;
      margin-top: 1rem;
    }

    span {
      width: auto;
      text-align: center;
      color: #142b47;
      font-size: 21px;
      font-family: "Rubik-Regular";
    }
    .services-container {
      margin-top: 2rem;
      display: flex;
      flex-direction: row;
      width: 780px;
      height: 150px;

      @media only screen and (max-width: 576px) {
        height: auto;
        width: 100%;
        flex-direction: column;
        width: 90%;
        height: 150px;
      }
      .services {
        margin: auto auto;
        display: flex;
        flex-direction: column;
        width: 243px;
        height: 104;
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
        @media only screen and (max-width: 576px) {
          margin-bottom: 0.8rem;
          width: 100%;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          border-top-right-radius: 6px;
          border-top-left-radius: 6px;
        }
        .service-vehicle-desc {
          color: #fff;

          background-color: black;
          background: transparent;
          opacity: 0.85;
          background-color: #000;
          border-bottom-right-radius: 6px;
          border-bottom-left-radius: 6px;
          height: auto;
          padding: 0.5rem 0px;
          display: flex;
          flex-direction: column;
          font-family: "Rubik-Regular";

          p {
            font-size: 12px;
            width: 65%;
            margin: 0px;
            height: 100%;
            display: flex;
            justify-items: center;
            text-align: left;
            align-items: center;
            margin-left: 0.4rem;
            box-sizing: border-box;
            font-weight: 500;
            line-height: 1.33;
          }
          .sub-text {
            font-weight: normal;
          }
        }
      }
    }
  }
  .cruise-footer-section {
    margin-top: 2rem;
    width: 100%;
    @media only screen and (max-width: 576px) {
      margin-top: 0.2rem;
    }
    .book-about-section {
      width: 52%;
      margin: auto auto;
      display: flex;
      flex-direction: column;
      @media only screen and (max-width: 576px) {
        flex-direction: row;
        height: 350px;
        width: 60%;
      }
      .book-now {
        color: #424242;
        @media only screen and (max-width: 576px) {
          margin-right: 4rem;
        }

        p {
          color: #000;
          font-size: 14px;
          font-weight: 500;
          padding: 0;
          margin: 0;
        }
        span {
          font-size: 14px;
          font-weight: normal;
        }
        .footer-links {
          margin-top: 0.5rem;
          display: flex;
          flex-direction: column;
          a {
            font-size: 14px;
            padding-bottom: 0.4rem;
            color: #424242;
            @media only screen and (max-width: 576px) {
              width: 150px;
              padding: 0px;
            }
          }
        }
      }
    }
  }

  .margin-space {
    margin-bottom: 1rem;
  }
  .contact-request-quote-team-sm {
    display: flex;
    justify-content: space-between;
    width: 660px;

    @media only screen and (max-width: 576px) {
      display: block;
      width: 100%;
      margin-left: 35px;
      margin-top: 27rem;
      margin-bottom: 2rem;
    }

    button {
      width: 75%;
      border-radius: 6px;
      text-align: center;
      background-color: #e0b542;
      color: #000;
      padding: 0.5rem 0.7rem;
      margin-bottom: 2rem;
      line-height: 1.33;
      letter-spacing: normal;
      font-size: 12px;
      font-family: "Rubik-Medium";
      font-weight: 500;
      border: none;
      user-select: auto;

      a {
        color: #000;
      }

      &:hover {
        border: none;
      }
      &:active {
        border: none;
      }

      @media only screen and (max-width: 576px) {
        width: 81%;
      }
    }

    // a,
    // span {
    //   width: 306px;
    //   border-radius: 6px;
    //   background-color: #000;
    //   color: #fff;
    //   padding: 0.5rem 0.7rem;
    //   margin-bottom: 2rem;
    //   font-size: 12px;
    //   font-family: "Rubik-Medium";
    //   font-weight: 500;
    //   border: none;
    //   text-align: center;
    //   @media only screen and (max-width: 576px) {
    //     margin: auto auto;
    //     margin-top: 1rem;
    //   }
    //   &:hover {
    //     border: none;
    //   }
    //   &:active {
    //     border: none;
    //   }
    // }
  }
  .footer-section {
    @media only screen and (max-width: 576px) {
      margin-top: 2rem;
      //  margin-left: 3rem;
    }
  }
  .limos {
    width: 100% !important;
  }
  .jumbotron-header .jumbotron-content .hero-form {
    @media only screen and (max-width: 576px) {
      width: 100% !important;
      margin: auto auto !important;
    }
  }
  .contact-form-holder {
    margin-top: 4rem !important;
    margin-bottom: 0px;
  }
  .form-holder {
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 3rem;
  }
  #search-button {
    background-color: #e0b542;
    color: #000;
    border: 2px solid #e0b542;
  }
  .transport-image-lg {
    width: 400px;
    height: 250px;
    object-fit: contain;
    display: block;
    margin: auto auto;
    margin-top: -4rem;
    @media only screen and (max-width: 576px) {
      width: 300px !important;
      margin: auto;
      margin-top: -8rem;
    }

    img {
      align-items: center;
      display: flex;
      justify-content: center;
      width: 100%;
      height: 100%;
      /* width: 430px !important;
        height: 300px !important; */
      min-height: 300px;
      max-height: 250px;
      object-fit: contain;
      margin: auto auto;
      @media only screen and (max-width: 576px) {
        width: 100%;
        height: 100%;
        margin: auto;
        object-fit: contain;
      }
    }
  }
  .hero-form.new-search-form .form-title {
    @media only screen and (max-width: 576px) {
      display: block;
      font-size: 18px;
    }
  }
  .jumbotron-header .jumbotron-content .hero-form {
    background-image: linear-gradient(
      to bottom left,
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 0.6)
    );
  }
  .contact-form-container {
    margin-bottom: 0px !important;
  }

  .desk-only {
    @media (max-width: 1023px) {
      display: none !important;
    }
  }

  .mob-only {
    @media (min-width: 1024px) {
      display: none !important;
    }
  }

  .page-footer .container {
    width: 82%;

    @media only screen and (max-width: 576px) {
      width: 100%;
    }
  }
}
