@font-face {
  font-family: "Rubik-ExtraBold";
  src: url("https://assets.s3.amazonaws.com/images/front-end/new-landing/Font/Rubik-ExtraBold.ttf")
    format("truetype");
}

@font-face {
  font-family: "Rubik-Medium";
  src: url("https://assets.s3.amazonaws.com/images/front-end/new-landing/Font/Rubik-Medium.ttf")
    format("truetype");
}

@font-face {
  font-family: "Rubik-Black";
  src: url("https://assets.s3.amazonaws.com/images/front-end/new-landing/Font/Rubik-Black.ttf")
    format("truetype");
}

@font-face {
  font-family: "Rubik-Regular";
  src: url("https://assets.s3.amazonaws.com/images/front-end/new-landing/Font/Rubik-Regular.ttf")
    format("truetype");
}

.operator-page {
  /* Slideshow container */
  .slideshow-container {
    position: relative;
    margin: auto;
    .new-home-menu {
      width: 100%;
      padding-left: 100px;
      padding-right: 100px;
      position: absolute;
      top: 0;
      @media screen and (max-width: 567px) {
        padding-left: 15px;
        padding-right: 15px;
      }
      .home-links {
        text-align: right;
        padding-top: 25px;
        @media screen and (max-width: 567px) {
          padding-top: 15px;
        }
        a {
          color: #fff;
          font-family: "Rubik-Regular";
        }
      }
    }
    .carousel-slide-content {
      overflow: hidden;
      img {
        min-height: 1740px;
        max-height: 1740px;
        object-fit: cover;

        @media screen and (max-width: 567px) {
          min-height: 400px;
          max-height: 400px;
        }
      }
    }
    .carousel-root {
      .carousel-slider button {
        @media screen and (max-width: 567px) {
          display: none;
        }
      }
    }

    .image-shadow {
      top: 0;
      left: 0;
      position: absolute;
      background-image: url("https://assets.s3.amazonaws.com/images/front-end/new-landing/Fade.png");
      width: 100%;
      height: 100%;
      z-index: 100000000;
      background-size: cover;
    }
  }

  .banner-text {
    position: absolute;
    top: 20%;
    text-align: center;
    width: 100%;

    p {
      color: #fff;
      width: 100%;
      float: left;
      margin-bottom: 06px;
      font-size: 1.2em;
    }
  }
}

/* On smaller screens, decrease text size */
@media only screen and (max-width: 300px) {
  .text {
    font-size: 11px;
  }
}

.specialize-section {
  @media only screen and (min-device-width: 1024px) {
    display: none;
  }

  @media only screen and (max-width: 1023px) {
    width: 100%;
    background-color: #f5f5f5;
    @media screen and (max-width: 567px) {
      margin: auto;
      margin-bottom: 40px;
    }

    .specialize-section-heading {
      text-align: center;
      padding-top: 20px;
      font-family: "Rubik-Regular";
    }

    .specialize-section-image-container {
      text-align: center;
      @media screen and (max-width: 567px) {
        display: none;
      }
    }

    .specialize-container {
      width: 80%;
      margin: auto;
      @media screen and (max-width: 567px) {
        width: 100%;
      }
    }

    .specialize-container ul {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      margin: auto;
      @media screen and (max-width: 567px) {
        margin-bottom: 0px;
        justify-content: center;
        padding-left: 5px;
        padding-right: 5px;
      }
    }

    .specialize-container ul li {
      list-style: none;
      color: #fff;
      width: 25%;
      text-align: center;
      font-size: 15px;
      padding: 20px;
      @media screen and (max-width: 567px) {
        width: 50%;
      }
    }

    .specialize-container ul li img {
      width: 65px;
      margin-bottom: 10px;
      @media screen and (max-width: 567px) {
        width: 130px;
      }
    }

    .specialize-container p {
      font-size: 14px;
      font-family: "Rubik-Regular";
      color: #000;
    }

    .request-a-quote-btn-container {
      transform: translate(0%, 50%);
      text-align: center;
      border-style: none;
      font-weight: bold;
      letter-spacing: 0.5px;
      font-family: "Rubik-Medium";

      .request-a-quote-btn {
        border-radius: 7px;
        background-color: #3c3c3c;
        border: none;
        font-weight: 700;
        letter-spacing: 0.5px;
        padding: 6px;
        font-family: "Rubik-Medium";
        color: #fff;
        box-shadow: 0 3px 4px 0 rgb(0 0 0 / 10%);
      }
    }
  }
}

.four-features {
  width: 100%;
  float: left;
  overflow-x: hidden;

  ul {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding-left: 0px;
    margin: 50px 0px;
    @media screen and (max-width: 567px) {
      margin-bottom: 0px;
      justify-content: center;
      padding-left: 5px;
      padding-right: 5px;
    }

    li {
      list-style: none;
      float: left;
      font-size: 12px;
      width: 20%;
      text-align: center;

      @media screen and (max-width: 567px) {
        min-height: 170px;
        width: 33.33%;
        display: inline-block;
      }

      img {
        width: 65px;
        margin-bottom: 10px;
      }
    }
  }

  p {
    font-size: 14px;
    font-family: "Rubik-Regular";
  }
}

.banner-text-container {
  position: absolute;
  width: 100%;
  height: 100%; 
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 100px;
  padding-right: 100px;

  @media only screen and (min-device-width: 668px) and (max-device-width: 1024px) {
    padding-left: 20px;
    padding-right: 20px;
    top: 85px;
  }

  p {
    margin-bottom: 0;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.35);
    color: #000;
    letter-spacing: 1.44px;
    font-family: "Rubik-Regular";
    font-size: 21px;
  }
  @media screen and (max-width: 667px) {
    display: block;
    position: relative;
    padding-left: 15px;
    padding-right: 15px;
    top: -230px;
  }
  .cta-buttons-wrapper {
    margin-top: 40px;
  }
}

.slider-text-container {
  // width: 60%;
  text-align: center;
  position: absolute;
  top: 13%;

  &.mobile {
    display: none;
  }

  @media only screen and (min-device-width: 668px) and (max-device-width: 1024px) {
    width: 100%;
  }

  @media screen and (max-width: 667px) {
    &.mobile {
      display: block;
      width: 100%;
      // padding-right: 5px;
      position: absolute;
      top: 1%;
      text-align: center;
      min-height: 367px; // banner height
      p {
        color: #fff;
      }
      .logo-text {
        display: none;
      }
    }
    &.desktop {
      display: none;
    }
  }

  .text-container p.title-text {
    font-size: 23px;
    font-family: "Rubik-Regular";
    text-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
    @media screen and (max-width: 567px) {
      font-size: 17px;
    }
  }

  .text-container p.subtitle-text {
    font-size: 23px;
    font-family: "Rubik-Regular";
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.35);
    @media screen and (max-width: 567px) {
      font-size: 17px;
    }
  }

  .text-container p.divider-title-text {
    @media screen and (max-width: 567px) {
      width: 26px;
      height: 1px;
      opacity: 0.3;
      border: solid 2px #fff;
      margin: 10px auto;
    }
  }

  .logo-text {
    font-size: 44px;
    font-family: "Rubik-Medium";
    margin-bottom: 10px !important;
    @media screen and (max-width: 567px) {
      font-size: 23px;
    }
  }

  .cta-buttons-wrapper {
    @media screen and (max-width: 567px) {
      //margin-top: 60px;
    }
  }
}

.down-slider-text-container {
  text-align: center;
  .text-container p.title-text {
    @media screen and (max-width: 567px) {
      font-size: 17px;
      font-family: "Rubik-Regular";
      text-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
    }
  }

  .text-container p.divider-title-text {
    @media screen and (max-width: 567px) {
      width: 26px;
      height: 1px;
      opacity: 0.3;
      border: solid 2px #fff;
      margin: 10px auto;
    }
  }

  .text-container p.subtitle-text {
    @media screen and (max-width: 567px) {
      font-size: 17px;
      font-family: "Rubik-Regular";
      text-shadow: 0 2px 4px rgba(0, 0, 0, 0.35);
    }
  }
}

@media (min-width: 568px) {
  .down-slider-text-container {
    display: none; /* Hides the div for screens larger than 567px */
  }
}

.footer-links-heading {
  font-family: "Rubik-Medium";
  color: #424242;
  font-weight: 700;
}

.banner-form-container {
  width: 28%;
  @media only screen and (min-device-width: 668px) {
    position: absolute;
    top: 44%;
  }
  @media screen and (max-width: 567px) {
    // margin-top: 26%;
  }

  @media only screen and (min-device-width: 668px) and (max-device-width: 1024px) {
    width: 50%;
  }
  .book-online-text {
    color: #000;
    display: none;
    text-align: center;
    font-size: 1.5em;
    @media screen and (max-width: 567px) {
      display: block;
    }
  }
  .field-set-wrapper {
    //border: 2px solid rgba(255, 255, 255, 0.30);
    //border-radius: 7px;

    // @media screen and (max-width: 567px) {
    //   border: 2px solid #000;
    // }

    legend {
      color: #ffffff;
      font-size: 20px;
      font-family: "Rubik-Medium";
      text-align: center;
      width: inherit;
      padding-left: 15px;
      padding-right: 15px;
      margin-bottom: 0;

      @media screen and (max-width: 567px) {
        padding-left: 15px;
        padding-right: 15px;
        margin-bottom: 20px;
        color: #000;
      }
    }
  }

  @media screen and (max-width: 667px) {
    width: 100%;
  }

  p.get-in-touch {
    color: #ffffff;
    font-size: 20px;
    font-family: "Rubik-Medium";
    margin-bottom: 10px;

    @media screen and (max-width: 567px) {
      padding-left: 15px;
      padding-right: 15px;
      color: #424242;
    }
  }

  .getin-touch-form {
    margin: 0 auto;
    background: transparent;
    padding: 15px;
    border-radius: 5px;
    font-family: "Rubik-Medium";

    @media screen and (max-width: 567px) {
      margin-top: 0;
      padding-top: 0;
    }

    .form-error-description {
      color: #ec6060;
      margin-bottom: 10px;
      font-size: 12px;
    }
    .form-input {
      outline: none;
    }

    .form-item {
      position: relative;
      margin-bottom: 8px;

      &.google-recaptcha {
        margin-top: 8px;
      }
    }

    .form-item input {
      display: block;
      width: 100%;
      height: 35px;
      background: #fff;
      border: solid 1px #ccc;
      transition: all 0.3s ease;
      padding: 0 15px;
      position: relative;
      z-index: 2;
      border-radius: 7px;
      font-size: 14px;
      color: #424242;
      font-family: "Rubik-Regular";
    }

    input[type="date"] {
      -webkit-appearance: none;
      -webkit-min-logical-width: calc(100% - 16px);
    }

    .form-item input::-webkit-input-placeholder {
      /* Edge */
      color: #424242;
      text-transform: uppercase;
      font-size: 11px;
      font-family: "Rubik-Medium";
    }

    .form-item input:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #424242;
      text-transform: uppercase;
      font-size: 14px;
      font-family: "Rubik-Medium";
    }

    .form-item input::placeholder {
      color: #424242;
      text-transform: uppercase;
      font-size: 14px;
      font-family: "Rubik-Medium";
    }

    // .form-item input:focus {
    //   border-color: blue
    // }

    .form-submit-button button {
      padding: 4.5px 65.6px 5.9px 65.9px;
      border-radius: 5.1px;
      background-color: #424242;
      width: 100%;
      outline: 0;
      border: 0;
      color: #fff;
      font-family: "Rubik-Medium";
    }

    .form-item label {
      display: none;
      position: absolute;
      cursor: text;
      z-index: 1;
      top: 12px;
      left: 5px;
      font-size: 10px;
      font-weight: bold;
      padding: 0 10px;
      color: #424242;
      transition: all 0.3s ease;
      text-transform: uppercase;
    }

    .form-item input:focus + label {
      top: 1px;
    }

    .form-item input:focus + label {
      color: #424242;
    }
  }
  .info-text {
    display: none;
  }
  .jumbotron-header {
    // padding: 67px 16px 64px 16px;
    @media screen and (max-width: 567px) {
      padding-top: 15px;
    }
    .jumbotron-content {
      .hero-form {
        .search-button {
          padding-top: 5px;
          padding-bottom: 5px;
          border: 2px solid #fff;
          &:hover {
            border: 2px solid #fff;
          }
        }
        .linkButton {
          color: #fff;
        }
      }
    }
  }
}

.page-footer {
  width: 100%;
  padding-top: 30px;
  background-color: #fff;

  .container {
    width: 70%;

    @media (max-width: 480px) {
      width: 100%;
    }
  }

  .footer-main-links {
    padding-left: 0;
    list-style-type: none;
    font-size: 15px;

    li {
      line-height: 27.5px;
      a {
        color: #424242;
        font-family: "Rubik-Regular";
      }
      .hidden-text {
        color: #fff;
        font-family: "Rubik-Regular";
      }
    }
  }
  .footer-columns {
    margin-bottom: 20px;
  }
}

// new header styles
.new-limios-navbar {
  .navbar {
    background: transparent;

    .navbar-collapse {
      .navbar-nav {
        a {
          color: #000;
        }
      }
    }
    &.navbarExpanded {
      background: #fff;
      .navbar-toggler {
        svg {
          color: #000;
        }
      }
    }
  }
}

// comments section
.review-main-content {
  width: 70%;
  margin: auto;
  clear: both;
  font-family: "Rubik-Regular";

  @media screen and (max-width: 567px) {
    width: 90%;
    margin: auto;
    clear: both;
  }

  .yotpo {
    direction: LTR;
    clear: both;
    text-align: left;
    vertical-align: baseline;
    font-family: "Rubik-Regular";
    font-style: inherit;
    padding: 0;
    margin: 0;
    letter-spacing: normal;
  }
  .yotpo-nav {
    background: transparent;
    font-family: "Rubik-Regular";
    border-color: rgba(0, 39, 59, 0.08);
    display: block;
    position: relative;
    box-sizing: border-box;

    ul {
      float: left;
      padding: 0;
      font-size: 15px;
      margin: 0px;
      list-style-type: none;
    }
  }

  .yotpo-popup-box-small {
    padding-top: 12px;
    margin: 0 10px;
  }
  .yotpo-regular-box {
    padding: 25px 0 22px;
    margin: 0 10px;
    border-top: 1px solid #e3e3e3;

    .yotpo-header {
      position: relative;
      color: #6a6c77;

      .yotpo-icon-profile {
        position: relative;
        width: 45px;
        height: 45px;
        margin-right: 8px;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        background: #83b6dd;

        .yotpo-user-letter {
          background: transparent !important;
          position: absolute;
          top: 8px;
          width: 100%;
          min-width: 100%;
          left: 1px;
          text-align: center;
          font-size: 20px;
          color: #fff;
        }

        .yotpo-icon-circle-checkmark {
          display: inline-block;
          color: #1cc286;
        }
      }

      .yotpo-header-actions {
        float: right;
      }

      .yotpo-user-name {
        color: #2f84ed;
        margin-right: 5px;
      }

      .yotpo-header-element {
        padding-top: 2px;
        display: inline-block;
        vertical-align: top;
      }

      .pull-left {
        text-align: left;
        float: left;
      }
    }

    .yotpo-main {
      display: block;
      margin-top: 10px;
      margin-right: 0px;
      margin-bottom: 15px;
      margin-left: 53px;
    }

    .content-title {
      color: #6b6d76;
      // font-weight: bold;
      font-family: "Rubik-Regular";
    }

    .yotpo-footer {
      position: relative;
      color: #6a6c77;
      margin-left: 53px;
    }
  }

  .yotpo
    .yotpo-review
    .yotpo-header
    .yotpo-icon-profile
    .yotpo-icon-circle-checkmark {
    position: absolute;
    left: 31px;
    top: 28px;
    background: #fff;
    border-radius: 50%;
  }

  .yotpo-icon {
    font-size: 17.5px;
    font-style: normal;
    font-weight: normal;
    font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    width: auto !important;
    background: none;
    height: auto;
    -webkit-font-smoothing: antialiased;
    list-style-type: none;
  }

  .yotpo-footer {
    display: flex;
    justify-content: space-between;

    .y-label {
      min-width: 0px;
      padding: 0px;
      margin-left: 5px;
      text-transform: capitalize;
    }

    .vote-btn {
      margin-left: 10px;
    }

    .yotpo-icon-btn-small {
      display: inline-block;
    }
  }

  .yotpo-review-stars {
    display: inline-block;
    margin-top: 7px;
    margin-right: 10px;
  }

  .star-icon {
    color: #e7721b;
  }

  .yotpo-nav-wrapper {
    border-bottom: 3px solid;
    border-color: #2f84ed;
    overflow: visible;
    max-width: none;
    padding-bottom: 6px;
    font-size: 18px;
    font-family: "Rubik-Regular";
  }

  .yotpo-nav-tab {
    margin-bottom: 15px;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

// button container scss
.opeartor-banner-btn-container {
  .cta-buttons-wrapper {
    display: flex;
    justify-content: center;

    @media screen and (max-width: 567px) {
      margin: 10px auto;
      text-align: center;
      display: unset;
    }
  }

  .banner-call-to-action-buttons {
    padding: 15px;
  }

  .banner-call-to-action-buttons a {
    border-style: none;
    font-size: 1.2em;
    font-weight: 500;
    color: #fff !important;
    border-radius: 6px;
    letter-spacing: 0.5px;
    font-family: "Rubik-Medium";
    text-align: center;
    background-color: #595959;
    padding: 10px;
    cursor: pointer;
  }
}

@media (min-width: 992px) {
  .new-banner-btn-container.mobile {
    display: none;
  }
}

.operator-banner-text-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Rubik-Regular";

  .slider-text-container {
    position: absolute;
    top: 0;
    width: 30%;
    margin: 0 auto;
    text-align: left;

    @media screen and (max-width: 567px) {
      width: 100%;
      margin: 0 auto;
      text-align: center;
    }

    .gcLogo {
      width: 300px;
      height: 85px;
      margin: auto;
    }

    .slider-text {
      text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    }

    p {
      text-shadow: 0 2px 4px rgb(0 0 0 / 35%);
      color: #ffffff;
      letter-spacing: 1.44px;
      font-family: "Rubik-Regular";
      font-size: 21px;
    }
  }
}

// form

.operator-banner-form-container {
  position: absolute;
  top: 450px;
  width: 100%;

  @media screen and (max-width: 567px) {
    position: unset;
    background-image: linear-gradient(
      to bottom left,
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 0.8)
    );
  }

  .existing-search-form {
    width: 400px;
    margin: 0 auto;

    .form-title {
      font-family: RubikRoman;
      font-size: 19px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.37;
      letter-spacing: normal;
      text-align: center;
      color: #fff;

      @media screen and (max-width: 567px) {
        padding: 10px 0;
      }
    }
  }

  .horizontal-line {
    height: 2px;
    opacity: 0.3;
    border: solid 0.5px #fff;
    width: 30%;
    margin: 50px auto;
  }
}

// question answer section
.question-answer-container {
  clear: both;
  width: 70%;
  margin: 15px auto;

  @media screen and (max-width: 567px) {
    width: 90%;
  }

  & .question-answer-title {
    margin: 50px 0;
    h2 {
      font-family: "Rubik-Regular";
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.29;
      letter-spacing: normal;
      text-align: center;
      color: #424242;

      @media screen and (max-width: 567px) {
        font-size: 26px;
      }
    }
  }

  & .question-answer-description-container {
    width: 60%;
    margin: auto;

    @media screen and (max-width: 567px) {
      width: 90%;
    }

    & .ques-ans-section {
      margin-bottom: 25px;
    }
    & .question-section {
      font-family: "Rubik-Regular";
      font-size: 21px;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.73;
      letter-spacing: normal;
      color: #424242;
      font-weight: 500;
    }
    & .answer-section {
      font-weight: normal;
      font-family: "Rubik-Regular";
      font-size: 15px;
    }
  }
}
