@import "src/scss/globals";
@import "src/scss/brands";


.modal-content {
  & .modal-header {
    color: #fff;

    & .close {
      color: #fff;
      opacity: 77;
    }
  }
}
.termsAssuranceTitle {
  font-weight: bold;
}

.extras-title a {
  text-decoration: underline;
}

.bookingTermsContainer {
  color: white;

  p,
  li {
    font-size: 0.9rem;
    line-height: 1.1rem;
  }

  p {
    margin-bottom: 0.4rem;
  }

  ul {
    list-style-type: none;
    padding-left: 1rem;
  }

  ul li:before {
    content: "–";
    position: absolute;
    margin-left: -1rem;
  }

  h4 {
    font-size: 1.2rem;
    margin-top: 0;
    margin-bottom: 0;
  }

  h5 {
    font-size: 0.95rem;
    margin-top: 0;
    margin-bottom: 0;
  }

  .conditionClickable {
    cursor: pointer;
  }

  .conditionHidden {
    display: none;
  }
}

@include brandify {
  .termsAndCheckoutButton {
    .buttonAsLink.btn,
    .buttonAsLink.btn:hover,
    .buttonAsLink.btn:focus,
    .buttonAsLink.btn:active,
    .buttonAsLink.btn-primary:not(:disabled):not(.disabled).active,
    .buttonAsLink.btn-primary:not(:disabled):not(.disabled):active {
      font-weight: inherit;
      padding: 0;
      margin: 0;
      text-decoration: none;
      font-size: inherit;
      text-align: inherit;
      vertical-align: inherit;
      border-radius: 0;
      border: 0;
      height: 24px;
      min-height: auto;
    }

    .buttonAsLink.btn:hover {
      color: black;
    }
  }

  .termsModal {
    color: $color-body-text;
  }

  .termsContainer {
    text-align: center;
    margin-top: 0.95rem;
    margin-bottom: 0rem;
    object-fit: contain;
  }

  .termsContainer img {
    width: 100%;
    border-radius: $border-radius-standard;
    //border: 0.5rem solid $color-off-white;
  }

  .terms-checkbox {
    margin-top: 1rem;
  }

  .conditionHighlight {
    background-color: $color-off-white;
    border: 1px solid $color-static;
    padding: 0.6rem;
    border-radius: 0.2rem;
    margin-top: 0.2rem;
    margin-bottom: 0.8rem;
  }
}
