.sitemap-container {
    width: 80%;
    margin: 0 auto;
    margin-top: 100px;
    p { 
        a {
            color: #fff;

            &:hover {
                color: gold;
            }
        }
    }
}