#events-page {
  .jumbotron-content {
    align-items: flex-start;
    padding-top: 100px;
  }
  .services {
    margin-top: 2rem;
  }

  .colorLayer {
    background: rgba(0, 0, 0, 0.69);
  }

  .form-control {
    margin: 24px auto;
  }

  .checkboxes {
    display: inline-block;

    label {
      margin: 0 16px 0 8px;
    }
  }

  .message-input {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    margin-top: 16px;

    textarea {
      width: 100%;
      padding: 16px;
    }
  }

  .DateRangePicker {
    width: 100%;

    .DateRangePickerInput {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .DateInput_input {
        font-size: 1.4rem;
        font-weight: 400;
        padding-left: 16px;
      }
    }
  }
}
