@import "src/scss/globals";
@import "src/scss/brands";

@include brandify {
  .searchResultDate {
    font-size: 1.4rem;
    font-weight: 700;
    padding-left: 1.4rem;
    color: white;
    letter-spacing: -0.025rem;
  }

  .miniAddress {
    font-style: italic;
    font-weight: 300;
    font-size: 1.3rem;
    color: black !important;
  }
  .miniPickupAddress {
    padding-left: 1rem;
  }
  .miniDropOffAddress {
    text-align: right;
  }
  .miniTripInfo {
    margin-top: 0rem;
  }
  .mapMarker {
    max-height: 2rem;
    max-width: 4rem;
    padding: 0.1rem;
    object-fit: scale-down;
  }
  .pickupMapMarker {
    margin-right: 0.3rem;
  }

  .dropoffMapMarker {
    margin-left: 0.3rem;
  }
}
