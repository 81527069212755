.content-section {
    max-width: 1024px;
    padding: 10px 20px;

    ul {
      padding-left: 50px;
    }

    @media (min-width: 1024px) {
      margin: 64px auto;
      padding: 0;
    }

    img {
      max-width: 100%;
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
}

