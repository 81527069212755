@import "src/scss/brands";
@import "src/scss/globals";

.hero-form {
  width: 100%;
}

.datepicker {
  width: 92%;
}

.rbt {
  margin-top: 0.8rem;
}

.rbt-input-main {
  padding-left: 25px;
}

.form-control {
  height: 3.25rem;
  border-radius: 0;
  font-size: $form-control-font-size;
  //   font-style: $form-control-font-style;
  font-weight: $form-control-font-weight;
  color: $elephant;
  padding-top: 0.45rem;
  border: 0 !important;
}

.hero-form .btn {
  height: 3.25rem;
  border: none;
  font-weight: 800;
  font-weight: bold;
  border-radius: 0 !important;
  /*margin: 5px;*/
}

.search-button {
  margin-top: 1rem;
}

.btn-group-toggle .btn {
  background-color: white;
  border-width: $border-width-standard;
  margin-bottom: 0.4rem;
}

.size-picker {
  margin-bottom: 1.5rem;
}

.size-picker.btn-group-toggle .btn {
  flex: 1;
  margin-bottom: 0;
}

.btn-group-toggle .active {
  color: white;
}

.size-picker>.btn:not(:last-child):not(.dropdown-toggle),
.btn-group>.btn-group:not(:last-child)>.btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.size-picker>.btn:not(:first-child):not(.dropdown-toggle),
.btn-group>.btn-group:not(:last-child)>.btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.vehicleSelection {
  margin: 1rem;
}

.grid {
  display: flex;
  flex-wrap: wrap;
}

.contactBoxOnSearchForm {
  margin-top: 16px;

  @media (min-width: 768px) {
    margin-top: 96px;
  }
}

.round-trip-label {
  display: flex;
  align-items: center;
}

.rbt-input-main.round-trip-input {
  width: 1.5rem;
  margin-right: 0.5rem;
}

.hr-dashed {
  border-top-style: dashed;
}

@include brandify {
  .error-label {
    color: white;
    text-shadow: 2px 2px 2px red;
  }

  .error {
    color: red;
  }

  .hero-form .btn.button-inverse {
    background-color: white;
    color: $color-interactive;
    border: 0.2rem solid $color-border;
  }

  ::placeholder {
    color: $color-placeholder;
  }

  .hero-form .btn-rounded {
    font-size: 1.5rem;
  }

  .vehicleCapacity {
    color: $color-static;
    line-height: 1rem;
    min-width: 5.3rem;

    @media only screen and (min-width: 576px) {
      min-width: 6.3rem;
    }

    @media only screen and (min-width: 1200px) {
      min-width: 7rem;
    }

    .vehicleSizeDescriptor {
      font-size: 0.75rem;
      display: inline-block;
      padding-bottom: 0.15rem;

      .vehicleSizeRangeDisplay {
        margin-top: 0rem;
      }
    }
  }

  @media only screen and (max-width: 350px) {
    .vehicleSizeRangeDisplay {
      font-size: 1rem;
    }
  }

  .dropdown-item {
    font-size: 1rem;
    color: $color-dynamic-content;
  }

  .dropdown-item.active,
  .dropdown-item:active {
    background-color: $color-dynamic-content;
    color: white;
  }

  .dropdown-menu {
    margin: 0rem;
    border: none;
    border-radius: 0;

    a {
      color: black;
    }
  }

  .size-picker.btn-group-toggle .btn {
    padding-top: 0.23rem;
  }

  .btn-group-toggle .btn {
    background-color: white;
    color: black;
    border: 1px solid $color-off-white;
  }

  .btn-primary:not(:disabled):not(.disabled).active,
  .btn-primary:not(:disabled):not(.disabled):active,
  .show>.btn-primary.dropdown-toggle {
    background-color: $color-interactive;
    border-color: $color-interactive;
    border: 0;
  }

  .form-label-limos {
    text-align: left !important;
    margin-top: 1.5rem;
    color: black;
    line-height: 1.2rem;

    svg {
      margin-right: 8px;
    }
  }

  .size-picker {
    display: flex;
    flex-wrap: wrap;
    border-radius: 1.625rem;
    overflow: hidden;
    border: $border-width-standard solid $color-interactive;
  }
}

.jumbotron-header {
  width: 100%;
  // padding: 128px 16px 64px 16px;
  position: relative;

  .slick-slider,
  .slick-list,
  .slick-track,
  .slick-slide {
    z-index: 0;
  }

  .colorLayer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background: rgba(0, 0, 0, 0.11);
  }

  .jumbotron-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 2;
    position: relative;
    max-width: 1200px;
    margin: 0 auto;

    .hero-form {
      padding: 16px;
      width: 400px;
      background-image: linear-gradient(to bottom left,
          rgba(0, 0, 0, 0.5),
          rgba(0, 0, 0, 0.2));
      box-shadow: 0 1px 1.5px 0 rgba(0, 0, 0, 0.12),
        0 1px 1px 0 rgba(0, 0, 0, 0.24);

      .search-button {
        background-color: #4062a2;
        color: white;
        border: 6px solid white;
        height: auto;
        padding-top: 16px;
        padding-bottom: 16px;
        // margin-top: 32px;

        // &:hover {
        //   border: 6px solid gold;
        // }
      }

      .form-label-limos {
        color: white;
      }

      .dropdown-menu {
        border-radius: 7px;
        box-shadow: 0 1px 1.5px 0 rgba(0, 0, 0, 0.12),
          0 1px 1px 0 rgba(0, 0, 0, 0.24);

        .dropdown-item {
          color: black;
        }
      }

      .contact-container {
        margin-top: 32px;

        h4 {
          color: white;
        }

        .button-container {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-direction: column;
          margin-top: 16px;

          .linkButton {
            background: none;
            border: 2px solid white;
            margin-top: 0;
            margin-bottom: 16px;
          }
        }

        @media (min-width: 768px) {
          .button-container {
            flex-direction: row;

            .linkButton {
              &:first-of-type {
                margin-bottom: 0;
                margin-right: 16px;
              }
            }
          }
        }
      }
    }

    .info-text {
      color: white;
      margin-bottom: 32px;

      .vehicle-image {
        margin-bottom: 15px;

        img {
          min-width: 100%;
          max-width: 100%;
        }
      }

      h1,
      h2,
      h3 {
        text-shadow: 2px 2px 2px #000;
      }

      h3 {
        line-height: 36px;
      }

      @media (min-width: 768px) {
        margin-left: 64px;
        margin-bottom: 0;

        h1 {
          font-size: 64px;
        }

        h2 {
          font-size: 38px;
        }

        h3 {
          font-size: 24px;
        }
      }
    }

    @media (min-width: 768px) {
      flex-direction: row-reverse;
      align-items: center;
      justify-content: flex-start;

      &>div {
        width: 100%;
      }
    }
  }

  .rbt {
    margin-top: -4px;
  }

  .rbt-input-main {
    padding-left: 16px;
    border-color: white !important;
  }
}

#about {
  h3 {
    text-align: center;
    margin: 96px auto 0 auto;
    max-width: 768px;

    @media (min-width: 768px) {
      font-size: 36px;
    }
  }

  @media (min-width: 768px) {
    margin-bottom: 96px;
  }
}

.full-screen-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}