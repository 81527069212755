// DO NOT EDIT THIS FILE
// THIS FILE GENERATED FROM brands-spec.json by src/process-json-specs.js
$color-interactive: null;
$color-static: null;
$color-dynamic-content: null;
$color-off-white: null;
$color-hover: null;
$color-form-field: null;
$color-disabled: null;
$color-body-text: null;
$color-price: null;
$color-border: null;
$color-shadow: null;
$color-red: null;
$color-green: null;
$color-blue: null;
$color-placeholder: null;
$font: null;
$font-size-base: null;
$vehicle-background: null;
$aScssValue: null;
$brands: (
'limos': (
'color-interactive': #4062A2,
'color-static': #0E304D,
'color-dynamic-content': #4062A2,
'color-off-white': #ECEAEA,
'color-hover': #ECEAEA,
'color-form-field': #0E304D,
'color-disabled': #CACED6,
'color-body-text': #4062A2,
'color-price': #DF5526,
'color-border': #4062A2,
'color-shadow': black,
'color-red': #CF0747,
'color-green': #1CBB53,
'color-blue': #0E304D,
'color-placeholder': #9EA2A8,
'font': (proxima-nova-extra-condensed, sans-serif),
'font-size-base': 1.3rem,
'vehicle-background': 'https://assets.s3.amazonaws.com/images/front-end/gradientScaled-v2.png',
'aScssValue': #DF5526,
),
'bidmyrides': (
'color-interactive': black,
'color-static': #0E304D,
'color-dynamic-content': black,
'color-off-white': #ECEAEA,
'color-hover': #ECEAEA,
'color-form-field': #0E304D,
'color-disabled': #CACED6,
'color-body-text': black,
'color-price': #DF5526,
'color-border': black,
'color-shadow': black,
'color-red': #CF0747,
'color-green': #1CBB53,
'color-blue': #0E304D,
'color-placeholder': #9EA2A8,
'font': (proxima-nova-extra-condensed, sans-serif),
'font-size-base': 1.3rem,
'vehicle-background': 'https://assets.s3.amazonaws.com/images/front-end/gradientScaled-v2.png',
'aScssValue': #DF5526,
),
) !default;
// Example usage:
// @include brandify {
//     .my-button {
//         color: $color-interactive;
//     }
// }
//
// which would output:
// .limos .my-button {
//     color: #DF5526;
// }
// .icars .my-button {
//     color: green;
// }
@mixin brandify($brands: $brands) {
    @each $brand, $value in $brands {
    // creates the selector like .limos { ... }
    .#{$brand} {

      // map the brand variable to a global variable
$color-interactive: map-get($value, 'color-interactive') !global;
$color-static: map-get($value, 'color-static') !global;
$color-dynamic-content: map-get($value, 'color-dynamic-content') !global;
$color-off-white: map-get($value, 'color-off-white') !global;
$color-hover: map-get($value, 'color-hover') !global;
$color-form-field: map-get($value, 'color-form-field') !global;
$color-disabled: map-get($value, 'color-disabled') !global;
$color-body-text: map-get($value, 'color-body-text') !global;
$color-price: map-get($value, 'color-price') !global;
$color-border: map-get($value, 'color-border') !global;
$color-shadow: map-get($value, 'color-shadow') !global;
$color-red: map-get($value, 'color-red') !global;
$color-green: map-get($value, 'color-green') !global;
$color-blue: map-get($value, 'color-blue') !global;
$color-placeholder: map-get($value, 'color-placeholder') !global;
$font: map-get($value, 'font') !global;
$font-size-base: map-get($value, 'font-size-base') !global;
$vehicle-background: map-get($value, 'vehicle-background') !global;
$aScssValue: map-get($value, 'aScssValue') !global;
@content;
$color-interactive: null !global;
$color-static: null !global;
$color-dynamic-content: null !global;
$color-off-white: null !global;
$color-hover: null !global;
$color-form-field: null !global;
$color-disabled: null !global;
$color-body-text: null !global;
$color-price: null !global;
$color-border: null !global;
$color-shadow: null !global;
$color-red: null !global;
$color-green: null !global;
$color-blue: null !global;
$color-placeholder: null !global;
$font: null !global;
$font-size-base: null !global;
$vehicle-background: null !global;
$aScssValue: null !global;
}
}
}
