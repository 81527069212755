// @import "src/scss/brands";
// @import "src/scss/globals";
@font-face {
  font-family: "SFCompactDisplay-Medium";
  src: url("https://assets.s3.amazonaws.com/images/front-end/new-landing/SFCompactDisplay-Medium.ttf") format("truetype");
}

@media screen and (max-width: 567px) { 
  .banner-form-container .jumbotron-header {
    padding: 15px 0 0 0 !important;
  }
}

.hero-form.new-search-form {
  backdrop-filter: blur(11px);
  border-radius: 7px;

  @media screen and (max-width: 567px) {
    padding-top: 15px;
    background-image: linear-gradient(to bottom left,
        rgba(0, 0, 0, 0.8),
        rgba(0, 0, 0, 0.8)) !important;
  }
 /*  .transport-image {
    width: 212px;
    height: 61px;
    object-fit: contain;
    display: block;
  
    img {
      width:100%;
      height: 100%;
      align-items: center;
      display: flex;
      justify-content: center;  
    }
     
  } */
  .mt-3 {
  //  margin-top: 3rem;

  }
  .form-title {
    color: #FFF;
    font-family: "SFCompactDisplay-Medium";

    @media screen and (max-width: 567px) {
      display: none;
    }
  }

  form {
    .form-control:focus {
      border-color: #000;
      box-shadow: none;
    }

    .service-type-dropdown-container {
      position: relative;
      // height: 65px;
    }
    
    .service-type-dropdown-container select {
      -webkit-appearance: none;
      -moz-appearance: none;
      text-indent: 1px;
      font-family: "SFCompactDisplay-Medium";
      background-color: transparent;
      color: #FFF;
      font-size: 15px;
      padding-top: 0;
      padding-bottom: 0;
      position: absolute;
      height: 100%;
      top: 3px;
      left: 0;
      padding-top: 18px;
      padding-left: 15px;

      option,
      optgroup {
        color: #000;
      }

      option[value=""] {
        color: rgba(255, 255, 255, 0.6);
      }

    }

    .service-type-dropdown-container .down-arrow-container {
      display: inline-block;
      color: #FFF;
      width: 48%;
      text-align: end;
      position: relative;
      top: 16px;
      @media screen and (max-width: 567px) { 
        top: 10px;
      }
      .svg-down-icon {
        display: inline-block;
        fill: currentColor;
        line-height: 1;
        stroke: currentColor;
        stroke-width: 0;
      }
    }

    .service-type-dropdown-container select::-ms-expand {
      display: none;
    }

    .form-control,
    .form-field {
      input {
        // @media screen and (max-width: 567px) { 
        //   height: 20px;
        // }
        height: 30px;
        padding-top: 0;
        padding-bottom: 0;
        background-color: transparent;
        color: #fff;
        font-size: 15px;
        font-family: "SFCompactDisplay-Medium";
        padding-left: 15px;

        &::-webkit-input-placeholder {
          /* WebKit, Blink, Edge */
          color: rgba(255, 255, 255, 0.65);
          font-family: "SFCompactDisplay-Medium";
        }

        &:-moz-placeholder {
          /* Mozilla Firefox 4 to 18 */
          color: rgba(255, 255, 255, 0.65);
          opacity: 1;
          font-family: "SFCompactDisplay-Medium";
        }

        &::-moz-placeholder {
          /* Mozilla Firefox 19+ */
          color: rgba(255, 255, 255, 0.65);
          opacity: 1;
          font-family: "SFCompactDisplay-Medium";
        }

        &:-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: rgba(255, 255, 255, 0.65);
          font-family: "SFCompactDisplay-Medium";
        }

        &::-ms-input-placeholder {
          /* Microsoft Edge */
          color: rgba(255, 255, 255, 0.65);
          font-family: "SFCompactDisplay-Medium";
        }

        &::placeholder {
          /* Most modern browsers support this now. */
          color: rgba(255, 255, 255, 0.65);
          font-family: "SFCompactDisplay-Medium";
        }

        &:focus {
          border: 0;
          outline: 0;
          box-shadow: none;
        }
      }

      .form-checkboxes {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: 10px;

        label {
          color: #fff;
          margin-bottom: 0;
          margin-left: 5px;
          font-family: "SFCompactDisplay-Medium";
        }
      }

      .rbt-input-hint {
        color: rgba(255, 255, 255, 0.6) !important;
      }
    }

    .form-field.add-form-border {
      border: 2.2px solid rgba(255, 255, 255, 0.4) !important;
      border-radius: 7px;
      background-color: transparent;
      margin-top: 0;
      padding-top: 5px;
      padding-bottom: 5px;
      margin-bottom: 7px;
      height: 57px;

      .new-form-label {
        text-align: left;
        color: #fff;
        padding-left: 15px;
        font-size: 15px;

        label {
          margin-bottom: 0;
          text-transform: uppercase;
          font-family: "SFCompactDisplay-Medium";
        }
        .label-half-width-container {
          width: 50%;
        }
      }
    }

    .round-trip-checkbox-container {
      margin-bottom: 10px;
    }

    .trip-date-field {
      overflow: hidden;

      .trip-date-picker {
        width: 50%;
        float: left;
        border-right: 1px solid rgba(255, 255, 255, 0.4);
      }

      .trip-time-picker {
        width: 50%;
        float: right;

        .minute-type,
        .hour-type,
        .timepicker-dropdownDivider {
          display: inline;
          width: auto;
          font-size: 15px;
          color: #fff;
          background-color: transparent;
          height: auto;
          padding: 0 0 6px 0px;
          float: left;
          @media screen and (max-width: 567px) {  
            padding: 0;
            height: 20px;
          }

          option {
            color: #000;
          }
        }

        .hour-type {
          float: left;
        }

        .timepicker-dropdownDivider {
          float: left;
          padding: 0 5px 0 5px;
          @media screen and (max-width: 567px) {  
            padding: 0 5px 0 5px;
          }
        }

        .hour-time-container {
          padding-left: 15px;
          position: relative;
          top: -1px;

          select {
            font-family: "SFCompactDisplay-Medium";
            height: 30px;
          }
        }
      }
    }    

    button#search-button {    
      text-transform: inherit;
      border-radius: 7px !important;
      font-weight: normal;
      min-height: auto;     
      font-family: "SFCompactDisplay-Medium";
      letter-spacing: normal;         
    }

    .form-error-message {
      text-align: left;
      margin-bottom: 5px;
      line-height: 18px;

      .error-label {
        font-family: "SFCompactDisplay-Medium";
        color: #FF6181 !important;
        letter-spacing: 1px;
        text-shadow: none !important;
        font-size: 17px;
      }
    }

    .react-select-cruiseport-dropdown {

      .react-select__single-value,
      .react-select__input-container {
        color: #fff;
        font-size: 15px;
      }

      .react-select__placeholder {
        color: #fff;
        font-size: 15px;
        text-align: left;
        font-family: "SFCompactDisplay-Medium";
        opacity: 0.7;
      }

      .react-select__value-container {
        text-align: left;
        padding-left: 12px;
        padding-top: 7px;
        padding-bottom: 6px;
      }

      .react-select__indicator-separator {
        display: none;
      }

      .react-select__indicator {
        color: #fff;
      }

      .react-select__menu {
        .react-select__menu-list {
          .react-select__option {
            padding-left: 15px;
            text-align: left;
            font-family: "SFCompactDisplay-Medium";
          }
        }
      }

      .react-select__control {
        background-color: transparent;
        font-family: "SFCompactDisplay-Medium";
        font-size: 15px;
        padding-top: 0;
        padding-bottom: 0;
        border-color: rgba(255, 255, 255, 0.6);
        box-shadow: none;
        border: 2.2px solid rgba(255, 255, 255, 0.4) !important;
        border-radius: 7px;
        background-color: transparent;
        height: auto;

        option,
        optgroup {
          color: #000;
        }

        &::-ms-expand {
          display: none;
        }
      }

    }

    .passenger-duration-container {
      overflow: hidden;

      .passengers-count-field {
        width: 50%;
        float: right;
        
        input {
          padding-left: 15px;
          font-family: "SFCompactDisplay-Medium";
          background: transparent !important;
        }

        .passenger-input-field {
          margin-top: -4px;
        }
      }

      .passengers-count-field.fullWidth {
        width: 100%;
        float: right;
      }

      .duration-form-field {
        width: 50%;
        float: left;
        border-right: 1px solid rgba(255, 255, 255, 0.4);

        .input-duration-field {
          margin-top: -4px;
        }
      }
    }

    .react-datepicker__input-container {
      margin-top: -4px;
    }

    
  }
  .btn-white{
    background-color: white;
    color: #000;
    font-weight: normal;
    border: 2px solid #fff;

  }
  .search-results-button {
    background-color: #E0B542;
    color: #000;
    border: 2px solid #E0B542;
    background-size: 200% 100%;
    transition: all .99s ease-in-out;
  }
}