.landing-page {
  margin-top: 4rem;

  .divider {
    margin: 0 auto 16px auto;
    height: 2px;
    width: 100px;
    background: gold;
  }

  .landing-header {
    background: white;
    color: black;
    padding: 24px;

    .landing-inner {
      margin: 0 auto;
      padding: 64px 0;
      max-width: 1024px;
      text-align: center;

      img {
        margin: 32px auto;
      }
    }
  }

  .landing-section {
    max-width: 1024px;
    margin: 64px auto;

    & > * {
      padding: 24px;
    }

    @media (min-width: 1024px) {
      margin: 124px auto;
    }

    &.flex,
    .flex {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;

      @media (min-width: 1024px) {
        flex-direction: row;

        & > * {
          padding: 24px;
          width: 50%;
        }

        &.reverse {
          flex-direction: row-reverse;
        }
      }
    }
  }

  img {
    max-width: 100%;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}
