#request-quote-parent { 
  width: 500px;
  @media screen and (max-width: 567px) {
    width: 300px;
   }
  #request-quote-form {
    width: 450px;
    .full-width {
      width: 100% !important;
    }
    .custom-margin {
      margin-bottom:7px !important;
    }
    .custom-height {
      height: 21px !important;
    }
    #captcha-error {
      text-align: center;
    }
    .custom-btn-margin {
      margin-bottom: 1rem !important;
    }
   
    @media screen and (max-width: 567px) {
      width: 100%;
     }
    .new-form-label {
      font-size: 13px;
    }
    .service-type-dropdown-container{
      select {
        font-size: 14px;
      }
    }
    .error-label {
      font-size: 15px;
    }
    .field-flex-container {
      display: flex !important;
      flex-direction: row !important;
      justify-content: space-between !important;
      @media screen and (max-width: 567px) { 
        display: flex !important;
        flex-direction: column !important;
      }
      .field-flex-column {
        display: flex;
        flex-direction: column;
        width: 49%;   
        @media screen and (max-width: 567px) { 
          width: 100%; 
        } 
      }
     
    }
    #notes {
      margin-bottom: 10px;
      .data-hj-whitelist {
        height: 21px;
      }

    }
     
  }

}