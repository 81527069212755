@import "./variables.scss";

@mixin margin-auto($height,$width,$border) {
  margin:auto auto;
  width:$width;
  height: $height;
  border: $border;
}

@mixin align-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin display-column {
  display: flex;
  flex-direction: column;
}

@mixin display-row {
  display: flex;
  flex-direction: row;
}
@mixin row-space-between {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
}


@mixin font-normal($fontSize,$fontColor,$color,$align,$fontWeight) {
  font-size:$fontSize;
  line-height: 1.55;
  color: $color;
  font-weight: $fontWeight;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  font-family: $rubikRegularFont;
  text-align: $align;  
}
