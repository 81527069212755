@import "src/scss/brands";

@include brandify {
  .allTimesLocal {
    text-align: center;
    color: white;
    font-weight: 500;
    font-style: italic;
    margin-top: 1rem;
  }
}
