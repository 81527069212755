$cornflower-blue: #4062A2;
$elephant: #0E304D;
$flame-pea: #DF5526;
$slow-gray: #464E63;
$san-marino: #4062A2;
$gallery: #ECEAEA;
$polo-blue: #91A7D0;
$athens-gray: #DEE2E9;
$cadet-blue: #CACED6;
$sushi: #4062A2;
$razzmatazz: #CF0747;
$cold-emerald: #1CBB53;
$gray-chateau: #9EA2A8;
$form-control-font-size: 1.4rem;
$form-control-font-weight: 400;
$form-control-font-style: italic;
