.contact-form-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;

  .signup-form {
    font-family: "Rubik-Regular";
    text-align: center;
  }
  .container-spinner-success {
    position: absolute;
    width: 100%;
    display: flex;
    align-items: center;
    top: 30%;
    height: 100px;
    z-index: -1;
    .post-success {
      font-size: 18px;
      text-align: center;
      font-family: "Rubik-Regular";
      width: 100%;
      opacity: 1;
      transition: opacity 3s cubic-bezier(0.19, 1, 0.22, 1);
    }
    .fade-out {
      opacity: 0;
    }
    .loader {
      margin: auto auto;
    }
  }
  .contact-form {
    display: flex;
    position: relative;
    flex-direction: row;
    width: 45%;
    min-width: 500px;
    max-width: 570px;
    align-items: baseline;
    font-family: "Rubik-Regular";

    justify-content: space-between;
    margin: auto auto;
    justify-content: center;

    @media (max-width: 768px) {
      width: 100%;
      flex-direction: column;
      margin: auto auto;
      align-items: center;
      min-width: unset;
    }

    .form-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 10px;
      width: 49%;
      flex-direction: column;
      font-family: "SFCompactDisplay-Medium";
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000;
      @media (max-width: 768px) {
        width: 100%;
      }

      label {
        flex-basis: 30%;
      }

      input {
        flex-grow: 1;
        width: 92%;
        max-width: 235px;
        border-radius: 7px;
        border: solid 1.3px #ccc;
        padding: 10px;
        color: #000;
        outline: none;
        transition: border-color 0.5s;
        &::placeholder {
          color: #000; /* Placeholder color */
        }
        /*  &:focus,
        &:hover {
        } */
      }

      .error {
        color: red;
      }
    }

    button {
      margin-top: 10px;
      background-color: #4062a2;
      border: none;
      color: white;
      text-transform: uppercase;
      letter-spacing: 0.1rem;
      border-radius: 6px;
      font-size: 14px;
      padding: 7px 10px;
      margin-left: 5px;
    }
  }
}
