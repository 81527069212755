@import "src/scss/brands";

.center {
  text-align: center;
}

.serverError .callAndChatContainer {
  margin-top: 0.3rem;
}

.serverError .callAndChatContainer .btn.linkButton {
  font-size: 1rem;
}

@include brandify {
  .vehicleBottom {
    width: 100%;
    position: fixed;
    bottom: 0px;
    background-image: url($vehicle-background);
    background-position: bottom;
    background-repeat: repeat-x;
    background-size: contain;
    z-index: 1;
  }

  .vehicleBottomHidden {
    visibility: hidden;
    position: inherit;
  }

  .vehicleSelection {
    display: block;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 0;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-bottom: 1.5rem;
  }

  @media only screen and (max-height: 540px) {
    .vehicleBottom {
      display: none;
    }
  }
}
/* .cancel-modal {
  .modal-content {
    border: 1px solid #fff;
    .modal-header {
      color:#fff;
    }

  form {
    color:#fff;
  }
  p{
    color:#fff;
  }
}
} */
.cancel-modal {
  .modal-content {
    border: 1px solid #000;
    background-color: #fff;
    .modal-header {
      color:#000;
    }

  form {
    color:#000;
  }
  p{
    color:#000;
  }
  .close {
    color:#000;

  }

  
}
}
