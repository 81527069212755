.operator-signup-form {
  .input-field {
    padding: 5px 0 5px 15px;
    border-radius: 6.9px;
    border: 2.3px solid hsla(0, 0%, 100%, 0.4);
    -webkit-backdrop-filter: blur(10.3px);
    backdrop-filter: blur(10.3px);
    background-color: rgba(0, 0, 0, 0.58);
    margin-bottom: 6px;
    height: 56px;

    label {
      display: block;
      font-size: 15px;
      color: #fff;
      font-family: "SFCompactDisplay-Medium";
      margin: 0;
      text-transform: uppercase;
    }

    input {
      outline: none;
      border: none;
      display: block;
      line-height: 1.2em;
      font-size: 16px;
      width: 100%;
      color: #fff;
      background-color: transparent;
      font-family: "SFCompactDisplay-Medium";
      padding: 0;
    }
  }

  .error-field {
    text-align: left;
    margin-bottom: 5px;
    line-height: 18px;

    p {
      margin-left: 5px;
      font-family: "SFCompactDisplay-Medium";
      color: #ff6181 !important;
      letter-spacing: 1px;
      text-shadow: none !important;
      font-size: 17px;
    }
  }

  textarea {
    outline: none;
    border: none;
    display: block;
    line-height: 1.2em;
    font-size: 17px;
    width: 100%;
    color: #fff;
    background-color: transparent;
    font-family: "SFCompactDisplay-Medium";
  }

  select {
    background-color: transparent;
    outline: none;
    border: none;
    line-height: 1.2em;
    font-size: 17px;
    width: 100%;
    color: #fff;
    font-family: "SFCompactDisplay-Medium";

    optgroup {
      color: #000;
    }
  }

  input[type="submit"] {
    width: 100%;
    background-color: #fff;
    color: #2a2a2a;
    border-radius: 7px;
    line-height: 1.72;
    letter-spacing: normal;
    text-align: center;
    margin-top: 10px;
    padding: 10px;
    font-size: 15px;
    font-weight: 600;
    min-height: auto;
    font-family: "SFCompactDisplay-Medium";
    border: 2px solid grey;
    cursor: pointer;
  }

  .input-radio-field {
    font-size: 18px;
    font-family: "SFCompactDisplay-Medium";
    color: white;
    height: 65px;

    label {
      display: inline;
    }

    input {
      display: inline;
      width: auto;
      margin-right: 5px;
    }
  }

  .google-recaptcha {
    margin: 15px auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
