@import "src/scss/brands";

.brandLogoHeaderImage {
  margin-top: 0.17rem;
  margin-left: 0.3rem;
}

.navbarExpanded {
  box-shadow: 0 0 0.2rem 0 #0000006b;
}

.shareButtonContainer {
  flex-grow: 1;
  text-align: right;
}

.linkButton.shareButton {
  display: inline-block;
}

@media only screen and (max-width: 550px) {
  .telephone-on-medium {
    display: none;
  }
}

@include brandify {
  .shareButton.btn {
    color: white;
    background-color: inherit;
    height: 2rem;
  }
}

.limos-navbar {
  .index-links {
    display: none;

    @media (min-width: 1024px) {
      display: flex;
      margin-left: auto;

      a {
        margin: 8px 12px;
        text-transform: uppercase;
        color: white;

        &:hover,
        &:hover:not(:disabled) {
          color: gold;
        }
      }
    }
    .menu-each-link-wrapper {
      position: relative;
      
      .vehicles-sub-menu {
        display: none;
        min-width: 400px;
        position: absolute;
        z-index: 199;
        transform: translate(-160px, 0px);
        background-color: #1b1b1b;
        margin-top: 13px;

        .arrow {
          width: 20px;
          height: 10px;
          border-bottom: solid 10px rgb(27,27,27);
          border-left: solid 10px transparent;
          border-right: solid 10px transparent;
          position: absolute;
          left: 50%;
          margin-left: -10px;
          top: -10px;
        }
  
        .fleet-menu-each-sub-item {
          overflow: hidden;
          display: block;
    
          .luxury-cars {
            width: 100%;
            float: left;
            margin: 10px 0px;
            padding: 10px 15px;
            transition: all 0.5s;
            border-radius: 7px;
            margin: 0;
    
            &:hover {
              background-color: rgba(255,255,255, 0.1);
            }
            .carImg {
              width: 30%;
              float: left;

              .image-responsive {
                min-width: 100%;
                max-width: 100%;
              }
            }
    
            .carTitle {
              width: 70%;
              float: left;
              padding-left: 30px;
    
              p {
                color: #FFF;
              }
              span {
                font-size: 18px;
                color: #FFF;
              }
            }
          }
        }
      }
    }
  }
  .navbar {
    background: rgba(0, 0, 0, 0.8);
    .navbar-toggler svg {
      color: white;
    }
  }

  .limos-logo-text {
    color: white;
    font-size: 2.5rem;
    text-transform: uppercase;
    line-height: 1;
    margin-bottom: 0;
  }

  .navbar-light {
    .nav-link {
      color: white;

      &:hover {
        background: white;
        padding-left: 16px;
      }
    }
  }
}

.contact-icon-header {
  display: none;
  @media only screen and (max-width: 550px) {
    display: block;
  }
}
