@import "src/scss/globals";
@import "src/scss/brands";

.content-container {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 20px;

  .content-item {
    width: 30%;

    span {
      font-family: "Rubik-Regular";
      color: #686868;
      line-height: 2.11;
      letter-spacing: normal;
      font-size: 14px;
    }
  }

  .content-value {
    width: 70%;

    span {
      font-family: "Rubik-Regular";
      color: #2a2a2a;
      line-height: 2.11;
      letter-spacing: normal;
      font-size: 14px;
    }
  }
}

.vehicle-info-container {
  background-color: #f5f5f5;
  display: flex;
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 6px;

  .vehicle-details-container {
    width: 70%;

    .vehicle-name {
      font-family: "Rubik-Regular";
      color: #2a2a2a;
      line-height: 1.7;
      letter-spacing: normal;
      font-size: 18px;
    }

    .passenger-details,
    .amenties-details {
      font-family: "Rubik-Regular";
      color: #2a2a2a;
      line-height: 1.5;
      font-size: 13px;
      width: 85%;
    }
  }

  .vehicle-image-container {
    width: 30%;

    .carousel {
      border-radius: 6px !important;
    }
  }
}

.extra-note-container {
  .note-text {
    color: #000;
    opacity: 0.4;
    font-family: "Rubik-Regular";
    font-size: 12px;
    margin: 10px 10px;
  }
}

.return-trip-heading {
  width: 100%;
  clear: both;
  padding: 50px 0px;
  h1 {
    text-align: center;
  }
}
.return-trip-results-wrapper {
  width: 100%;
}
