.carnival-banner {
  max-width: 100%;
  height: auto;

  @media only screen and (max-width: 576px) {
  }
}
.container-carnival {
  width: 100%;
  min-height: 900px;
  background-color: white;
  margin-top: 0rem;

  .carnival-hero-banner {
    width: 100%;
    height: 500px;
    background-color: white;

    .img-container {
      position: relative;
      .banner-container {
        height: 541px;
        overflow: hidden;

        .banner-lg {
          @media only screen and (max-width: 576px) {
            display: none;
          }
        }
        .banner-sm {
          display: none;
          @media only screen and (max-width: 576px) {
            display: block;
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }

        img {
          object-fit: cover;
          width: 100%;
        }

        @media only screen and (max-width: 576px) {
          height: 800px;
          width: 600px;

          img {
            //object-fit: contain;
            width: 100%;
          }
        }
        /*  img {
          width: 100%;
          height: 100%;
        } */
      }

      .sprites-bg {
        width: 40%;
        height: 40%;
        position: absolute;
        bottom: 4rem;
        left: 6%;

        @media only screen and (max-width: 576px) {
          bottom: 8rem;
          width: 100%;
          height: 20%;
          left:0;
        }
        .transport-image-lg {
          width: 212px;
          height: 61px;
          object-fit: contain;
          display: block;
          margin: auto auto;

          img {
            width: 100%;
            height: 100%;
            align-items: center;
            display: flex;
            justify-content: center;
          }
          @media only screen and (max-width: 576px) {
           display: none;
          }
        }
        .transport-image-sm {
          width: 284px;
          height: 81px;
          object-fit: contain;
          display: block;
          margin: auto auto;
          display: none;

          img {
            width: 100%;
            height: 100%;
            align-items: center;
            display: flex;
            justify-content: center;
            margin-top: 1rem;
          }
          @media only screen and (max-width: 576px) {
           display: block;
          }
        }
        .sprite-group {
          position: relative;
          .cruise-logo {
            width: auto;
            height: 35px;
            align-items: center;
            display: flex;
            text-align: center;
            justify-content: center;
            justify-items: center;
            margin-bottom: 1rem;
            @media only screen and (max-width: 576px) {
              margin-bottom: 0.4rem;
            }

            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
          .img-desc {
            width: 100%;
            margin: auto auto;
            height: auto;
            text-align: center;
           

            span {
              font-size: 16px;
              line-height: 1.5;
              color: #142b47;
              font-family: "Rubik-Regular";
              letter-spacing: normal;
              @media only screen and (max-width: 576px) {
                font-size: 16px;
                line-height: 0;
              }
            }
          }
          .banner-sprites {
            list-style-type: none;
            display: flex;
            padding: 0;
            justify-content: center;
            align-items: center;
            justify-content: center;
            margin-top: 1rem;
            @media only screen and (max-width: 576px) {
              background-color: #7cd3f0;
              padding: 1rem 0;
            }
            .li-flex {
              display: flex;
              flex-direction: column;
              width: 120px;
              align-items: center;
              .img-div {
                width: 4.5rem;
                height: auto;
                object-fit: contain;
                img {
                  width: 100%;
                  height: 100%;
                }
              }
            }
            .li-desc {
              color: #142b47;
              font-size: 11px;
              font-weight: 500;
              font-family: "Rubik-Medium";
              text-align: center;
              font-weight: bold;
            }
          }
        }
      }
      .search-form-carnival {
        position: absolute;
        top: 4rem;
        right: 7rem;
        width: auto;
        height: 200px;
        box-sizing: border-box;
        padding: 9px 14px 17px;
        @media only screen and (max-width: 576px) {
          width: 90%;
          margin: auto auto;
          top: 5rem;
          left: 5%;
        }
        form {
        }
        #index-page {
          .form-title {
            font-size: 22px !important;
            font-weight: 500;
          }
          .react-select__menu {
            .react-select__menu-list {
              .react-select__option {
                color: #000;
                font-size: 15px;
              }
            }
          }
        }

        #search-button {
          width: 100%;
          min-height: 34px;
          box-sizing: border-box;
          padding: 0;
          border-radius: 6.9px;
          background-color: #fff;
          font-size: 15px;
          font-weight: 800;
          font-family: "Rubik-Regular";
          font-weight: bold;
        }
      }
    }
  }
  .cruise-ships-container {
    width: 100%;
    background-color: white;
    margin-top: 7rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media only screen and (max-width: 576px) {
      width: 100%;
      margin-top: 23rem;
      justify-content: flex-start;
      align-items: flex-start;

    }
    .cruise-ship-desc {
      color: #142b47;
      text-align: center;
      margin-bottom: 2rem;
      font-size: 21px;
      font-family: "Rubik-Regular";
      @media only screen and (max-width: 576px) {
        font-size: 18px;
        margin-bottom: 0.2rem;
        text-align: left;
        width: 85%;
        padding: 0 2rem;    


      }
    }
    .cruise-ship-names {
      width: 332px;
      display: flex;
      flex-direction: column;
      color: #3c91ad;
      align-items: center;
      margin: auto auto;
      font-size: 14px;
      line-height: 2.21;
      margin-bottom: 3.5rem;
      font-family: "Rubik-Regular";
      @media only screen and (max-width: 576px) {
        width: 100%;
        margin-bottom: 0.2rem;
        align-items:flex-start;
        padding-left:2rem;
        margin: 1rem 0;

        
      }
    }
  }
  .popular-cruise-ports {
    display: flex;
    flex-direction: column;

    align-items: center;
    margin: auto auto;
    margin-bottom: 4rem;
    @media only screen and (max-width: 576px) { 
      align-items: flex-start;
      padding-left: 2rem;
      margin-top: 1rem;
    }


    span {
      width: auto;
      text-align: center;
      color: #142b47;
      font-size: 21px;
      font-family: "Rubik-Regular";
    }
    .cruise-ports-container {
      margin-top: 2rem;
      display: flex;
      flex-direction: row;
      width: 780px;
      height: 150px;

      @media only screen and (max-width: 576px) {
        height: auto;
        width: 100%;
        flex-direction: column;
        width: 90%;
        height: 150px;
      }
      .cruise-ports {
        margin: auto auto;
        display: flex;
        flex-direction: column;
        width: 243px;
        height: 104;
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
        @media only screen and (max-width: 576px) {
          margin-bottom: 0.8rem;
          width:100%;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          border-top-right-radius: 6px;
          border-top-left-radius: 6px;
        }
        .cruise-port-desc {
          color: #fff;

          background-color: black;
          background: transparent;
          opacity: 0.85;
          background-color: #000;
          border-bottom-right-radius: 6px;
          border-bottom-left-radius: 6px;
          height: auto;
          padding: 0.5rem 0px;
          display: flex;
          flex-direction: column;
          font-family: "Rubik-Regular";

          p {
            font-size: 12px;
            width: 45%;
            margin: 0px;
            height: 100%;
            display: flex;
            justify-items: center;
            text-align: left;
            align-items: center;
            margin-left: 0.4rem;
            box-sizing: border-box;
            font-weight: 500;
            line-height: 1.33;
          }
          .sub-text {
            font-weight: normal;
          }
        }
      }
    }
  }
  .cruise-footer-section {
    margin-top: 2rem;
    width: 100%;
    @media only screen and (max-width: 576px) {
      margin-top: 0.2rem;
    }
    .book-about-section {
      width: 52%;
      margin: auto auto;
      display: flex;
      flex-direction: column;
      @media only screen and (max-width: 576px) {
        flex-direction: row;
        height: 350px;
        width: 60%;
      }
      .book-now {
        color: #424242;
        @media only screen and (max-width: 576px) {
          margin-right: 4rem;
        }

        p {
          color: #000;
          font-size: 14px;
          font-weight: 500;
          padding: 0;
          margin: 0;
        }
        span {
          font-size: 14px;
          font-weight: normal;
        }
        .footer-links {
          margin-top: 0.5rem;
          display: flex;
          flex-direction: column;
          a {
            font-size: 14px;
            padding-bottom: 0.4rem;
            color: #424242;
            @media only screen and (max-width: 576px) {
              width: 150px;
              padding: 0px;
            }
          }
        }
      }
    }
  }

  .margin-space {
    margin-bottom: 1rem;
  }
}
.contact-carnival-team-sm {
  width: 306px;
  margin: auto auto;
 /*  display: flex;
  align-items: center;
  justify-content: center; */
  @media only screen and (max-width: 576px) {
    display: block;
    margin-left: 2rem;
    width:100%;
     margin-top: 31rem;
 
  }

  button {
    width: 75%;
    border-radius: 6px;
    background-color: #000;
    color: #fff;
    padding: 0.5rem 0.7rem;
    margin-bottom: 2rem;
    font-size: 12px;
    font-family: "Rubik-Medium";
    font-weight: 500;
    border: none;
    &:hover {
      border: none;
    }
    &:active {
      border: none;
    }
  }
}
.footer-section {
  @media only screen and (max-width: 576px) { 
    margin-top: 2rem;
    margin-left: 3rem;
  }
}
