@import "src/scss/globals";
@import "src/scss/brands";

/*New home page styles*/

* {
  box-sizing: border-box;
}

.limos .new-home-page {
  font-family: proxima-nova-extra-condensed, sans-serif;

  p {
    margin-bottom: 0;
  }
}

img {
  vertical-align: middle;
}

.horizontal-center {
  display: flex;
  justify-content: center;
}

/* Slideshow container */
.slideshow-container {
  position: relative;
  margin: auto;
  .new-home-menu {
    width: 100%;
    padding-left: 100px;
    padding-right: 100px;
    position: absolute;
    top: 0;
    @media screen and (max-width: 567px) {
      padding-left: 15px;
      padding-right: 15px;
    }
    .home-links {
      text-align: right;
      padding-top: 25px;
      @media screen and (max-width: 567px) {
        padding-top: 15px;
      }
      a {
        color: #fff;
        font-family: "Rubik-Regular";
      }
    }
  }
  .carousel-slide-content {
    overflow: hidden;
    img {
      min-height: 900px;
      max-height: 900px;
      object-fit: cover;

      @media screen and (max-width: 567px) {
        min-height: 400px;
        max-height: 400px;
      }
    }
  }
  .carousel-root {
    .carousel-slider button {
      @media screen and (max-width: 567px) {
        display: none;
      }
    }
  }

  .image-shadow {
    top: 0;
    left: 0;
    position: absolute;
    background-image: url("https://assets.s3.amazonaws.com/images/front-end/new-landing/Fade.png");
    width: 100%;
    height: 100%;
    z-index: 100000000;
    background-size: cover;
  }
}
.image-responsive {
  min-width: 100%;
  max-width: 100%;
}
.mySlides {
  position: relative;
  overflow: hidden;

  .banner-slider-image {
    @media screen and (max-width: 567px) {
      min-height: 340px;
      width: auto;
      object-fit: cover;
    }
  }
}

/* Caption text */
.text {
  color: #f2f2f2;
  font-size: 26px;
  width: 100%;
  font-weight: 600;
}

/* Number text (1/3 etc) */
.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}
.fade:not(.show) {
  opacity: 0.5 !important;
}
/* The dots/bullets/indicators */
.dot {
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.active {
  background-color: #717171;
}

/* Fading animation */
.fade {
  animation-name: fade;
  animation-duration: 1.5s;
}
.banner-text {
  position: absolute;
  top: 20%;
  text-align: center;
  width: 100%;
}
.banner-text p {
  color: #fff;
  width: 100%;
  float: left;
  margin-bottom: 06px;
  font-size: 1.2em;
}

@keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

/* On smaller screens, decrease text size */
@media only screen and (max-width: 300px) {
  .text {
    font-size: 11px;
  }
}

.specialize-section {
  @media only screen and (min-device-width: 1024px) {
    display: none;
  }

  @media only screen and (max-width: 1023px) {
    width: 100%;
    background-color: #f5f5f5;
    @media screen and (max-width: 567px) {
      margin: auto;
      margin-bottom: 40px;
    }

    .specialize-section-heading {
      text-align: center;
      padding-top: 20px;
      font-family: "Rubik-Regular";
    }

    .specialize-section-image-container {
      text-align: center;
      @media screen and (max-width: 567px) {
        display: none;
      }
    }

    .specialize-container {
      width: 80%;
      margin: auto;
      @media screen and (max-width: 567px) {
        width: 100%;
      }
    }

    .specialize-container ul {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      margin: auto;
      @media screen and (max-width: 567px) {
        margin-bottom: 0px;
        justify-content: center;
        padding-left: 5px;
        padding-right: 5px;
      }
    }

    .specialize-container ul li {
      list-style: none;
      color: #fff;
      width: 25%;
      text-align: center;
      font-size: 15px;
      padding: 20px;
      @media screen and (max-width: 567px) {
        width: 50%;
      }
    }

    .specialize-container ul li img {
      width: 65px;
      margin-bottom: 10px;
      @media screen and (max-width: 567px) {
        width: 130px;
      }
    }

    .specialize-container p {
      font-size: 14px;
      font-family: "Rubik-Regular";
      color: #000;
    }

    .request-a-quote-btn-container {
      transform: translate(0%, 50%);
      text-align: center;
      border-style: none;
      font-weight: bold;
      letter-spacing: 0.5px;
      font-family: "Rubik-Medium";

      .request-a-quote-btn {
        border-radius: 7px;
        background-color: #3c3c3c;
        border: none;
        font-weight: 700;
        letter-spacing: 0.5px;
        padding: 6px;
        font-family: "Rubik-Medium";
        color: #fff;
        box-shadow: 0 3px 4px 0 rgb(0 0 0 / 10%);
      }
    }
  }
}

.specialize-section-banner-btn-container {
  .cta-buttons-wrapper {
    display: flex;

    .banner-call-to-action-buttons a,
    .banner-call-to-action-buttons button {
      border-style: none;
      background-color: #fff;
      font-size: 1.2em;
      font-weight: 700;
      color: #404040 !important;
      padding: 11px 10px;
      border-radius: 6px;
      letter-spacing: 0.5px;
      font-family: "Rubik-Medium";
      box-shadow: 0 3px 4px 0 rgb(0 0 0 / 10%);
    }
  }
}

.new-specialize-section {
  display: none;
  @media only screen and (min-device-width: 1024px) {
    // background-color: rgba(0, 0, 0, 0.8);
    background-color: #141414;
    display: flex;
    margin-bottom: 0px;

    // .specialize-container-heading {
    //   width: 20%;
    //   // padding: 30px 0 0 20px;
    //   margin: auto;
    //   h3 {
    //     line-height: 1.73;
    //     letter-spacing: normal;
    //     color: #fff;
    //     text-shadow: 0 2px 4px rgba(0, 0, 0, 0.35);
    //     font-family: "Rubik-Regular";
    //     font-size: 19px;
    //     padding-left: 40px;
    //   }
    // }

    .specialize-container-slider {
      width: 80%;
      margin:auto auto;
      padding-bottom: 40px;
      // animation

      logo-slider {
        $min-image-size: 40px;
        $max-image-size: 80px;

        --image-size: #{$max-image-size};

        padding: 15px;
        overflow: hidden;
        // max-width: 900px;
        width: 100%;
        margin: 0 auto;
        position: relative;
        display: flex;

        div {
          display: flex;
          position: relative;
          justify-content: space-evenly;
          width: 100%;
          // animation: marquee 25s linear infinite;

          span {
            margin: 0 10px;
          }
        }

        img {
          display: block;
          min-width: var(--image-size);
          height: var(--image-size);
          margin: 0 auto;
        }

        @media (max-width: 900px) {
          --image-size: #{$min-image-size};
          --image-size: min(max(#{$min-image-size}, 10vw), #{$max-image-size});
        }
      }

      @keyframes marquee {
        0% {
          transform: translateX(0%);
        }
        100% {
          transform: translateX(-100%);
        }
      }
    }

    p {
      text-align: center;
      font-size: 14px;
      font-family: "Rubik-Regular";
      color: #7d7d7d;
    }
  }
}

.feature-container {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  float: left;
}
.feature-container ul {
  width: 100%;
  display: flex;
  align-items: center;
  float: left;
  margin-bottom: 0px;
  padding-left: 0px;
}
.feature-container ul li:first-child {
  padding-top: 15px;
}
.feature-container ul li {
  list-style: none;
  float: left;
  color: #fff;
  width: 33%;
  text-align: center;
  font-size: 12px;
}
.feature-container ul li {
  margin-bottom: 0px;
  font-size: 15px;
}
.feature-container ul li label {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 0px;
}

.four-features {
  width: 100%;
  float: left;
  overflow-x: hidden;
}

.four-features ul li {
  list-style: none;
  float: left;
  font-size: 12px;
  width: 20%;
  text-align: center;

  @media screen and (max-width: 567px) {
    min-height: 170px;
    width: 33.33%;
    display: inline-block;
  }
}
.four-features ul li img {
  width: 65px;
  margin-bottom: 10px;
}
.four-features ul {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding-left: 0px;
  margin: 50px 0px;
  @media screen and (max-width: 567px) {
    margin-bottom: 0px;
    justify-content: center;
    padding-left: 5px;
    padding-right: 5px;
  }
}
.four-features p {
  font-size: 14px;
  font-family: "Rubik-Regular";
}
.testimonial ul li {
  list-style: none;
  float: left;
}
.testimonial {
  margin-bottom: 30px;
  background-color: #f5f5f5;
  padding: 60px 15px;
  @media screen and (max-width: 567px) {
    padding-top: 20px;
    padding-bottom: 30;
  }
}

.main .container .swiper-container .swiper-wrapper .swiper-slide .card-image {
  border: none;
  outline: none;
  border-radius: 0px;
}
.main .container .swiper-container .swiper-wrapper .swiper-slide .card-image img {
  display: block;
  position: relative;
  left: 0px;
  bottom: 0;
  width: 100%;
  height: auto;
  -o-object-fit: cover;
  object-fit: cover;
}
.main .container .swiper-container .swiper-pagination-bullet {
  opacity: 0.8;
  background: #252a32;
}
.main .container .swiper-container .swiper-pagination-bullet-active {
  background: #ffffff;
}
.main .container .swiper-container .swiper-button-next,
.main .container .swiper-container .swiper-button-prev {
  background-image: none;
  background-size: 0;
  background-repeat: no-repeat;
  background-position: 0;
  margin-top: -1rem;
}
.main .container .swiper-container .swiper-button-next .arrow-icon,
.main .container .swiper-container .swiper-button-prev .arrow-icon {
  font-size: 1rem;
  color: #ffffff !important;
}
.swiper-button-next,
.swiper-button-prev {
  color: #fff !important;
  width: 20px;
}
main {
  position: relative;
}
.swiper-button-prev,
.swiper-rtl .swiper-button-next {
  left: 340px !important;
}
.swiper-button-next,
.swiper-rtl .swiper-button-prev {
  right: 340px !important;
}
.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after,
.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  font-size: 30px !important;
}
// .swiper-pagination{
//     display: none;
// }
.swiper-container .card-image img {
  border-radius: 10px;
  height: 500px !important;
}
.supportstaff {
  float: right;
  margin-top: 100px;
}
.supportstaff img {
  width: 170px;
}

.call-btn {
  position: relative;
  margin-top: 20px;

  .premiumvehicles & {
    margin-top: 30px;
  }

  button,
  a {
    position: relative;
    color: #424242;
  }
}

.cta-buttons-wrapper {
  .call-btn {
    @media screen and (max-width: 567px) {
      text-align: center;
    }
    .book-online-now {
      margin-left: 30px;

      @media screen and (max-width: 567px) {
        display: inline-block;
        margin-left: unset;
      }
    }
    .banner-call-to-action-buttons {
      margin-bottom: 20px;
    }
  }
  /* .banner-call-to-action-buttons {
    .banner-call-for-discount {
      background-color: #fff !important;
      color:#000 !important;
    }
  } */
}

.call-btn button,
.call-btn a {
  border-style: none;
  background-color: #fff;
  font-size: 1.2em;
  font-weight: bold;
  color: #404040 !important;
  padding: 11px 10px;
  border-radius: 6px;
  letter-spacing: 0.5px;
  font-family: "Rubik-Medium";
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.1);
  @media screen and (max-width: 567px) {
    padding: 9px 10px;
    font-size: 0.8em;
  }
}
.call-btn button {
  @media screen and (max-width: 567px) {
    padding: 7px 10px;
  }
}

.call-btn i {
  font-size: 16px;
}
.call-btn .additional-offer {
  position: absolute;
  right: -15px;
  background-color: #e8b317;
  color: #fff;
  font-weight: 600;
  border-radius: 7px;
  padding: 02px 10px;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  top: -12px;
  @media screen and (max-width: 567px) {
    font-size: 11px;
  }
}
.discount-text {
  padding-left: 5px;
}
.book-online-now {
  padding-left: 5px;
}
.prev-img img {
  width: 100%;
  float: left;
}
.slider-images {
  padding: 0px;
  width: 100%;
  float: left;
}
.slider-images img {
  height: 400px;
  object-fit: cover;
}
.slider-images .col-lg-2:first-child {
  float: left;
  padding-left: 0px;
}
.slider-images .col-lg-2:last-child {
  padding-right: 0px;
  float: left;
}
.slider-images .col-lg-8 {
  float: left;
}
.vote-image {
  text-align: center;
  position: relative;
}
.vote-image img:last-child {
  width: 50%;
  @media screen and (max-width: 567px) {
    width: 95%;
    display: none;
  }
}
.vote-image img:first-child {
  width: 50%;

  @media screen and (max-width: 567px) {
    width: 95%;
  }
}
// .vote-image img:last-child{
//     width: 50%;
//     @media screen and (max-width: 567px) {
//       width: 80%;
//     }
// }
// .vote-image img:first-child{
//     width: 50%;

//     @media screen and (max-width: 567px) {
//       width: 80%;
//     }
// }
.premiumtext {
  text-align: center;
  color: #585858;
  font-weight: 600;
  margin-top: 30px;
  letter-spacing: 0.5px;
  p {
    font-family: "Rubik-Regular";
  }
}

.banner-text-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: -60px;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 100px;
  padding-right: 100px;
  @media screen and (max-width: 567px) {
  .form-luxury-image {
    display: none;
    }
  }

  @media only screen and (min-device-width: 668px) and (max-device-width: 1024px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  p {
    margin-bottom: 0;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.35);
    color: #000;
    letter-spacing: 1.44px;
    font-family: "Rubik-Regular";
    font-size: 21px;
  }
  @media screen and (max-width: 667px) {
    display: block;
    position: relative;
    padding-left: 15px;
    padding-right: 15px;
  }
  .cta-buttons-wrapper {
    margin-top: 40px;
  }

  .messages {
    display: flex;
    flex-direction: column;
    margin-top: 0.5rem;
    font-family: "SFCompactDisplay-Medium";
  }

  .message {
    border-radius: 20px;
    padding: 8px 15px;
  }

  .promo {
    align-items: center;
  }

  .promo .message {
    color: white;
    background-attachment: fixed;
    position: relative;
    caret-color: white;
    background: #3B82F7;
    line-height: 18px;
    text-align: left;
  }
  .promo .message.last:after {
    content: "";
    position: absolute;
    z-index: 1;
    width: 14%;
    height: 100%;
    border-radius: 25%;
    bottom:3px;
    right: -48px;
    box-shadow: #3B82F7 0.5vmin 0, 2vmin -0.5vmin #3B82F7, 2vmin -0.5vmin 0 0.5vmin #3B82F7;
    clip-path: polygon(0% 49%, 150% 48%, 150% 100%, 0% 100%);
    transform: scaleX(-1);
    z-index: 1;
  }

  .type {
    color: #0000;
    background: linear-gradient(-90deg, #fff 3px, #0000 0) 3px 0, linear-gradient(#fff 0 0) 0 0;
    background-size: calc(var(--n) * 1ch) 200%;
    -webkit-background-clip: padding-box, text;
    background-clip: padding-box, text;
    background-repeat: no-repeat;
    animation: b 0.2s infinite steps(1), t calc(var(--n) * 0.1s) steps(var(--n)) infinite;
    font-size: 16px;
    text-shadow: none;
    font-family: "SFCompactDisplay-Medium";
  }
  .type span ::after {
    animation: cursor-blink 1.5s steps(2) infinite;
    color: #fff;
  }
  @keyframes cursor-blink {
    0% {
      opacity: 0;
    }
  }
  @keyframes t {
    from {
      background-size: 0 200%;
    }
  }
  @keyframes b {
    50% {
      background-position: 0 -100%, 0 0;
    }
  }

  .banner-form-container {
    width: 640px;

    @media screen and (max-width: 567px) {
      width: auto;
    }

    @media only screen and (min-width: 567px) {
      .video-container-mobile {
        display: none;
      }
    }

    @media only screen and (min-width: 0px) and (max-width: 567px) {
      .video-container-mobile {
        .video-container-mobile-title {
          font-family: "SFCompactDisplay-Medium";
          text-align: center;
          color: #fff;
          margin-bottom: 5px;
        }
      }
    }
  }

  .video-container {
    margin-left: 30px;

    @media screen and (max-width: 567px) {
      display: none;
    }
  }


}

.banner-btn-container {
  background-color: #fff;
  &.mobile {
    display: none;
  }

  @media screen and (max-width: 567px) {
    &.mobile {
      display: block;
      width: 100%;
      text-align: center;
      padding: 20px 0;
      background-color: rgba(0, 0, 0, 0.9);
    }
    &.desktop {
      display: none;
    }
  }
}
.slider-text-container {
  // width: 60%;
  text-align: center;
  position: absolute;
  top: 16%;

  &.mobile {
    display: none;
  }
  .messages {
    display: flex;
    flex-direction: column;
    margin-top: 0.5rem;
    font-family: "SFCompactDisplay-Medium";
    @media screen and (max-width: 667px) { 
      margin-top: 1.5rem;
    }
  }

  .message {
    border-radius: 20px;
    padding: 8px 15px;
  }

  .promo {
    align-items: center;
  }

  .promo .message {
    color: white;
    background-attachment: fixed;
    position: relative;
    caret-color: white;
    background: #3B82F7;
    line-height: 18px;
    text-align: left;
  }
  .promo .message.last:after {
    content: "";
    position: absolute;
    z-index: 1;
    // width: 14%;
    height: 100%;
    border-radius: 25%;
    bottom:3px;
    right: -48px;
    box-shadow: #3B82F7 0.5vmin 0, 2vmin -0.5vmin #3B82F7, 2vmin -0.5vmin 0 0.5vmin #3B82F7;
    clip-path: polygon(0% 49%, 150% 48%, 150% 100%, 0% 100%);
    transform: scaleX(-1);
    z-index: 1;
  }

  .type {
    color: #0000;
    background: linear-gradient(-90deg, #fff 3px, #0000 0) 3px 0, linear-gradient(#fff 0 0) 0 0;
    background-size: calc(var(--n) * 1ch) 200%;
    -webkit-background-clip: padding-box, text;
    background-clip: padding-box, text;
    background-repeat: no-repeat;
    animation: b 0.2s infinite steps(1), t calc(var(--n) * 0.1s) steps(var(--n)) infinite;
    font-size: 16px;
    text-shadow: none;
    font-family: "SFCompactDisplay-Medium";
  }
  .type span ::after {
    animation: cursor-blink 1.5s steps(2) infinite;
    color: #fff;
  }
  @keyframes cursor-blink {
    0% {
      opacity: 0;
    }
  }
  @keyframes t {
    from {
      background-size: 0 200%;
    }
  }
  @keyframes b {
    50% {
      background-position: 0 -100%, 0 0;
    }
  }

  @media only screen and (min-device-width: 668px) and (max-device-width: 1024px) {
    width: 100%;
  }

  @media screen and (max-width: 667px) {
    &.mobile {
      display: block;
      width: 100%;
      // padding-right: 5px;
      position: absolute;
      top: 5%;
      text-align: center;
      min-height: 367px; // banner height
      p {
        color: #fff;
      }
      .logo-text {
        display: none;
      }
    }
    &.desktop {
      display: none;
    }
  }

  .text-container p.title-text {
    font-size: 23px;
    font-family: "Rubik-Regular";
    text-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
    @media screen and (max-width: 567px) {
      font-size: 17px;
    }
  }

  .text-container p.subtitle-text {
    font-size: 23px;
    font-family: "Rubik-Regular";
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.35);
    @media screen and (max-width: 567px) {
      font-size: 17px;
    }
  }

  .text-container p.divider-title-text {
    @media screen and (max-width: 567px) {
      width: 26px;
      height: 1px;
      opacity: 0.3;
      border: solid 2px #fff;
      margin: 10px auto;
    }
  }

  .logo-text {
    font-size: 44px;
    font-family: "Rubik-Medium";
    margin-bottom: 10px !important;
    @media screen and (max-width: 567px) {
      font-size: 23px;
    }
  }

  .cta-buttons-wrapper {
    @media screen and (max-width: 567px) {
      //margin-top: 60px;
    }
  }
}

.footer-links-heading {
  font-family: "Rubik-Medium";
  color: #424242;
  font-weight: 700;
}
.banner-form-container {
  width: 28%;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-items: center;

  @media only screen and (min-device-width: 668px) {
    position: absolute;
    top: 60%;
  }
  // width: 36%;

  @media only screen and (min-device-width: 668px) and (max-device-width: 1024px) {
    width: 50%;
  }
  .book-online-text {
    color: #000;
    display: none;
    text-align: center;
    font-size: 1.5em;
    @media screen and (max-width: 567px) {
      display: block;
    }
  }
  .field-set-wrapper {
    //border: 2px solid rgba(255, 255, 255, 0.30);
    //border-radius: 7px;

    // @media screen and (max-width: 567px) {
    //   border: 2px solid #000;
    // }

    legend {
      color: #ffffff;
      font-size: 20px;
      font-family: "Rubik-Medium";
      text-align: center;
      width: inherit;
      padding-left: 15px;
      padding-right: 15px;
      margin-bottom: 0;

      @media screen and (max-width: 567px) {
        padding-left: 15px;
        padding-right: 15px;
        margin-bottom: 20px;
        color: #000;
      }
    }
  }

  @media screen and (max-width: 667px) {
    width: 100%;
  }

  p.get-in-touch {
    color: #ffffff;
    font-size: 20px;
    font-family: "Rubik-Medium";
    margin-bottom: 10px;

    @media screen and (max-width: 567px) {
      padding-left: 15px;
      padding-right: 15px;
      color: #424242;
    }
  }

  .getin-touch-form {
    margin: 0 auto;
    background: transparent;
    padding: 15px;
    border-radius: 5px;
    font-family: "Rubik-Medium";

    @media screen and (max-width: 567px) {
      margin-top: 0;
      padding-top: 0;
    }

    .form-error-description {
      color: #ec6060;
      margin-bottom: 10px;
      font-size: 12px;
    }
    .form-input {
      outline: none;
    }

    .form-item {
      position: relative;
      margin-bottom: 8px;

      &.google-recaptcha {
        margin-top: 8px;
      }
    }

    .form-item input {
      display: block;
      width: 100%;
      height: 35px;
      background: #fff;
      border: solid 1px #ccc;
      transition: all 0.3s ease;
      padding: 0 15px;
      position: relative;
      z-index: 2;
      border-radius: 7px;
      font-size: 14px;
      color: #424242;
      font-family: "Rubik-Regular";
    }

    input[type="date"] {
      -webkit-appearance: none;
      -webkit-min-logical-width: calc(100% - 16px);
    }

    .form-item input::-webkit-input-placeholder {
      /* Edge */
      color: #424242;
      text-transform: uppercase;
      font-size: 11px;
      font-family: "Rubik-Medium";
    }

    .form-item input:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #424242;
      text-transform: uppercase;
      font-size: 14px;
      font-family: "Rubik-Medium";
    }

    .form-item input::placeholder {
      color: #424242;
      text-transform: uppercase;
      font-size: 14px;
      font-family: "Rubik-Medium";
    }

    // .form-item input:focus {
    //   border-color: blue
    // }

    .form-submit-button button {
      padding: 4.5px 65.6px 5.9px 65.9px;
      border-radius: 5.1px;
      background-color: #424242;
      width: 100%;
      outline: 0;
      border: 0;
      color: #fff;
      font-family: "Rubik-Medium";
    }

    .form-item label {
      display: none;
      position: absolute;
      cursor: text;
      z-index: 1;
      top: 12px;
      left: 5px;
      font-size: 10px;
      font-weight: bold;
      padding: 0 10px;
      color: #424242;
      transition: all 0.3s ease;
      text-transform: uppercase;
    }

    .form-item input:focus + label {
      top: 1px;
    }

    .form-item input:focus + label {
      color: #424242;
    }
  }
  .info-text {
    display: none;
  }
  .jumbotron-header {
    // padding: 67px 16px 64px 16px;
    @media screen and (max-width: 567px) {
      padding-top: 15px;
    }
    .jumbotron-content {
      .hero-form {
        .search-button {
          padding-top: 5px;
          padding-bottom: 5px;
          border: 2px solid #fff;
          &:hover {
            border: 2px solid #fff;
          }
        }
        .linkButton {
          color: #fff;
        }
      }
    }
  }
}
.popup-model-container {
  .modal-body {
    padding: 0;
    .banner-form-container {
      width: 100%;

      .field-set-wrapper {
        //border: 2px solid rgba(0, 0, 0, 0.3);
        legend {
          color: #000;
        }
      }
      .popup-close-button {
        text-align: center;
        margin-top: 20px;
        button {
          border-radius: 5.1px;
          background-color: #424242;
          width: 100%;
          outline: 0;
          border: 0;
          color: #fff;
          font-family: "Rubik-Medium";
          display: inline-block;
          width: auto;
          padding-left: 15px;
          padding-right: 15px;
        }
      }
    }
  }
}
.page-footer {
  width: 100%;

  .container {
    width: 70%;

    @media (max-width: 480px) {
      width: 100%;
    }
  }

  .footer-main-links {
    padding-left: 0;
    list-style-type: none;
    font-size: 15px;

    li {
      line-height: 27.5px;
      a {
        color: #424242;
        font-family: "Rubik-Regular";
        &:hover {
          color: #4062a2;
        }
      }
      .hidden-text {
        color: #fff;
        font-family: "Rubik-Regular";
      }
    }
  }
  .footer-columns {
    margin-bottom: 20px;
  }
}

.slider-content {
  position: absolute;
  bottom: 35px;
  width: 100%;
}

.offer-label {
  background-color: #e8b317;
  color: #fff;
  font-weight: 600;
  border-radius: 5px;
  padding: 2px 10px;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.second-slider .carousel .slide img {
  max-height: 500px;
  object-fit: cover;
}
.carousel-status {
  display: none;
}
.form-luxury-image {
  display: none;
  margin-top: 15px;
  @media screen and (max-width: 567px) {
    margin-top: 0px;
  }
  img {
    max-width: 100%;
    @media screen and (max-width: 567px) {
      max-width: 70%;

    }
  }
  @media screen and (max-width: 567px) {
    display: block;
  }
 
  .book-online-text {
    font-family: "Rubik-Regular";
    @media screen and (max-width: 667px) {
      color: #000;
      text-align: center;
      font-size: 27px;
    }
  }
}

// new header styles
.new-limios-navbar {
  .navbar {
    background: transparent;

    .navbar-collapse {
      .navbar-nav {
        a {
          color: #000;
        }
      }
    }
    &.navbarExpanded {
      background: #fff;
      .navbar-toggler {
        svg {
          color: #000;
        }
      }
    }
  }
}

/*!
 * Widget Version: 2022-07-03_07-28-28
 */
.yotpo-display-wrapper {
  visibility: visible !important;
}

.review-main-content {
  width: 70%;
  margin: auto;
  clear: both;
  font-family: "Rubik-Regular";

  @media screen and (max-width: 567px) {
    width: 90%;
    margin: auto;
    clear: both;
  }

  .yotpo {
    direction: LTR;
    clear: both;
    text-align: left;
    vertical-align: baseline;
    font-family: "Rubik-Regular";
    font-style: inherit;
    padding: 0;
    margin: 0;
    letter-spacing: normal;
  }
  .yotpo-nav {
    background: transparent;
    font-family: "Rubik-Regular";
    border-color: rgba(0, 39, 59, 0.08);
    display: block;
    position: relative;
    box-sizing: border-box;

    ul {
      float: left;
      padding: 0;
      font-size: 15px;
      margin: 0px;
      list-style-type: none;
    }
  }

  .yotpo-popup-box-small {
    padding-top: 12px;
    margin: 0 10px;
  }
  .yotpo-regular-box {
    padding: 25px 0 22px;
    margin: 0 10px;
    border-top: 1px solid #e3e3e3;

    .yotpo-header {
      position: relative;
      color: #6a6c77;

      .yotpo-icon-profile {
        position: relative;
        width: 45px;
        height: 45px;
        margin-right: 8px;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        background: #83b6dd;

        .yotpo-user-letter {
          background: transparent !important;
          position: absolute;
          top: 8px;
          width: 100%;
          min-width: 100%;
          left: 1px;
          text-align: center;
          font-size: 20px;
          color: #fff;
        }

        .yotpo-icon-circle-checkmark {
          display: inline-block;
          color: #1cc286;
        }
      }

      .yotpo-header-actions {
        float: right;
      }

      .yotpo-user-name {
        color: #2f84ed;
        margin-right: 5px;
      }

      .yotpo-header-element {
        padding-top: 2px;
        display: inline-block;
        vertical-align: top;
      }

      .pull-left {
        text-align: left;
        float: left;
      }
    }

    .yotpo-main {
      display: block;
      margin-top: 10px;
      margin-right: 0px;
      margin-bottom: 15px;
      margin-left: 53px;
    }

    .content-title {
      color: #6b6d76;
      font-weight: normal;
      font-family: "Rubik-Regular";
    }

    .yotpo-footer {
      position: relative;
      color: #6a6c77;
      margin-left: 53px;
    }
  }
}

.yotpo .yotpo-review .yotpo-header .yotpo-icon-profile .yotpo-icon-circle-checkmark {
  position: absolute;
  left: 31px;
  top: 28px;
  background: #fff;
  border-radius: 50%;
}

.yotpo-icon {
  font-size: 17.5px;
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  width: auto !important;
  background: none;
  height: auto;
  -webkit-font-smoothing: antialiased;
  list-style-type: none;
}

.yotpo-footer {
  display: flex;
  justify-content: space-between;

  .y-label {
    min-width: 0px;
    padding: 0px;
    margin-left: 5px;
    text-transform: capitalize;
  }

  .vote-btn {
    margin-left: 10px;
  }

  .yotpo-icon-btn-small {
    display: inline-block;
  }
}

.yotpo-review-stars {
  display: inline-block;
  margin-top: 7px;
  margin-right: 10px;
}

.star-icon {
  color: #e7721b;
}

.yotpo-nav-wrapper {
  border-bottom: 3px solid;
  border-color: #2f84ed;
  overflow: visible;
  max-width: none;
  padding-bottom: 6px;
  font-size: 18px;
  font-family: "Rubik-Regular";
}

.yotpo-nav-tab {
  margin-bottom: 15px;
}

.modal-content {
  padding: 0 !important;
  background-color: rgba(0, 0, 0, 0.8);
  border: none;

  & .popup-close-button-container {
    text-align: end;
    margin-right: 10px;
    button {
      border-radius: 30%;
      padding: 0 10px;
    }
  }
  & .request-a-quote-form-container {
    .jumbotron-header {
      padding: 0 !important;

      .jumbotron-content {
        justify-content: center;

        & .search-button {
          padding: 0;
        }

        form {
          & .form-field {
            height: 60px;

            & .hour-time-container {
              .hour-type {
                padding-top: 0 !important;
              }
            }
          }
          .name-container {
            .first-name-form-field {
              width: 50%;
              float: left;
              border-right: 1px solid rgba(255, 255, 255, 0.4);
            }

            .last-name-form-field {
              width: 50%;
              float: right;
            }

            .duration-form-field {
              border-right: 1px solid rgba(255, 255, 255, 0.4);
            }
          }
        }
      }
    }
  }
}

.rio-section-container {
  clear: both;
  width: 70%;
  margin: 15px auto;
  margin-top: 3rem;;

  @media screen and (max-width: 567px) {
    width: 90%;
  }

  & .rio-section-title {
    h2 {
      font-family: "Rubik-Regular";
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.29;
      letter-spacing: normal;
      text-align: center;
      color: #424242;

      @media screen and (max-width: 567px) {
        font-size: 26px;
      }
    }
  }

  & .rio-section-description-container {
    & .rio-section-description {
      font-family: "Rubik-Regular";
      font-size: 21px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.73;
      letter-spacing: normal;
      text-align: center;
      color: #424242;

      @media screen and (max-width: 567px) {
        font-size: 17px;
      }
    }
    & .bold {
      font-weight: bold;
      font-size: 20px;
      @media screen and (max-width: 567px) {
        font-size: 17px;
      }
    }
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

// button container scss
.btn_container {
  background-color: #141414;
}
.new-banner-btn-container {
  padding: 40px 0 10px 0;
  @media (max-width: 480px) {
    padding: 25px 0;
  }

  .cta-buttons-wrapper {
    display: flex;
    justify-content: center;

    @media (max-width: 480px) {
      text-align: center;
      display: block;
    }
  }

  .banner-call-to-action-buttons {
    padding: 15px;
  }

  .banner-call-to-action-buttons a {
    border-style: none;
    font-size: 1.2em;
    font-weight: 500;
    color: #fff !important;
    border-radius: 6px;
    letter-spacing: 0.5px;
    font-family: "Rubik-Medium";
    text-align: center;
    background-color: #595959;
    padding: 10px;
    cursor: pointer;
  }
}

@media (max-width: 991px) {
  .new-banner-btn-container.desktop {
    display: none;
  }

  .new-banner-btn-container.mobile {
    .banner-call-to-action-buttons a {
      font-size: 16px;
      line-height: 1.5;
      font-family: "Rubik-Medium";
      text-align: center;
      background-color: #5a5a5a;
      padding: 10px 10px;
    }
  }
}

@media (min-width: 992px) {
  .new-banner-btn-container.mobile {
    display: none;
  }
}

.gallery-container {
  clear: both;
  width: 70%;
  margin: 15px auto;

  @media screen and (max-width: 567px) {
    width: 100%;
    margin: 15px auto;
  }

  .gallery-title {
    text-align: center;
    font-family: "Rubik-Regular";
    font-size: 21px;
    color: #424242;
    line-height: 1.29;
  }

  .gallery-image-main-container {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    flex-wrap: wrap;

    .gallery-image-container {
      position: relative;
      margin: 22px 20px;
      @media screen and (max-width: 567px) {
        margin: 10px 0;
      }
      .gallery-image {
        height: 150px;
        width: 243px;
        border-radius: 6px;

        @media (min-width: 992px) and (max-width: 1199px) {
          height: 150px;
          width: 243px;
        }

        @media (min-width: 1200px) {
          height: 170px;
          width: 320px;
        }
      }
      .gallery-image-description {
        position: absolute;
        bottom: 0;
        left: 0;
        opacity: 0.85;
        background-color: #000;
        width: 100%;
        padding: 7px 10px;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;

        p {
          font-family: "Rubik-Regular";
          font-size: 12px;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.33;
          letter-spacing: normal;
          color: #fff;
        }
      }
    }
  }
}
.modal-backdrop.show {
  opacity: none;
  background: transparent;
}
.modal-backdrop.fade {
  opacity: 0.7;
  background-color: #000;
  animation-duration: 0;
  animation-name: none;
}
.discount-popup-model-container {
  .modal-dialog {
    max-width: 350px;
    margin: 0 auto;

    .modal-content {
      background-color: #fff;

      .modal-header,
      .close {
        position: absolute;
        top: 2px;
        right: 5px;
        border: none;
        font-size: 2rem !important;
        font-weight: 500;
        text-shadow: none !important;
      }

      .modal-body {
        padding: 0;

        .discount-modal-img-container {
          position: relative;
        }

        .discount-modal-description {
          margin: 15px auto;

          .discount-modal-title {
            text-align: center;
            font-size: 22px;
            font-weight: 700;
            color: #1a1815;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.37;
            letter-spacing: normal;
            font-family: Rubik-Regular;
            margin-bottom: 25px;
          }

          .discount-modal-sub-title {
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            text-align: center;
            color: #1a1815;
            font-family: Rubik-Regular;

            &.success-text {
              width: 75%;
              margin: auto;
              line-height: 1.1;
              margin-bottom: 50px;
            }
          }
        }

        .discount-modal-form,
        .discount-modal-submit-btn-container,
        .discount-modal-footer-text-container {
          width: 80%;
          margin: 0 auto;

          .input-type-email {
            border-radius: 6.9px;
            border: solid 1.3px #e5e5e5;
            color: #000;
            font-family: "SFCompactDisplay-Medium";
          }

          button {
            width: 100%;
            margin-bottom: 10px;
            border-radius: 6.9px;
            background-color: #2a2a2a;
            padding: 0.175rem 0.75rem;
          }

          .footer-text {
            font-size: 8px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            text-align: center;
            color: #000;
            opacity: 0.45;
            font-family: Rubik-Regular;
            margin-top: 10px;
          }
        }

        .discount-code-container {
          text-align: center;
          margin: 10px auto;
        }
      }
    }
  }
}
.lm-operator-form-container {
  width: 100%;
  background-image: linear-gradient(115deg, #2c2c2c 40%, #000 95%);
  padding: 2rem 1rem;
  margin-bottom: 2rem;

  .lm-inner-container {
    width: 60%;
    margin: auto auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-family: "Rubik-Regular";
    @media screen and (max-width: 567px) {
      width: 100%;
      display: block;
    }
    h1 {
      font-family: "Rubik-Regular";
      font-size: 20px;
      line-height: 1.35;
      color: #fff;
    }
    h2 {
      color: #fff;
      font-size: 20px;
    }
    .lm-operator-desc-small {
      opacity: 0.6;
      text-shadow: 0 2px 4px rgba(0, 0, 0, 0.35);
      font-family: "Rubik-Regular";
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.77;
      letter-spacing: normal;
      color: #fff;
    }
    .lm-operator-btn {
      font-family: "Rubik-Regular";
      width: 45%;
      text-align: center;
      color: #fff;
      display: flex;
      align-items: center;
      flex-direction: column;
      padding:10px;
      font-size: 14px;
      min-width: 383px;
      img {
        height: 70px;
        @media screen and (max-width: 567px) {
          height: 40px;
          min-width: unset;
        }
      }

      h1 {
        letter-spacing: 2px;
        font-size: 25px;
      }
      h2{
        font-family: "Rubik-Regular";
      }
      h4 {
        font-size: 13px;
        opacity: 0.7;
        letter-spacing: normal;
      }
      span {
        letter-spacing: 3px;
      }
      .lm-btn-click {
        border: 1px solid red;
        width: 84%;
        margin: auto auto;
        border-radius: 6px;
        background-color: #fff;
        border: none;
        font-size: 17px;      
        padding: 10px;
        margin-top: 1rem;
        max-width:305px;
        color: #2a2a2a;
        @media screen and (max-width: 567px) {
          width: 80%;
          font-size: 11px;
        }
      }
    }
  }
  .btn_container {
    position: absolute;
    bottom: 0%;
    width: 99%;
    background-color: #141414;
    .btn_inner {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
     }
  } 
}

.lm-operator-form-container .lm-inner-container .lm-operator-btn .mouse-hover-button.lm-btn-click:hover {
  background-color: #4062a2;
  color: #FFF;
}
