@import "src/scss/globals";
@import "src/scss/brands";

@include brandify {
  .confirmation-container {
    margin: 124px auto 64px auto;
  }

  .bookingStatus {
    text-align: center;
    color: white;
    font-size: 1.5rem;
    text-transform: uppercase;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
    margin-bottom: 0;
    padding: 8px;
    width: 100%;

    &.booking {
      background-color: $color-static;
    }
    &.booked {
      background-color: $color-green;
    }
    &.failed {
      background-color: $color-red;
    }
    &.support {
      background-color: #ffc107;
    }

    .spinner {
      margin-right: 0.5rem;
    }
  }

  .bookedJumbotron {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    background-color: $color-off-white;
    border-radius: 0 !important;
    color: black;

    .passenger-input-form {
      margin: 16px 0;
      width: 100%;

      input {
        margin: 4px 0;
      }
    }

    .addToCalendarContainer {
      text-align: right;
      .btn {
        font-size: 1.2rem;
        height: 2.5rem;
        width: 100%;
      }

      ul {
        margin-top: 1rem;
        list-style: none;
      }
    }

    .calendarList {
      margin-top: 16px;
      text-align: center;
    }
  }

  .callAndChatContainer {
    margin-top: 24px;
    
    width: 90%;
    margin: 0 auto;
    margin-bottom: 24px;
    .linkButton {
      color: white !important;
      border-color: white !important;
    }
    .talkToSpecialist {
      color: white;
    }
  }
}
