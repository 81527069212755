@import "src/scss/globals";
@import "src/scss/brands";

@include brandify {
  .search-results-page {
    padding-top: 60px;
    .specialize-section-image-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    h1 {
      width: 62%;
      text-align: right;
    }
    .jumbotron {
      background-color: #f2e84d;
      color: #000;
      padding: 0;
      margin: 10px;

      h3 {
        font-size: 20px;
        text-align: center;
        padding: 4px 0;
        font-family: "Rubik-Regular";
        margin-top: 1.5rem;

        @media screen and (max-width: 667px) {
          font-size: 14px;
        }
      }
    }

    .searchResultsPageTitle {
      color: white;
      font-weight: 400;
      margin: 1rem auto 32px auto;
      width: 100%;
      display: flex;
      flex-direction: row;
      @media screen and (max-width: 667px) {
        flex-direction: column;
      }
      h1 {
        width: 62%;
        text-align: right;
        @media screen and (max-width: 667px) {
          width: 100%;
          text-align: center;
        }
      }
      .assistance-text-container {
        border-radius: 5px;
        border: solid 0.8px rgba(255, 255, 255, 0.3);
        background-color: rgba(255, 255, 255, 0.16);
        font-family: "Rubik-Regular";
        width: 28%;
        margin: auto;
        display: flex;
        align-items: center;
        min-width: 311px;

        &__link {
          display: inherit;
        }

        @media screen and (max-width: 667px) {
          width: 95%;
        }

        .small-image {
          height: 40px;
          margin: 4px 9px 0px 9px;
        }

        .line1,
        .line2 {
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 13px;
          color: #fff;
        }

        .line2 {
          font-size: 13px !important;
        }
      }

      svg {
        margin-right: 8px;
      }
    }

    .searchResultDate,
    .miniAddress {
      color: white !important;
    }

    .accordion .btn {
      display: block;
    }

    .accordion .btn-group {
      display: block;
    }

    .phone-number-input {
      margin-top: 0.5rem;
    }

    .accordion .card {
      border-radius: 5px;
      background-color: white;
      border-radius: 0;
      color: black;

      h4 {
        color: black;
      }
    }
    .override {
      display: block;
      margin: 0 auto;
      border-color: red;
    }

    .spinnerText {
      color: white;
      margin: 8rem auto 0 auto;
      text-align: center;
      font-size: 1.6rem;
      font-style: italic;
      font-weight: 900;
    }

    .accordion .card .btn-link {
      text-decoration: none;
      color: #4062a2;;
      font-weight: 500;
      font-size: 12px;
    }
    .accordion .card-header {
      border: none;
      padding: 0;
      display: flex;
      align-self: center;
      background-color: rgba(0, 0, 0, 0);
    }

    .accordion .card-header .btn {
      background-color: rgba(0, 0, 0, 0);
      border-color: white;

      &:hover,
      &:hover:not(:disabled) {
        border-color: white;
      }
    }

    .modifySearchButton {
      font-weight: 600;
      border: none;
      font-size: 1.4rem;
      color: black;
      border-radius: $border-radius-standard;
    }

    h4 {
      color: $color-interactive;
      margin-top: 0.7rem;
    }

    .sortAndFilter .btn {
      background-color: rgba(0, 0, 0, 0);
      border: none;
      text-align: left;
      border-radius: 0rem;
      color: black;
      font-size: 1.2rem;
      font-weight: 600;

      &.active {
        background-color: black !important;
        color: white;
      }
    }

    .results-container {
      max-width: 100%;
      margin: 32px auto;
      position: relative;
    }
  }
}

.return-trip-heading {
  width: 100%;
  clear: both;
  padding: 50px 0px;
  h1 {
    text-align: center;
  }
}
.return-trip-results-wrapper {
  width: 100%;
}

.assistance-sticky-wrapper {
  width: 200px;
  padding: 10px;
  border-radius: 3px;
  background-color: rgba(255, 255, 255, 0.2);
  position: fixed;
  top: 220px;
  right: 0;
  z-index: 9;
  display: none;

  @media (max-width: 992px) {
    display: none;
  }

  p.assistance-sticky-title {
    font-size: 17px;
    line-height: normal;
    margin-bottom: 8px;
  }
  p.assistance-sticky-description {
    font-size: 17px;
  }
}

.loading-state {
  .spinnerText {
    color: white;
    text-align: center;
  }
}
