
.container-request-quote {
  width: 100%;
  min-height: 900px;
  background-color: white;
  margin-top: 0rem;

  .second-section-vehicle-banner {
    display: flex;
     flex-direction: row;
     width:  70%;
     max-width: 1300px !important;
     margin:  auto auto;
     @media only screen and (max-width: 576px) { 
      flex-direction: column;
      width: 90%;
      margin: auto auto;
     }

     .left-banner {
      width: 70%;
      max-height: 200px;
      @media only screen and (max-width: 576px) {  
        width: 100%;
      }
     img {
       width: 100%; 
       height: 100%;
    }
    }
     .right-banner-container {
      width: 100%;
      padding:0 0px 0 0px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.35);
      background-color: #0c0c0c;

       .right-banner-secondary-container {
        margin-left: 6%;
        width: 100%;   
        display: flex;
        justify-content: space-evenly;
        flex-direction: column;   
        padding: 1rem;
         @media only screen and (max-width: 576px) {  
        //  margin-left: 8%;
        }
       
       .promo-banner {
        width: auto;
         max-width: 210px;
          padding: 3px 2px;
         border-radius: 4px ;
         box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.35) ;
         background-color: #e0b542 ;
         text-align: center ;
        font-size: 11px;
         font-weight: 500 ;
         font-family: "Rubik-Medium";
         font-size: 10px;
         color: #000;
         margin-bottom: 2%;
         @media only screen and (max-width: 576px) {  
          margin-bottom: 4%;
         }

         
       }
       
      }
      .service-vehicle-image {
        width: 150px;
        height: 20px;
        margin-left: -3%;
        @media only screen and (max-width: 576px) {  
          margin-left: -5%;
          margin-bottom: 6px;
        }
         img {
          object-fit: contain;
          width: 100%;
          height: 100%;
         }
       }
       .right-vehicle-desc {
        width: 100%;
        color: #fff;
        font-size: 15px;
        text-align: left;
        font-family: "Rubik-Regular";
        font-weight: normal;
        margin-top: 2%;
       }
       .contact-marketing {
        width: 228px;
        border-radius: 6px;
        background-color: #fff;
        text-align: center;
        margin-top: 2%;
        color: #0c0c0c;
        font-family: "Rubik-Medium";
        font-size: 12px;
        font-weight: 500;
        padding: 8px 11px 8px 12px;
       
      }
     }
   }
   .service-vehicle-container {
    .rio-upper-container {
      width: 60%;
      @media only screen and (max-width: 576px) {  
        width: 100%;
      }
    }
    .rio-section-container {
      width: 100%;
      .rio-section-description-container {
        font-size: 15px;
      }

    }
   }
   .promo-banner-section {
    width: auto;
    max-width: 210px;
     padding: 3px 2px;
    border-radius: 4px ;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.35) ;
    background-color: #e0b542 ;
    text-align: center ;
   font-size: 11px;
    font-weight: 500 ;
    font-family: "Rubik-Medium";
    font-size: 10px;
    color: #000;
    margin: auto; 
    margin-bottom: 2%;
   }

  .request-quote-hero-banner {
    width: 100%;
    background-color: white;
    
    .img-container {
      width: 100%;
      .inner-contents-wrapper{
      width: 100%;      
      position: relative;
    
      .container-black-extend {
        height: 100px;
        background-color: #131313;
        width: 100%;
        margin-bottom: 3%;
        @media only screen and (max-width: 576px) { 
          margin-top: -1px;
        }
      }
      .banner-container {
        overflow: hidden;

        width: 100%;   
        .banner-lg {
          @media only screen and (max-width: 576px) {
            display: none;
          }
        }
        .banner-sm {
          display: none;
          @media only screen and (max-width: 576px) {
            display: block;
            height: 100%;
            width: 100%;
            object-fit: cover;
            margin: auto auto;
            min-height: 1330px;
          }
        }

        img {
         width: 100%;        
          object-fit: cover;
           max-height: 900px;
          min-height: 900px; 

        }

        @media only screen and (max-width: 576px) {
          height: 800px;
          width: 100%;
          margin: auto auto;
          height:1200px;


          img {
            object-fit: contain;
            width: 100%;
          }
        }
      }
      .sprite-search {
        margin: auto;
        max-width: 1300px;
        min-width: 1200px;
        display: flex;
        flex-direction: row;        
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        align-items: center;
        @media (max-width: 576px) {
          min-width: unset;   
          top: 48%;     
      }
      
        @media (max-width: 576px) {
            flex-direction: column;          
        }
        .sprites-bg {
       
          margin-right: auto; 
          margin-top: auto;
          margin: auto auto;
          @media (max-width: 576px) {
            top: 0 !important;
            bottom: unset;
            order: 1;
            left: unset;
            width: unset;
            text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5) !important;
            margin-top: 10px;
          }
          .promo-box {
            width: auto;
            max-width: 210px;
            padding: 3px 2px;
            border-radius: 4px;
            box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.35);
            background-color: #e0b542;
            text-align: center;
            font-size: 11px;
            font-weight: 500;
            font-family: "Rubik-Medium";
            font-size: 10px;
            color: #000;
            margin-bottom: 3%;
            @media only screen and (max-width: 576px) {
              margin: auto auto;
              text-shadow: none;
            }
          }
      
          
          .transport-image-sm {
            width: 284px;
            height: 81px;
            object-fit: contain;
            display: block;
            margin: auto auto;
            display: none;
      
            img {
              width: 100%;
              height: 100%;
              align-items: center;
              display: flex;
              justify-content: center;
              margin-top: 1rem;
            }
            @media only screen and (max-width: 576px) {
              display: block;
            }
          }
          .sprite-group {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: left;
            justify-content: left;
            .vehicle-service-logo {
              width: 160px;
              height: 35px;
              align-items: center;
              display: flex;
              text-align: center;
              justify-content: center;
              justify-items: center;
              margin-bottom: 0.6rem;
              @media only screen and (max-width: 576px) {
                margin: auto auto;
                margin-bottom: 0;
                margin-top: 1rem;
                display: none;
              }
      
              img {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }
            .img-desc {
              width: 100%;
              margin: auto auto;
              height: auto;
              text-align: left;
              @media only screen and (max-width: 576px) {
                text-align: center;
                margin-top: 3%;
              }
      
              span {
                font-size: 16px;
                line-height: 1.5;
                color: #fff;
                font-family: "Rubik-Regular";
                letter-spacing: normal;
                color: "#fff";
                text-align: left;
                @media only screen and (max-width: 576px) {
                  font-size: 16px;
                  line-height: 0;
                }
              }
            }
            .banner-sprites {
              list-style-type: none;
              display: flex;
              padding: 0;
              justify-content: left;
              align-items: left;
              justify-content: left;
              margin-top: 1rem;
              @media only screen and (max-width: 576px) {
                padding: 1rem 0;
                margin: auto auto;
              }
              .li-flex {
                display: flex;
                flex-direction: column;
                width: 120px;
                align-items: center;
                .img-div {
                  width: 4.5rem;
                  height: auto;
                  object-fit: contain;
                  img {
                    width: 100%;
                    height: 100%;
                  }
                }
              }
              .li-desc {
                font-size: 11px;
                font-weight: 500;
                font-family: "Rubik-Medium";
                text-align: center;
                font-weight: bold;
                color: #fff;
                font-size: 12px;
                font-weight: 500;
                font-family: "Rubik-Medium";
              }
            }
          }
        }
      
        .search-form-request-quote {
        
          width: auto;
          box-sizing: border-box;
          padding: 9px 14px 17px;
          @media (max-width: 576px) {
            order: 2;
          }          
         
          #index-page {
            .form-title {
              font-size: 22px !important;
              font-weight: 500;
            }
            .react-select__menu {
              .react-select__menu-list {
                .react-select__option {
                  color: #000;
                  font-size: 15px;
                }
              }
            }
          }
      
          #search-button {
            width: 100%;
            min-height: 46px;
            height: 46px;
            box-sizing: border-box;
            padding: 0;
            border-radius: 6.9px;
            background-color: #fff;
            font-size: 18px;
            font-family: "Rubik-Medium";
            margin-top: 1rem;
            background-color: #e0b542;
            color: #000;
            border: 2px solid #e0b542;
            background-size: 200% 100%;
            transition: all 0.99s ease-in-out;
          }
        }
     /*    @media only screen and (min-width: 1200px) {
          width: 100%;
          position: relative;
          max-width: 1200px;
        } */
      }

    }}
  }

  .service-vehicle-container {
    width: 100%;
    background-color: white;
    margin-top: 0rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color:#424242;
    .transport-image-lg {
      width: 400px;
      height: 250px;
      object-fit: contain;
      display: block;
      margin: auto auto;

      img {      
        align-items: center;
        display: flex;
        justify-content: center;
        width: 430px !important;
        height: 300px !important;
        min-height: 300px;
        max-height: 250px;
        object-fit: contain;
        margin: auto auto;
      }
      @media only screen and (max-width: 576px) {
       display: none;
      }
    }
    @media only screen and (max-width: 576px) {
      width: 100%;
      justify-content: flex-start;
      align-items: flex-start;

    }
    .cruise-ship-desc {
      color: #142b47;
      text-align: center;
      margin-bottom: 2rem;
      font-size: 21px;
      font-family: "Rubik-Regular";
      @media only screen and (max-width: 576px) {
        font-size: 18px;
        margin-bottom: 0.2rem;
        text-align: left;
      //  width: 85%;
        padding: 0 2rem;    


      }
    }
    .cruise-ship-names {
    //  width: 332px;
      display: flex;
      flex-direction: column;
      color: #3c91ad;
      align-items: center;
      margin: auto auto;
      font-size: 14px;
      line-height: 2.21;
      margin-bottom: 3.5rem;
      font-family: "Rubik-Regular";
      @media only screen and (max-width: 576px) {
        width: 100%;
        margin-bottom: 0.2rem;
        align-items:flex-start;
        padding-left:2rem;
        margin: 1rem 0;

        
      }
    }
  }
  .popular-services {
    display: flex;
    flex-direction: column;

    align-items: center;
    margin: auto auto;
    margin-bottom: 4rem;
    font-family: "Rubik-Regular";
    font-size: 21px;
    text-align: center;
    color: #142b47;
    font-weight: normal;
    @media only screen and (max-width: 576px) { 
      align-items: flex-start;
      padding-left: 2rem;
      margin-top: 1rem;
    }


    span {
      width: auto;
      text-align: center;
      color: #142b47;
      font-size: 21px;
      font-family: "Rubik-Regular";
    }
    .services-container {
      margin-top: 2rem;
      display: flex;
      flex-direction: row;
      width: 780px;
      height: 150px;

      @media only screen and (max-width: 576px) {
        height: auto;
        width: 100%;
        flex-direction: column;
        width: 90%;
        height: 150px;
      }
      .services {
        margin: auto auto;
        display: flex;
        flex-direction: column;
        width: 243px;
        height: 104;
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
        @media only screen and (max-width: 576px) {
          margin-bottom: 0.8rem;
          width:100%;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          border-top-right-radius: 6px;
          border-top-left-radius: 6px;
        }
        .service-vehicle-desc {
          color: #fff;

          background-color: black;
          background: transparent;
          opacity: 0.85;
          background-color: #000;
          border-bottom-right-radius: 6px;
          border-bottom-left-radius: 6px;
          height: auto;
          padding: 0.5rem 0px;
          display: flex;
          flex-direction: column;
          font-family: "Rubik-Regular";

          p {
            font-size: 12px;
            width: 65%;
            margin: 0px;
            height: 100%;
            display: flex;
            justify-items: center;
            text-align: left;
            align-items: center;
            margin-left: 0.4rem;
            box-sizing: border-box;
            font-weight: 500;
            line-height: 1.33;
          }
          .sub-text {
            font-weight: normal;
          }
        }
      }
    }
  }
  .cruise-footer-section {
    margin-top: 2rem;
    width: 100%;
    @media only screen and (max-width: 576px) {
      margin-top: 0.2rem;
    }
    .book-about-section {
      width: 52%;
      margin: auto auto;
      display: flex;
      flex-direction: column;
      @media only screen and (max-width: 576px) {
        flex-direction: row;
        height: 350px;
        width: 60%;
      }
      .book-now {
        color: #424242;
        @media only screen and (max-width: 576px) {
          margin-right: 4rem;
        }

        p {
          color: #000;
          font-size: 14px;
          font-weight: 500;
          padding: 0;
          margin: 0;
        }
        span {
          font-size: 14px;
          font-weight: normal;
        }
        .footer-links {
          margin-top: 0.5rem;
          display: flex;
          flex-direction: column;
          a {
            font-size: 14px;
            padding-bottom: 0.4rem;
            color: #424242;
            @media only screen and (max-width: 576px) {
              width: 150px;
              padding: 0px;
            }
          }
        }
      }
    }
  }

  .margin-space {
    margin-bottom: 1rem;
  }
}

.contact-request-quote-team-sm {
  width: 306px;
  margin: auto auto;
 /*  display: flex;
  align-items: center;
  justify-content: center; */
  @media only screen and (max-width: 576px) {
    display: flex;
    width:100%;
    margin: auto auto;
     margin-top: 27rem;
     margin-bottom: 2rem;
 
  }

  button {
    width: 75%;
    border-radius: 6px;
    background-color: #000;
    color: #fff;
    padding: 0.5rem 0.7rem;
    margin-bottom: 2rem;
    font-size: 12px;
    font-family: "Rubik-Medium";
    font-weight: 500;
    border: none;
    @media only screen and (max-width: 576px) { 
      margin: auto auto;
    }
    &:hover {
      border: none;
    }
    &:active {
      border: none;
    }
  }
}
.footer-section {
  @media only screen and (max-width: 576px) { 
    margin-top: 2rem;
  //  margin-left: 3rem;
  }
}
.limos {
  width: 100% !important;
}
.jumbotron-header .jumbotron-content .hero-form  {
  @media only screen and (max-width: 576px) { 
    width:100% !important;
    margin: auto auto !important;
  }
 
}
